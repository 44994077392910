import {html} from 'lit';
import {property, customElement, queryAll} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import styles from './style.scss';

import {AccountCategory, accountCategoryIcons} from '../../../../types/account';
import RoadCheckbox from 'src/components/base/checkbox';

export enum AccountCategoryFiltersEvents {
  ACCOUNT_CATEGORIES_CHANGED = 'filters-account-categories-changed',
}

@customElement('road-account-category-filters')
export class RoadAccountCategoryFilters extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Array})
  selectedAccountCategories: AccountCategory[] = [];

  @queryAll('road-checkbox[name="accountCategories"]') checkboxes!: RoadCheckbox[];

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.selectedAccountCategories = [];
    this.checkboxes.forEach(checkbox => checkbox.deselect());
    this.requestUpdate();
  }

  handleAccountCategoriesChange(evt: CustomEvent): void {
    const accountCategory = this.context.accountCategories.find((ac: AccountCategory) => ac.id === Number(evt.detail.value));
    if (!accountCategory) return;

    if (evt.detail.checked) {
      this.selectedAccountCategories.push(accountCategory)
    } else {
      this.selectedAccountCategories = this.selectedAccountCategories.filter((ac: AccountCategory) => ac.id !== accountCategory.id);
    }

    this.dispatchAccountCategoriesChangedEvent();
  }

  dispatchAccountCategoriesChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent(AccountCategoryFiltersEvents.ACCOUNT_CATEGORIES_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: this.selectedAccountCategories,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-account-category-filters__filter">
          <label for="accountCategories">Account Categories</label>
          <div class="road-account-category-filters__checks-container">
            ${repeat(
              this.context.accountCategories,
              (accountCategory: AccountCategory) => `account-category-filter-${accountCategory.id}`,
              (accountCategory: AccountCategory) => {
                return html`<road-checkbox
                  name="accountCategories"
                  value="${accountCategory.id}"
                  label="${accountCategory.name}"
                  icon="${accountCategoryIcons.get(accountCategory.name)!}"
                  ?checked="${this.selectedAccountCategories.includes(accountCategory)}"
                  @change="${(evt: CustomEvent) => {
                    this.handleAccountCategoriesChange(evt);
                  }}"
                >
                  ${accountCategory.name}
                </road-checkbox>`
              }
            )}
          </div>
        </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-account-category-filters': RoadAccountCategoryFilters;
  }
}
