import {html} from 'lit';
import {property, customElement, state, query} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {repeat} from 'lit/directives/repeat.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import {RoadContext} from '../../context';

import {DataTableComponent} from '../../../abstract/data_table';
import {applyRelevancy, RelevancyWeights} from '../../../../utils/fuzzysearch';
import {fuzzyMatch, safeToGrep} from '../../../../utils/strings';
import {paginate} from '../../../../utils/arrays';
import {printAmount} from '../../../../utils/numbers';

import {Variant as InputVariant} from '../../../base/input';
import RoadSelect, {SelectVariant} from '../../../base/select';

import {RoadFilters} from '../../filters';

import {AccountCategory, accountCategoryIcons} from '../../../../types/account';
import {Movement, MovementStatus, statusDescriptions} from '../../../../types/movement';
import {MovementType} from '../../../../types/movement_category';
import {Currency} from '../../../../types/currency';

import {MovementEvents} from '../movement';

import {
  loadMovements,
  confirmMovement,
  deleteMovement,
  FilterStore,
  AdvancedFilters,
} from '../../../../services/movements';

import styles from './style.scss';
import tableStyles from '../../../base/table/style.scss';
import dataTableStyles from '../../../abstract/data_table/style.scss';

const DEFAULT_PAGE_LIMIT = 50;
const DEFAULT_TABLE_DATA: Movement[] = [];
const DEFAULT_FILTERED_DATA: Movement[] = [];

export interface Touched {
  statusTouched?: boolean;
  accountCategoryTouched?: boolean;
  currencyTouched?: boolean;
}

@customElement('road-movements-table')
export class RoadMovementsTable extends DataTableComponent<Movement> {
  private context = new RoadContext(this);

  @property({type: Array, attribute: 'movements'})
  tableData: Movement[] = DEFAULT_TABLE_DATA;

  @state()
  filteredData: Movement[] = DEFAULT_FILTERED_DATA;

  @property({type: Object})
  filters: FilterStore = {};

  @property({type: Object})
  advancedFilters: AdvancedFilters = { useFiscalDate: '1' };

  @property({type: Object})
  touched: Touched = {};

  @property({type: Array})
  statuses: MovementStatus[] = [];

  @property({type: Number})
  pageLimit = DEFAULT_PAGE_LIMIT;

  @property({type: Number})
  tableSize = 0;

  @property({type: Number})
  numPages = 0;

  @property({type: Number})
  backendPage = 1;

  @state()
  loading = false;

  @state()
  filtersCollapsed = false;

  @state()
  pauseUpdates = false;

  @state()
  showAdvancedFilters = false;

  @state()
  showMovement = false;

  @property({type: Object})
  selectedMovement?: Movement

  @state()
  confirmDelete = false;

  @query('road-filters') roadFilters!: RoadFilters;
  @query('road-select#currency') roadCurrencyFilter!: RoadSelect;
  @query('road-select#accountCategory') roadCategoryFilter!: RoadSelect;
  @query('road-select#status') roadStatusFilter!: RoadSelect;

  static get styles() {
    return [tableStyles, dataTableStyles, styles];
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(MovementEvents.MOVEMENT_CREATED, (event: Event) => { this.applyMovementModal(event as CustomEvent);});
    window.addEventListener(MovementEvents.MOVEMENT_UPDATED, (event: Event) => { this.applyMovementModal(event as CustomEvent); });
    window.addEventListener(MovementEvents.MOVEMENT_CLOSED, (event: Event) => { this.hideMovementModal(event as CustomEvent); });
    window.addEventListener(MovementEvents.MOVEMENT_CANCELLED, (event: Event) => { this.hideMovementModal(event as CustomEvent); });
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    window.removeEventListener(MovementEvents.MOVEMENT_CREATED, (event: Event) => { this.applyMovementModal(event as CustomEvent); });
    window.removeEventListener(MovementEvents.MOVEMENT_UPDATED, (event: Event) => { this.applyMovementModal(event as CustomEvent); });
    window.removeEventListener(MovementEvents.MOVEMENT_CLOSED, (event: Event) => { this.hideMovementModal(event as CustomEvent); });
    window.removeEventListener(MovementEvents.MOVEMENT_CANCELLED, (event: Event) => { this.hideMovementModal(event as CustomEvent); });
  }

  setInitialProperties() {
    this.tableData = DEFAULT_TABLE_DATA;
    this.filteredData = DEFAULT_FILTERED_DATA;

    this.pageLimit = DEFAULT_PAGE_LIMIT;
    this.tableSize = 0;
    this.numPages = 0;
    this.backendPage = 1;
    this.page = 1;
    this.loading = false;

    this.statuses = [MovementStatus.CONFIRMED, MovementStatus.UNCONFIRMED, MovementStatus.RECONCILING];
    this.advancedFilters = { useFiscalDate: '1' }
    this.filters = {};
  }

  load() {
    this.setInitialProperties();
    this.loadMovements(1);
  }

  async loadMovements(page: number) {
    if (this.pauseUpdates) return;
    if (this.loading) return;

    this.loading = true;
    const response = await loadMovements(
      page,
      this.pageLimit,
      this.filters,
      this.advancedFilters
    );
    this.loading = false;

    if (!response.ok) return alert(response?.errors?.join('\n'));

    this.tableData = response.movements || [];
    this.tableSize = response.count || 0;
    this.backendPage = response.page || 1;
    this.pageLimit = response.per_page || DEFAULT_PAGE_LIMIT;
    this.numPages = response.last_page || 0;
    this.page = 1;
    this.query = '';

    this.refreshTable();
  }

  async confirm(movement: Movement, status: MovementStatus = MovementStatus.CONFIRMED) {
    const response = await confirmMovement(movement, status);

    if (!response.ok) return alert(response?.errors?.join('\n'))

    const movementIndex = this.tableData.findIndex(m => m.id === movement.id)
    if (movementIndex >= 0 && response.movement) this.tableData[movementIndex] = response.movement

    this.refreshTable();
  }

  deleteConfirmation() {
    if (this.selectedMovement === undefined) return;

    const okLabel = 'Delete';
    const cancelLabel = 'Cancel';

    return html`
      <road-modal
        ?open=${this.confirmDelete}
        ?actions=${true}
        label="Confirm delete"
        .okLabel=${okLabel}
        .cancelLabel=${cancelLabel}
        @closed=${() => this.confirmDelete = false}
        @cancel=${() => this.confirmDelete = false}
        @ok=${() => this.delete(this.selectedMovement!)}
      >
        <div class="confirmation-message">
          Are you sure you want to delete this transaction?
        </div>
        ${this.renderPreview(this.selectedMovement)}
      </road-modal>`;
  }

  async delete(movement: Movement) {
    const response = await deleteMovement(movement)

    if (!response.ok) return alert(response?.errors?.join('\n'))

    const movementIndex = this.tableData.findIndex(m => m.id === movement.id)
    const destMovementId = movement.transfer_movement_id

    if (movementIndex >= 0) {
      this.tableData.splice(movementIndex, 1);
      this.selectedMovement = undefined;
    }

    if (destMovementId) {
      const destIndex = this.tableData.findIndex(m => m.id === destMovementId)
      if (destIndex >= 0) this.tableData.splice(destIndex, 1);
    }

    this.refreshTable();
  }

  haystack(movement: Movement): string {
    return (
      applyRelevancy(RelevancyWeights.HIGHEST, movement.account!.name) +
      applyRelevancy(RelevancyWeights.HIGH, movement.account!.currency.name) +
      applyRelevancy(RelevancyWeights.MEDIUM, movement.user_movement_category!.name) +
      applyRelevancy(RelevancyWeights.MEDIUM, movement.user_movement_sub_category!.name) +
      applyRelevancy(RelevancyWeights.LOW, movement.account!.account_category.name) +
      applyRelevancy(RelevancyWeights.LOWEST, movement.notes!)
    );
  }

  get pagedData() {
    return paginate(this.filteredData, this.page, this.pageLimit);
  }

  get totalPages() {
    return this.numPages;
  }

  nextPage() {
    if (this.backendPage >= this.numPages) return;

    this.loadMovements(this.backendPage + 1);
  };

  prevPage() {
    if (this.backendPage <=1) return;

    this.loadMovements(this.backendPage - 1);
  };

  firstPage() {
    if (this.backendPage <=1) return;

    this.loadMovements(1);
  };

  lastPage() {
    if (this.backendPage >= this.numPages) return;

    this.loadMovements(this.numPages);
  };

  gotoPage(page: number) {
    if (page < 1) {
      this.firstPage();
      return;
    }

    if (page > this.numPages) {
      this.lastPage();
      return;
    }

    this.loadMovements(page);
  };

  refreshTable() {
    this.filteredData = this.tableData;
    this.requestUpdate();
  }

  updateLocalTable() {
    let workingResults = this.tableData;

    if (this.query) {
      workingResults = workingResults.filter(item => {
        return fuzzyMatch(
          safeToGrep(this.query),
          safeToGrep(this.haystack(item))
        );
      });
    }

    this.page = 1;
    this.filteredData = workingResults;
    this.requestUpdate();
  }

  updateResults() {
    this.loadMovements(1);
  }

  advancedFiltersApplied(): boolean {
    for (const key in this.advancedFilters) {
      if (key === 'useFiscalDate') continue;

      const value = this.advancedFilters[key as keyof AdvancedFilters];
      if (value !== undefined && value !== null && (!Array.isArray(value) || value.length > 0)) {
        return true;
      }
    }

    return false;
  }

  filtersApplied(): boolean {
    for (const key in this.filters) {
      const value = this.filters[key as keyof FilterStore];
      if (value !== undefined && value !== null) {
        return true;
      }
    }

    return this.advancedFiltersApplied();
  }

  renderHeader() {
    return html`
      <thead>
        <tr class="road-table__columns">
          <th>
            <div class="road-table__column road-table__cell">Date</div>
          </th>
          <th>
            <div class="road-table__column road-table__cell">Category</div>
          </th>
          <th>
            <div class="road-table__column road-table__cell">Account</div>
          </th>
          <th>
            <div class="road-table__column road-table__cell movement-amount">Amount</div>
          </th>
        </tr>
      </thead>
    `;
  }

  renderRow(movement: Movement) {
    const classes = () => {
      return classMap({
        'road-table__row': true,
        'borderless': true,
        'road-table__row__debt': movement.net_amount! < 0,
        'road-table__row__positive': movement.net_amount! > 0,
      })
    };

    return html`
      <tr class="road-table__row ${classes()}"
        @click="${() => {
          // location.href = `/movements/${movement.id}`
          this.showMovementModal(movement);
        }}"
      >
        <td>
          <div class="road-table__cell road-table__cell--title">
            ${this.renderFirstColumn(movement)}
          </div>
        </td>
        <td>
          <div class="road-table__cell road-table__cell--title">
            ${this.renderCategory(movement)}
          </div>
        </td>
        <td>
          <div class="road-table__cell road-table__cell--title">
            <div class="flex-end"
                @click="${(e: MouseEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                  location.href = `/accounts/${movement.account!.id}`
                }}"
            >
              <road-icon icon="${accountCategoryIcons.get(movement.account!.account_category.name)!}"></road-icon>
              ${movement.account!.name}
            </div>
          </div>
        </td>
        <td>
          <div class="road-table__cell road-table__cell--title flex-end">
            ${this.renderAmount(movement)}
          </div>
        </td>
      </tr>
    `;
  }

  renderPreview(movement: Movement) {
    return html`
      <road-card
        header="${movement.user_movement_category!.name} : ${movement.user_movement_sub_category!.name}"
        headerIcon="${accountCategoryIcons.get(movement.account!.account_category.name)!}"
      >
        <div class="preview__header__amount">
          ${movement.account!.currency.abrev}
          ${printAmount(movement.net_amount!)}
          ${movement.account!.name}
        </div>
        <div>
          Payee: ${movement.payee?.name || 'N/A'}<br>
          Created: ${dayjs(movement.created_at).fromNow()}<br>
          Updated: ${dayjs(movement.updated_at).fromNow()}<br>
          Family Member: ${movement.family_member?.name || 'N/A'}<br>
          Tags: ${movement.tags?.map(t => t.tag).join(', ') || 'N/A'}<br>
        </div>
        <div slot="footer">
          ${movement.notes}
        </div>
        <div slot="header-aside">
          ${movement.status === MovementStatus.CONFIRMED
            ? html`<road-icon
              icon="toggle_on"
              fill="green"
            ></road-icon>`
            : html`<road-icon
              icon="toggle_off"
              fill="gray"
            ></road-icon>`
          }
        </div>
      </road-card>
    `;
  }

  renderFirstColumn(movement: Movement) {
    return html`
      <div class="flex-end">
        <road-tooltip>
          <div slot="content">
            ${this.renderPreview(movement)}
          </div>
          <road-icon
            icon="visibility"
            fill="primary--dark"
          >
          </road-icon>
        </road-tooltip>
        <road-tooltip content="Duplicate this transaction">
          <road-icon
            icon="content_copy"
            fill="primary--dark"
            @click=${(e: CustomEvent) => {
              e.stopPropagation();
              e.preventDefault();
              location.href = `/movements/${movement.id}/duplicate`
            }}
          >
          </road-icon>
        </road-tooltip>
        <road-tooltip content="Delete this transaction">
          <road-icon
            icon="delete_forever"
            fill="primary--dark"
            @click=${(e: CustomEvent) => {
              e.stopPropagation();
              e.preventDefault();
              this.selectedMovement = movement;
              this.confirmDelete = true;
            }}
          >
          </road-icon>
        </road-tooltip>
        ${new Date(movement.date).toLocaleDateString(undefined, { timeZone: 'UTC' })}
      </div>
    `;
  }

  renderCategory(movement: Movement) {
    if (movement.movement_type !== MovementType.TRANSFER) {
      return html`${movement.user_movement_category!.name} : ${movement.user_movement_sub_category!.name}`
    }

    return html`
      ${movement.net_amount! < 0
        ? html`To ${movement.transfer_movement_account_name}`
        : html`From ${movement.transfer_movement_account_name}`
      }
    `;
  }

  renderAmount(movement: Movement) {
    return html`
      <div class="flex-end">
        ${movement.account!.currency.abrev}
        ${printAmount(movement.net_amount!)}
        ${movement.status === MovementStatus.CONFIRMED
          ? html`
            <road-icon
              icon="toggle_on"
              fill="green"
              @click=${(e: CustomEvent) => {
                e.stopPropagation();
                e.preventDefault();
                this.confirm(movement, MovementStatus.UNCONFIRMED);
              }}
            >
            </road-icon>`
          : html`
            <road-icon
              icon="toggle_off"
              fill="gray"
              @click=${(e: CustomEvent) => {
                e.stopPropagation();
                e.preventDefault();
                this.confirm(movement, MovementStatus.CONFIRMED);
              }}
            >
            </road-icon>`
        }
      </div>
    `;
  }

  renderControls() {
    return html`<div
      class="road-table-container__controls
      road-table-container__controls--padded data-table__header"
    >
      <div class="road-table__controls">
        ${this.renderSearch()}
        ${this.renderFilters()}
      </div>
      <slot name="header"></slot>
    </div>`;
  }

  renderMoreFilters() {
    if (this.filtersCollapsed) return html``;

    const tooltip= this.advancedFiltersApplied()
      ? 'There are advanced filters applied'
      : 'Advanced filters';

    const color = this.advancedFiltersApplied() ? 'red' : 'primary--dark';

    const moreIcon = html`
      <road-tooltip content="${tooltip}">
        <road-icon
          icon="filter_list"
          fill=${color}
          class="filters-button"
          @click=${this.showAdvancedFiltersModal}
        >
        </road-icon>
      </road-tooltip>
    `;

    const dismissIcon = this.filtersApplied()
      ? html`
          <road-tooltip content="Remove all filters">
            <road-icon
              icon="close"
              fill="primary--dark"
              class="filters-button"
              @click=${this.resetFilters}
            >
            </road-icon>
          </road-tooltip>
        `
      : html``;

    return html`${moreIcon}${dismissIcon}`
  }

  renderBasicFilters() {
    if (this.filtersCollapsed) return html``;

    return html`
      <road-select
        id="currency"
        variant=${SelectVariant.CONDENSED}
        placeholderIcon="euro_symbol"
        searchText='Currency'
        @selected=${(e: CustomEvent) => {
          if (this.touched.currencyTouched) {
            this.filters.currency = e.detail.value;
            this.updateResults();
          } else {
            this.touched.currencyTouched = true;
          }
        }}
      >
        ${repeat(
          this.context.currencies,
          (currency: Currency) => currency.id,
          (currency: Currency) => {
            return html`<road-list-item style="min-width: 250px;" value="${currency.id}">
              ${currency.name}
            </road-list-item>`;
          }
        )}
      </road-select>

      <road-select
        id="accountCategory"
        variant=${SelectVariant.CONDENSED}
        placeholderIcon="category"
        searchText='Type'
        @selected=${(e: CustomEvent) => {
          if (this.touched.accountCategoryTouched) {
            this.filters.accountCategory = e.detail.value;
            this.updateResults();
          } else {
            this.touched.accountCategoryTouched = true;
          }
        }}
      >
        ${repeat(
          this.context.accountCategories,
          (accountCategory: AccountCategory) => accountCategory.id,
          (accountCategory: AccountCategory) => {
            return html`<road-list-item value="${accountCategory.id}">
              <div class="flex-start">
                <road-icon icon="${accountCategoryIcons.get(accountCategory.name)!}"></road-icon>
                <div style="min-width: 140px;">
                  ${accountCategory.name}
                </div>
              </div>
            </road-list-item>`;
          }
        )}
      </road-select>

      <road-select
        id="status"
        variant=${SelectVariant.CONDENSED}
        placeholderIcon="check_circle"
        searchText='Status'
        @selected=${(e: CustomEvent) => {
          if (this.touched.statusTouched) {
            this.filters.status = e.detail.value;
            this.updateResults();
          } else {
            this.touched.statusTouched = true;
          }
        }}
      >
        ${repeat(
          this.statuses,
          (status: MovementStatus) => status,
          (status: MovementStatus) => {
            return html`<road-list-item value="${status}">
              <div class="flex-start">
                <road-icon icon="${statusDescriptions.get(status)?.icon!}"></road-icon>
                <div style="min-width: 140px;">
                  ${statusDescriptions.get(status)?.description}
                </div>
              </div>
            </road-list-item>`;
          }
        )}
      </road-select>
    `;
  }

  renderSearch() {
    return html`
      <road-input
        hasicon
        type="search"
        placeholder="Search"
        variant=${InputVariant.BORDERLESS_NO_BG}
        @input=${(e: CustomEvent) => {
          this.query = e.detail.value;
          this.updateLocalTable();
        }}
      >
        <div slot="icon">
          <road-icon icon="search"></road-icon>
        </div>
      </road-input>`;
  }

  renderBasicFiltersToggle() {
    const icon = this.filtersCollapsed ? 'chevron_left' : 'chevron_right';
    const tooltip = this.filtersCollapsed ? 'Show filters' : 'Hide filters';

    return html`
      <road-tooltip content="${tooltip}">
        <road-icon
          icon="${icon}"
          class="collapsible-icon"
          size="lg"
          @click=${(e: CustomEvent) => {
            e.preventDefault();
            e.stopPropagation();
            this.filtersCollapsed = !this.filtersCollapsed}
          }
        >
        </road-icon>
      </road-tooltip>
    `;
  }

  renderFilters() {
    return html`<div class="road-table__controls__filters">
      ${this.renderBasicFiltersToggle()}
      ${this.renderMoreFilters()}
      ${this.renderBasicFilters()}
    </div>`;
  }

  renderMovementTable() {
    return html`<div class="movements-index">
      <div class="road-table-container" id="${this.key}">
        <div
          class=${classMap({
            'road-table': true,
            'road-table--horizontally-scrolled': false,
            'road-table--blur': this.loading,
          })}
        >
          ${this.renderControls()}
          <table>
            ${this.renderHeader()}
            <tbody>
              ${repeat(
                this.pagedData,
                (movement: Movement) => movement.id,
                this.renderRow.bind(this)
              )}
            </tbody>
          </table>
          ${this.renderCallOuts()}
        </div>
        <div class="road-table-container__controls data-table__footer">
          <road-paginator
            page=${this.backendPage}
            total=${this.totalPages}
            @next=${() => this.nextPage()}
            @previous=${() => this.prevPage()}
            @first=${() => this.firstPage()}
            @last=${() => this.lastPage()}
            @goto=${(evt: CustomEvent) => this.gotoPage(evt.detail.value)}
            ?disabled=${this.loading}
          >
          </road-paginator>
        </div>
      </div></div>
    `;
  }

  renderSkeletonTable() {
    return html`
      <div style="height: 500px"></div>
      <road-skeleton-table>
        <div class="road-callout-wrapper">
          <road-call-out
            .headline=${this.skeletonHeadline()}
          >
            <a href="movements/new">Create your first movement</a>
          </road-call-out>
        </div>
      </road-skeleton-table>
    `;
  }

  skeletonHeadline() {
    return 'No movements found';
  }

  skeletonCaption() {
    return 'Create your first movement';
  }

  renderAdvancedFilters() {
    return html`
      <road-filters
        ?open=${this.showAdvancedFilters}
        @closed=${this.hideAdvancedFiltersModal}
        @cancel=${this.hideAdvancedFiltersModal}
        @confirm=${this.applyAdvancedFiltersModal}
      >
      </road-filters>
    `;
  };

  renderMovement() {
    if (this.selectedMovement) {
      return html`
        <road-movement
          ?open=${this.showMovement}
          .movement=${this.selectedMovement}
        >
        </road-movement>
      `;
    }

    return html`
      <road-movement
        ?open=${this.showMovement}
      >
      </road-movement>
    `;
  }

  private showAdvancedFiltersModal() {
    this.showAdvancedFilters = true;
    this.requestUpdate();
  }

  private hideAdvancedFiltersModal() {
    this.showAdvancedFilters = false;
    this.requestUpdate();
  }

  private showMovementModal(movement: Movement | undefined = undefined) {
    if (movement === undefined) return;

    // Invert movement if this is the "to" part of the transfer movement
    // TODO: Handle the case where the "from" part is not in the table
    if (movement.transfer_movement_id && movement.net_amount! > 0) {
      const fromIndex = this.tableData.findIndex(m => m.id === movement!.transfer_movement_id)
      if (fromIndex >= 0) movement = this.tableData[fromIndex]
    }
    this.selectedMovement = movement;
    this.showMovement = true;
    this.requestUpdate();
  }

  private hideMovementModal(_event: CustomEvent) {
    this.selectedMovement = undefined;
    this.showMovement = false;
    this.requestUpdate();
  }

  private applyMovementModal(event: CustomEvent) {
    this.selectedMovement = undefined;
    this.showMovement = false;
    if (!event.detail) return;

    if (event.type === MovementEvents.MOVEMENT_CREATED) {
      if (event.detail.movement.transfer_movement_id) {
        this.tableData.unshift(event.detail.movement.transfer_movement);
      }
      this.tableData.unshift(event.detail.movement);
    } else if (event.type === MovementEvents.MOVEMENT_UPDATED) {
      const movementIndex = this.tableData.findIndex(m => m.id === event.detail.movement.id)
      if (movementIndex >= 0) this.tableData[movementIndex] = event.detail.movement
      if (event.detail.movement.transfer_movement_id) {
        const destIndex = this.tableData.findIndex(m => m.id === event.detail.movement.transfer_movement_id)
        if (destIndex >= 0) this.tableData[destIndex] = event.detail.movement.transfer_movement
      }
    }

    this.refreshTable();
  }

  private applyAdvancedFiltersModal(event: CustomEvent) {
    this.showAdvancedFilters = false;
    if (!event.detail) return;

    this.advancedFilters = event.detail;
    this.updateResults();
  }

  private resetFilters() {
    this.pauseUpdates = true;
    this.advancedFilters = { useFiscalDate: '1' };
    this.filters = {};
    this.roadFilters.resetFilters();
    this.roadCurrencyFilter.initialize('');
    this.roadCategoryFilter.initialize('');
    this.roadStatusFilter.initialize('');
    this.pauseUpdates = false;
    this.updateResults();
  }

  render() {
    if (this.loading && !this.tableData.length) {
      return html`<road-loader loading></road-loader>`;
    }

    return html`
      ${this.renderMovementTable()}
      ${this.renderMovement()}
      ${this.renderAdvancedFilters()}
      ${this.deleteConfirmation()}
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-movements-table': RoadMovementsTable;
  }
}
