import {html, CSSResultArray, TemplateResult} from 'lit';
import {customElement, property, queryAssignedNodes} from 'lit/decorators.js';

import {RoadComponent} from '../../../lib/component';
import RoadListItem, {
  COMPONENT_TAG as ROAD_LIST_ITEM_COMPONENT_TAG,
} from '../list_item';
import styles from './style.scss';

export const COMPONENT_TAG = 'road-list';

/**
 * RoadList
 */
@customElement(COMPONENT_TAG)
export default class RoadList extends RoadComponent {
  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @property({type: Boolean, reflect: true})
  loading = false;

  @property({type: Boolean})
  singleSelect = false;

  @queryAssignedNodes('', true)
  readonly $assignedNodes!: NodeListOf<HTMLElement>;

  getListItems(): RoadListItem[] {
    if (!this.$assignedNodes) return [] as RoadListItem[];
    return Array.from(this.$assignedNodes || []).filter(
      $node => $node.nodeName === ROAD_LIST_ITEM_COMPONENT_TAG.toUpperCase()
    ) as RoadListItem[];
  }

  static get styles(): CSSResultArray {
    return [styles];
  }

  rendered(): void {
    if (!this.singleSelect) return;
    const $nodes = this.getListItems();
    $nodes.forEach(node => {
      node.addEventListener('selected', () => {
        $nodes
          .filter(n => n !== node)
          .forEach(n => {
            n.selected = false;
          });
      });
    });
  }

  /**
   * Renderer
   * @override
   */
  render(): TemplateResult {
    if (this.loading) {
      return html` <slot></slot> `;
    }

    return html`
      <ul class="road-list">
        <slot></slot>
      </ul>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-list': RoadList;
  }
}
