import {fetcher} from '../utils/http';
import {RequestMethod} from '../common/constants';

import {Movement} from '../types/movement';
import {Payee} from '../types/payee';
import {MovementType} from '../types/movement_category';

import {ApiResponse} from './api';

export interface PayeeResponse extends ApiResponse { payee?: Payee; };

export interface PayeesResponse extends ApiResponse { payees?: Payee[]; };

export interface PayeeDeleteResponse extends ApiResponse {};

export interface MovementResponse extends ApiResponse { movement?: Movement; };

export async function createPayee(
  payee: Payee,
): Promise<PayeeResponse> {

  const url = `/api/payees`

  try {
    const response = await fetcher<PayeeResponse>(
      url,
      {method: RequestMethod.POST, body: {payee: {name: payee.name}}}
    );
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    alert(e.errors.join('\n'));
    return {ok: false, errors: e.errors};
  }
}

export async function updatePayee(
  payee: Payee,
): Promise<PayeeResponse> {

  let url = `/api/payees/${payee.id}`

  try {
    const response = await fetcher<PayeeResponse>(
      url,
      {method: RequestMethod.PUT, body: {payee: {name: payee.name}}}
    );
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function loadPayees(): Promise<PayeesResponse> {
  const url = '/api/payees';

  try {
    const response = await fetcher<PayeesResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function deletePayee(payee: Payee): Promise<PayeeDeleteResponse> {
  const url = `/api/payees/${payee.id}`;

  try {
    await fetcher<string>(url, {method: RequestMethod.DELETE});
    return {ok: true, errors: []};
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function loadLastMovement(payee: Payee, movementType: MovementType): Promise<MovementResponse> {
  const url = `/api/payees/${payee.id}/last_movement?movement_type=${movementType}`;

  try {
    const response = await fetcher<MovementResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}
