import {LitElement} from 'lit';

// A component; extends LitElement and HTMLElement
export class RoadComponent extends LitElement {
  static paths: string[] = [];
  lifecycleComplete = false;
  queries: URLSearchParams;
  protected path: string;
  private disconnectController = new AbortController();

  constructor() {
    super();

    this.path = window.location.pathname || '';
    this.queries = new URLSearchParams(window.location.search);
  }

  private finishLifecycle() {
    if (!this.lifecycleComplete) {
      this.rendered();
      this.lifecycleComplete = true;
      this.dispatchEvent(new CustomEvent('rendered'));
    }
  }

  /**
   * Abort controller signal
   * Useful for automatically remove listeners when the component is removed from the DOM
   */
  get disconnectSignal() {
    return this.disconnectController.signal;
  }

  beforeLoad(): void {}
  load(): void {}
  rendered(): void {}

  connectedCallback() {
    super.connectedCallback();
    this.disconnectController = new AbortController();

    // Mutation observer for after render/load hook
    const observer = new MutationObserver(() => this.finishLifecycle());
    const context = (this.shadowRoot?.getRootNode() ||
      this.getRootNode()) as Node;

    if (context) {
      observer.observe(context, {
        attributes: false,
        childList: true,
        subtree: true,
      });

      setTimeout(() => observer.disconnect(), 0);
    }

    // Before load hook
    this.beforeLoad = this.beforeLoad.bind(this);
    this.beforeLoad();

    this.render();

    // On load hook
    this.load = this.load.bind(this);
    this.load();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.disconnectController.abort();
  }

  firstUpdated() {
    this.finishLifecycle();
  }
}
