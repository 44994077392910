import '../loader';

import {html, TemplateResult} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';
import buttonStyles from '../button/style.scss';
import styles from './style.scss';
import RoadButton, {Size, Variant} from '../button';

export const LOADER_BUTTON_ELEMENT_TAG = 'road-loader-button';
export const loaderSizeMap = new Map([
  [Size.SM, '10px'],
  [Size.BASE, '10px'],
  [Size.MD, '12px'],
  [Size.LG, '12px'],
  [Size.XL, '14px'],
]);

export const loaderVariantMap = new Map([
  [Variant.BASE, 'white'],
  [Variant.OUTLINED, 'var(--road-color-bg--accent)'],
  [Variant.LINK, 'var(--road-color-bg--accent)'],
]);

/**
 * LoaderButton
 */
@customElement(LOADER_BUTTON_ELEMENT_TAG)
export default class RoadLoaderButton extends RoadButton {
  @property({type: Boolean, reflect: true})
  loading = false;

  static get styles() {
    return [buttonStyles, styles];
  }

  get classNames() {
    return {
      ...super.classNames,
      'road-loader-button': true,
      'road-loader-button--disabled': this.disabled || this.loading,
    };
  }

  loaderStyle() {
    return {
      '--road-loader-size': loaderSizeMap.get(this.size),
      '--road-loader-color': loaderVariantMap.get(this.variant),
    };
  }

  loaderMarkup(): TemplateResult {
    return html`
      <road-loader
        style=${styleMap(this.loaderStyle())}
        ?loading=${this.loading}
      >
      </road-loader>
    `;
  }

  render() {
    return html`
      <button
        type=${this.type}
        class="${classMap(this.classNames)}"
        ?disabled=${this.disabled || this.loading}
        @click=${() => {
          if (!this.disabled) {
            this.blurOnClick && this.blur();
          }
        }}
      >
        ${this.hasIcon
          ? html`
              <div class="road-btn--icon__prepend">
                <slot name="icon"></slot>
              </div>
            `
          : ''}
        <div class="road-btn--icon__label">
          <slot></slot>
        </div>
        ${this.loading ? this.loaderMarkup() : ''}
      </button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-loader-button': RoadLoaderButton;
  }
}
