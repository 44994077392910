import {CSSResultArray, html} from 'lit';
import {query} from 'lit-element';
import {customElement, property} from 'lit/decorators.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {classMap} from 'lit/directives/class-map.js';

import styles from './style.scss';

import RoadCard from '../../../components/base/card';

import RoadRadio, {
  CUSTOM_EVENT as ROAD_RADIO_CUSTOM_EVENT,
  CustomChangeEvent as RoadRadioCustomChangeEvent,
} from '../../../components/base/radio';

const DEFAULT_SVG_ICON_SIZE = 36;

export const COMPONENT_TAG = 'road-radio-card';
@customElement(COMPONENT_TAG)
export default class RoadRadioCard extends RoadCard {
  @property({type: String})
  value = '';

  @property({type: String})
  fieldset!: string;

  @property({type: Boolean})
  checked: boolean = false;

  @property({type: Boolean})
  disabled: boolean = false;

  @property({type: Boolean, reflect: true})
  readonly: boolean = false;

  @property({attribute: 'show-radio', type: Boolean, reflect: true})
  showRadio: boolean = false;

  @property({type: Boolean, reflect: true})
  invalid: boolean = false;

  @property({type: Boolean})
  initial: boolean = false;

  @property({attribute: 'toggle-on-click', type: Boolean})
  toggleOnClick: boolean = false;

  @property()
  headerSVGIconSize: string | number = DEFAULT_SVG_ICON_SIZE;

  @query('road-radio')
  $radioField!: RoadRadio;

  static get styles(): CSSResultArray {
    return [styles];
  }

  firstUpdated() {
    this.initialize();
  }

  initialize(state: boolean = this.initial) {
    this.checked = this.$radioField.checked = state;
  }

  reset(state: boolean = this.initial) {
    this.checked = this.$radioField.checked = state;
  }

  handleRadioFieldCustomEvent(_evt: CustomEvent<RoadRadioCustomChangeEvent>) {
    const evt = {
      fieldset: this.fieldset,
      initial: this.initial,
      ..._evt.detail,
      $radioField: this.$radioField,
    } as const;
    this.dispatchEvent(
      new CustomEvent(ROAD_RADIO_CUSTOM_EVENT.CHANGE, {detail: evt})
    );
  }

  radioFieldMarkup() {
    return html`
      <road-radio
        name=${ifDefined(this.fieldset)}
        fieldset=${ifDefined(this.fieldset)}
        value=${this.value}
        ?readonly=${this.readonly}
        ?checked=${this.checked}
        ?invalid=${this.invalid}
        ?disabled=${this.disabled}
        @focus=${() => {
          if (!this.readonly)
            this.dispatchEvent(new CustomEvent(ROAD_RADIO_CUSTOM_EVENT.FOCUS));
        }}
        @change=${this.handleRadioFieldCustomEvent.bind(this)}
        @blur=${() => {
          if (!this.readonly)
            this.dispatchEvent(new CustomEvent(ROAD_RADIO_CUSTOM_EVENT.BLUR));
        }}
      ></road-radio>
    `;
  }

  render() {
    const containerClasses = {
      ...this.computedClasses.container,
      'road-card--radio': true,
      'road-card--radio--checked': this.checked,
      'road-card--radio--disabled': this.disabled,
      'road-card--radio--readonly': this.readonly,
      'road-card--radio--invalid': this.invalid,
    };

    return html`
      <div
        class=${classMap(containerClasses)}
        ?checked=${this.checked}
        ?disabled=${this.disabled}
        ?readonly=${this.readonly}
        ?invalid=${this.invalid}
        key=${this.key}
        @click=${() => {
          if (this.toggleOnClick && !this.disabled && !this.readonly) {
            this.checked = !this.checked;
            this.dispatchEvent(new CustomEvent(ROAD_RADIO_CUSTOM_EVENT.BLUR));
          }
        }}
      >
        <div class=${classMap(this.computedClasses.headerContainer)}>
          ${this.headerIconMarkup()}
          <div class=${classMap(this.computedClasses.headerLabel)}>
            ${this.header}
          </div>
          <div
            class=${classMap(this.computedClasses.headerAside)}
            ?hidden=${!this.showRadio}
          >
            ${this.radioFieldMarkup()}
          </div>
        </div>
        <div class=${classMap(this.computedClasses.main)}>
          <slot></slot>
        </div>
        <div class=${classMap(this.computedClasses.footer)}>
          <slot name="footer"></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_TAG]: RoadRadioCard;
  }
}
