/**
 * Returns the CSRF token provided by rails.
 */
export function getCSRFToken(): string {
  const tokenNodeName = 'csrf-token';
  const meta = document.querySelector(`meta[name="${tokenNodeName}"]`);
  const token = meta?.getAttribute('content');

  return token || '';
}
