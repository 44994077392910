import {html} from 'lit';
import {property, customElement, state} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {repeat} from 'lit/directives/repeat.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import {DataTableComponent} from '../../../abstract/data_table';
import {applyRelevancy, RelevancyWeights} from '../../../../utils/fuzzysearch';
import {fuzzyMatch, safeToGrep} from '../../../../utils/strings';
import {paginate} from '../../../../utils/arrays';
import {printAmount} from '../../../../utils/numbers';

import {Variant as InputVariant} from '../../../base/input';
import {SelectVariant} from '../../../base/select';

import {Account, accountCategoryIcons} from '../../../../types/account';

import {loadAccounts, FavoriteAccount, UnfavoriteAccount} from '../../../../services/accounts';

import styles from './style.scss';
import tableStyles from '../../../base/table/style.scss';
import dataTableStyles from '../../../abstract/data_table/style.scss';

const DEFAULT_PAGE_LIMIT = 50;
const DEFAULT_TABLE_DATA: Account[] = [];
const DEFAULT_FILTERED_DATA: Account[] = [];

interface FilterStore {
  status?: string;
  creator?: number;
  type?: string;
  chapter?: string;
  currency?: string;
}

@customElement('road-accounts-table')
export class RoadAccountsTable extends DataTableComponent<Account> {

  @property({type: Array, attribute: 'accounts'})
  tableData: Account[] = DEFAULT_TABLE_DATA;

  @state()
  filteredData: Account[] = DEFAULT_FILTERED_DATA;

  @property({type: Object})
  filters: FilterStore = {};

  @property({type: Array})
  accountCategories: string[] = [];

  @property({type: Array})
  accountCurrencies: string[] = [];

  @property({type: Array})
  accountChapters: string[] = [];

  @property({type: Number})
  pageLimit = DEFAULT_PAGE_LIMIT;

  @state()
  loading = false;

  static get styles() {
    return [tableStyles, dataTableStyles, styles];
  }

  async load() {
    this.loading = true

    const response = await loadAccounts();

    if (response.ok) {
      this.tableData = response.accounts || DEFAULT_TABLE_DATA;
      this.accountCategories = [...new Set(this.tableData.map(account => account.account_category.name)) ];
      this.accountCurrencies = [...new Set(this.tableData.map(account => account.currency.name)) ];
      this.accountChapters = [...new Set(this.tableData.map(account => account.account_category.chapter_name)) ];
    } else {
      this.tableData = DEFAULT_TABLE_DATA;
    }
    this.loading = false
    this.updateResults();
  }

  async toggleFavorite(account: Account) {
    const response = account.favorite
      ? await UnfavoriteAccount(account)
      : await FavoriteAccount(account);

    const accountIndex = this.tableData.findIndex(a => a.id === account.id)
    if (accountIndex >= 0) this.tableData[accountIndex] = response.account

    this.requestUpdate();
  }

  // -- Datatable component overrides (START) -- //
  // ------------------------------------------- //
  haystack(account: Account): string {
    return (
      applyRelevancy(RelevancyWeights.HIGHEST, account.name) +
      applyRelevancy(RelevancyWeights.HIGH, account.account_category.name) +
      applyRelevancy(RelevancyWeights.MEDIUM, account.currency.name) +
      applyRelevancy(RelevancyWeights.LOW, account.user.name)
    );
  }

  get pagedData() {
    return paginate(this.filteredData, this.page, this.pageLimit);
  }

  updateResults() {
    let workingResults = this.tableData;

    if (this.query) {
      workingResults = workingResults.filter(item => {
        return fuzzyMatch(
          safeToGrep(this.query),
          safeToGrep(this.haystack(item))
        );
      });
      this.page = 1;
    }

    if (this.filters.type) {
      workingResults = workingResults.filter(item => {
        return item.account_category.name === this.filters.type;
      });
      this.page = 1;
    }

    if (this.filters.chapter) {
      workingResults = workingResults.filter(item => {
        return item.account_category.chapter_name === this.filters.chapter;
      });
      this.page = 1;
    }

    if (this.filters.currency) {
      workingResults = workingResults.filter(item => {
        return item.currency.name === this.filters.currency;
      });
      this.page = 1;
    }

    this.filteredData = workingResults;
    this.requestUpdate();
  }

  // -- Datatable component overrides (END)-- //
  // ---------------------------------------- //

  renderHeader() {
    return html`
      <thead>
        <tr class="road-table__columns">
          <th>
            <div class="road-table__column road-table__cell">Name</div>
          </th>
          <th>
            <div class="road-table__column road-table__cell">Type</div>
          </th>
          <th>
            <div class="road-table__column road-table__cell">Currency</div>
          </th>
          <th>
            <div class="road-table__column road-table__cell account-balance">Balance</div>
          </th>
          <th>
            <div class="road-table__column road-table__cell">Last updated</div>
          </th>
          <th></th>
        </tr>
      </thead>
    `;
  }

  renderRow(account: Account) {
    const classes = () => {
      return classMap({
        'road-table__row': true,
        'road-table__row__disabled': account.status === 0,
        'road-table__row__debt': account.balance < 0,
        'road-table__row__positive': account.balance > 0,
      })
    };

    return html`
      <tr class="road-table__row ${classes()}"
        @click="${() => location.href = `/accounts/${account.id}`}"
      >
        <td>
          <div class="road-table__cell road-table__cell--title">
            <div class ="account-name">
              ${this.renderAccountFavorite(account)}
              ${this.renderAccountIcon(account)}
              ${account.name}
            </div>
          </div>
        </td>
        <td>
          <div class="road-table__cell road-table__cell--title">
            ${account.account_category.name}
          </div>
        </td>
        <td>
          <div class="road-table__cell road-table__cell--title">
            ${account.currency.name}
          </div>
        </td>
        <td>
          <div class="road-table__cell road-table__cell--title account-balance">
            ${printAmount(account.balance)}
          </div>
        </td>
        <td>
          <div class="road-table__cell road-table__cell--title">
            ${dayjs(new Date(account.updated_at)).fromNow()}
          </div>
        </td>
      </tr>
    `;
  }

  renderAccountFavorite(account: Account) {
    const fill = account.favorite ? 'yellow' : 'gray';
    const tooltip = account.favorite ? 'Remove from favorite accounts' : 'Add to favorites accounts!';

    return html`<road-tooltip content="${tooltip}">
      <div
        class="account-favorite"
        @click=${(e: CustomEvent) => {
          e.stopPropagation();
          e.preventDefault();
          this.toggleFavorite(account);
        }}
      >
        <road-icon icon="star" fill="${fill}"></road-icon>
      </div>
    </road-tooltip>`;
  }

  renderAccountIcon(account: Account) {
    return html`<road-icon icon="${accountCategoryIcons.get(account.account_category.name)!}"></road-icon>`
  }

  renderControls() {
    return html`<div
      class="road-table-container__controls
      road-table-container__controls--padded data-table__header"
    >
      <div class="road-table__controls">
        <road-input
          hasicon
          type="search"
          placeholder="Search"
          variant=${InputVariant.BORDERLESS_NO_BG}
          @input=${(e: CustomEvent) => {
            this.query = e.detail.value;
            this.updateResults();
          }}
        >
          <div slot="icon">
            <road-icon icon="search"></road-icon>
          </div>
        </road-input>
        <div class="road-table__controls__filters">
          ${this.renderFilters()}
        </div>
      </div>
      <slot name="header"></slot>
    </div>`;
  }

  renderFilters() {
    return html`
      <road-select
        variant=${SelectVariant.CONDENSED}
        placeholder='Currency'
        searchText='Currency'
        @selected=${(e: CustomEvent) => {
          this.filters.currency = e.detail.value;
          this.updateResults();
        }}
      >
        ${this.accountCurrencies.map(currency => {
          return html`<road-list-item style="min-width: 250px;" value="${currency}">${currency}</road-list-item>`;
        })}
      </road-select>

      <road-select
        variant=${SelectVariant.CONDENSED}
        placeholder='Type'
        searchText='Type'
        @selected=${(e: CustomEvent) => {
          this.filters.type = e.detail.value;
          this.updateResults();
        }}
      >
        ${this.accountCategories.map(category => {
          return html`<road-list-item value="${category}">
            <div class="account-name">
              <road-icon icon="${accountCategoryIcons.get(category)!}"></road-icon>
              ${category}
            </div>
          </road-list-item>`;
        })}
      </road-select>

      <road-select
        variant=${SelectVariant.CONDENSED}
        placeholder='Chapter'
        searchText='Chapter'
        @selected=${(e: CustomEvent) => {
          this.filters.chapter = e.detail.value;
          this.updateResults();
        }}
      >
        ${this.accountChapters.map(chapter => {
          return html`<road-list-item value="${chapter}">${chapter}</road-list-item>`;
        })}
      </road-select>
    `;
  }

  renderAccountsTable() {
    return html`<div class="accounts-index">
      <div class="road-table-container" id="${this.key}">
        <div
          class=${classMap({
            'road-table': true,
            'road-table--horizontally-scrolled': false,
          })}
        >
          ${this.renderControls()}
          <table>
            ${this.renderHeader()}
            <tbody>
              ${repeat(
                this.pagedData,
                (account: Account) => account.id,
                this.renderRow.bind(this)
              )}
            </tbody>
          </table>
          ${this.renderCallOuts()}
        </div>
        <div class="road-table-container__controls data-table__footer">
          <road-paginator
            .page=${this.page}
            total=${this.totalPages}
            @next=${() => this.nextPage()}
            @previous=${() => this.prevPage()}
            @first=${() => this.firstPage()}
            @last=${() => this.lastPage()}
            @goto=${(evt: CustomEvent) => this.gotoPage(evt.detail.value)}
          >
          </road-paginator>
        </div>
      </div></div>
    `;
  }

  renderSkeletonTable() {
    return html`
      <div style="height: 500px"></div>
      <road-skeleton-table>
        <div class="road-callout-wrapper">
          <road-call-out
            .headline=${this.skeletonHeadline()}
            caption=''
          >
            <a href="accounts/new">Create your first account</a>
          </road-call-out>
        </div>
      </road-skeleton-table>
    `;
  }

  skeletonHeadline() {
    return 'No accounts found';
  }

  skeletonCaption() {
    return 'Create your first account';
  }

  render() {
    if (this.loading) {
      return html`<road-loader loading></road-loader>`;
    }

    return this.tableData.length
      ? this.renderAccountsTable()
      : this.renderSkeletonTable();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-accounts-table': RoadAccountsTable;
  }
}
