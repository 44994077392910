import '../icon';

import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../lib/component';
import styles from './style.scss';

export const PAGINATOR_ELEMENT_TAG = 'road-paginator';
const PREV_EVENT_KEY = 'previous';
const NEXT_EVENT_KEY = 'next';
const FIRST_EVENT_KEY = 'first';
const LAST_EVENT_KEY = 'last';
const GOTO_EVENT_KEY = 'goto';

/**
 * Paginator
 */
@customElement(PAGINATOR_ELEMENT_TAG)
export default class RoadPaginator extends RoadComponent {
  @property({type: Number})
  page = 1;

  @property({type: Number})
  total = 1;

  @property({type: Boolean})
  disabled = false;

  static get styles() {
    return [styles];
  }

  get nextDisabled() {
    return this.page >= this.total || this.disabled;
  }

  get prevDisabled() {
    return this.page <= 1 || this.disabled;
  }

  get nextClasses(): unknown {
    return classMap({
      'paginator__button--disabled': this.nextDisabled,
    });
  }

  get prevClasses(): unknown {
    return classMap({
      'paginator__button--disabled': this.prevDisabled,
    });
  }

  handleNextClick() {
    if (this.nextDisabled) return;
    const event = new CustomEvent(NEXT_EVENT_KEY);
    this.dispatchEvent(event);
  }

  handlePrevClick() {
    if (this.prevDisabled) return;
    const event = new CustomEvent(PREV_EVENT_KEY);
    this.dispatchEvent(event);
  }

  handleFirstClick() {
    if (this.prevDisabled) return;
    const event = new CustomEvent(FIRST_EVENT_KEY);
    this.dispatchEvent(event);
  }

  handleLastClick() {
    if (this.nextDisabled) return;
    const event = new CustomEvent(LAST_EVENT_KEY);
    this.dispatchEvent(event);
  }

  handleGotoClick(evt: CustomEvent) {
    if (this.disabled) return;
    const input = evt.target as HTMLInputElement;
    const event = new CustomEvent(GOTO_EVENT_KEY, {
      detail: {
        value: input.value,
      },
    });
    this.dispatchEvent(event);
  }

  render() {
    return html`
      <div class="paginator">
        <button
          class="paginator paginator__button paginator__button--first ${this.prevClasses}"
          @click=${() => this.handleFirstClick()}
          ?disabled=${this.prevDisabled}
        >
          <road-icon icon="first_page"></road-icon>
          First
        </button>
        <button
          class="paginator paginator__button paginator__button--prev ${this.prevClasses}"
          @click=${() => this.handlePrevClick()}
          ?disabled=${this.prevDisabled}
        >
          <road-icon icon="keyboard_arrow_left"></road-icon>
          Prev
        </button>
        <road-input
          class="paginator paginator__input"
          type="number"
          .value=${this.disabled ? '' : this.page}
          .min=${1}
          .max=${this.total}
          @change=${this.handleGotoClick}
        >
        </road-input>
        <span class="paginator paginator__total">of ${this.total}</span>
        <button
          class="paginator paginator__button paginator__button--next ${this.nextClasses}"
          @click=${() => this.handleNextClick()}
          ?disabled=${this.nextDisabled}
        >
          Next
          <road-icon icon="keyboard_arrow_right"></road-icon>
        </button>
        <button
          class="paginator paginator__button paginator__button--last ${this.nextClasses}"
          @click=${() => this.handleLastClick()}
          ?disabled=${this.nextDisabled}
        >
          Last
          <road-icon icon="last_page"></road-icon>
        </button>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-paginator': RoadPaginator;
  }
}
