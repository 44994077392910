import {html} from 'lit';
import {property, state, customElement, query} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';
import {classMap} from 'lit/directives/class-map.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import RoadMultiSelect, {Option} from '../../../../components/base/multiselect';
import RoadSelect, {SelectVariant} from '../../../../components/base/select';
import RoadInput from '../../../../components/base/input';

import {Movement, MovementStatus} from '../../../../types/movement';
import {MovementType} from '../../../../types/movement_category';
import {User} from '../../../../types/user';
import {Payee} from '../../../../types/payee';
import {Tag} from '../../../../types/movement';
import {UserExchangeRate} from 'src/types/user_exchange_rate';

import {loadUserExchangeRates} from '../../../../services/user_exchange_rates';
import {loadExchangeRate} from '../../../../services/accounts';

import {
  createMovement,
  updateMovement,
} from '../../../../services/movements';

import {loadLastMovement} from '../../../../services/payees';

import styles from './style.scss';

export enum MovementEvents  {
  MOVEMENT_CREATED = 'movement-created',
  MOVEMENT_UPDATED = 'movement-updated',
  MOVEMENT_CLOSED = 'movement-closed',
  MOVEMENT_CANCELLED = 'movement-cancelled',
}

@customElement('road-movement')
export class RoadMovement extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Boolean})
  open = false;

  @property({type: Object})
  movement!: Movement;

  @property({type: Number})
  movementType: MovementType = MovementType.EXPENSE;

  @property({type: Object})
  user!: User;

  @property({type: Array})
  userExchangeRates: UserExchangeRate[] = [];

  @property({type: Number})
  exchangeRateFrom?: number;

  @property({type: Number})
  exchangeRateTo?: number;

  @state()
  editing = false;

  @state()
  manualChanges = false;

  @state()
  movementChanged = false;

  @state()
  addPayee = false;

  @query('road-select#accountCategory') roadCategoryField!: RoadSelect;
  @query('road-select#accountSubCategory') roadSubCategoryField!: RoadSelect;
  @query('road-select#account') roadAccountField!: RoadSelect;
  @query('road-select#transferAccount') roadTransferAccountField!: RoadSelect;
  @query('road-select#payee') roadPayeeField!: RoadSelect;
  @query('road-select#familyMember') roadFamilyMemberField!: RoadSelect;
  @query('road-multi-select#tags') roadTagsField!: RoadMultiSelect;

  @query('road-input#amount') roadAmountField!: RoadInput;
  @query('road-input#transferAmount') roadTransferAmountField!: RoadInput;
  @query('road-input#date') roadDateField!: RoadInput;
  @query('road-input#fiscalDate') roadFiscalDateField!: RoadInput;
  @query('road-input#notes') roadNotesField!: RoadInput;

  static get styles() {
    return [styles];
  }

  load() {
    if (this.movement) {
      this.editing = true;
      this.swapTransferIfNeeded();
      this.getExchangeRate(this.movement.account?.id, 'from');
      this.getExchangeRate(this.movement.transfer_movement_account_id, 'to');
    } else {
      this.emptyMovement();
    }

    if (!this.userExchangeRates.length) this.loadExchangeRates();
  }

  async loadExchangeRates() {
    const response = await loadUserExchangeRates();

    if (!response.ok) return alert(response?.errors?.join('\n'))

    this.userExchangeRates = response.exchangeRates || [];
    this.requestUpdate();
  }

  rendered() {
    this.movementChanged = false;
  }

  emptyMovement() {
    this.movement = {
      id: 0,
      status: 0,
      movement_type: this.movementType,
      sign: -1,
      date: new Date().toISOString(),
      fiscal_date: new Date().toISOString(),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      tags: [],
      user: this.user,
      attachments: [],
    }
  }

  swapTransferIfNeeded() {
    // TODO: Check why the table changes when we swap the transfer movement, even without confirming
    if (!this.movement) return;
    if (!this.movement.transfer_movement_id) return;
    if (this.movement.net_amount === undefined) return;
    if (this.movement.net_amount < 0) return;

    const originalId = this.movement.id;
    const originalSign = this.movement.sign;
    const originalAmount = this.movement.amount;
    const originalAccount = this.movement.account;

    this.movement.id = this.movement.transfer_movement_id;
    this.movement.amount = this.movement.transfer_movement_amount;
    this.movement.sign = this.movement.transfer_movement_sign;
    this.movement.account = this.context.accounts.find(account => account.id === this.movement.transfer_movement_account_id);

    this.movement.transfer_movement_id = originalId;
    this.movement.transfer_movement_account_id = originalAccount?.id;
    this.movement.transfer_movement_account_name = originalAccount?.name;
    this.movement.transfer_movement_amount = originalAmount;
    this.movement.transfer_movement_sign = originalSign;
  }

  handleStatusChange() {
    if (this.manualChanges) return;

    if (this.movement.status === MovementStatus.CONFIRMED) {
      this.movement.status = MovementStatus.UNCONFIRMED;
    } else if (this.movement.status === MovementStatus.UNCONFIRMED) {
      this.movement.status = MovementStatus.CONFIRMED;
    }
  }

  handlePayeeChange(evt: CustomEvent) {
    if (this.manualChanges) return;

    const payeeId = Number(evt.detail.value);
    this.movement.payee = this.context.payees.find(payee => payee.id === payeeId);
    this.loadLastMovement(this.movement.payee, this.movement.movement_type);
  }

  handleAccountChange(evt: CustomEvent) {
    if (this.manualChanges) return;

    const accountId = Number(evt.detail.value);
    this.movement.account = this.context.accounts.find(account => account.id === accountId);
    this.updateExchangeRate(this.movement.account?.id, 'from', true);
  }

  handleTransferAccountChange(evt: CustomEvent) {
    if (this.manualChanges) return;

    const accountId = Number(evt.detail.value);
    this.movement.transfer_movement_account_id = accountId;
    this.updateExchangeRate(this.movement.transfer_movement_account_id, 'to', true);
  }

  handleAmountChange(evt: CustomEvent) {
    if (this.manualChanges) return;

    this.movement.amount = Number(evt.detail.value);
    this.movementChanged = true;
    if (this.movement.movement_type === MovementType.TRANSFER) {
      this.updateExchangeRate(this.movement.account?.id, 'from', false);
    }
  }
  handleFamilyMemberChange(evt: CustomEvent) {
    if (this.manualChanges) return;

    const fmId = Number(evt.detail.value);
    this.movement.family_member = this.context.familyMembers.find(fm => fm.id === fmId);
  }

  handleTagsChange(evt: CustomEvent) {
    if (this.manualChanges) return;

    this.movement.tags = evt.detail.selected.map((option: Option) => {
      return this.context.tags.find((tag: Tag) => tag.id === Number(option.value));
    });
  }

  handleCategoryChange(evt: CustomEvent) {
    if (this.manualChanges) return;

    const categoryId = Number(evt.detail.value);
    this.movement.user_movement_category = this.context.userMovementCategories.find(cat => cat.id === categoryId);
    this.movement.user_movement_sub_category = undefined;
  }

  handleSubCategoryChange(evt: CustomEvent) {
    if (this.manualChanges) return;

    const subCategoryId = Number(evt.detail.value);
    this.movement.user_movement_sub_category =
    this.context.userMovementCategories.find(cat => cat.id === this.movement.user_movement_category?.id)?.
        user_movement_sub_categories.find(subCat => subCat.id === subCategoryId);
  }

  handleMovementTypeChange() {
    if (this.manualChanges) return;

    switch (this.movement.movement_type) {
      case MovementType.EXPENSE:
        this.movement.movement_type = MovementType.INCOME;
        this.movement.sign = 1;
        break;
      case MovementType.INCOME:
        this.movement.movement_type = MovementType.TRANSFER;
        this.movement.sign = -1;
        break;
      case MovementType.TRANSFER:
        this.movement.movement_type = MovementType.EXPENSE;
        this.movement.sign = -1;
        break;
    }
  }

  handleAttachmentChange(evt: Event) {
    if (this.manualChanges) return;

    if (evt.target === null || !(evt.target instanceof HTMLInputElement)) return;

    const selectedFiles = evt.target.files;

    if (!selectedFiles) return;

    this.movement = {...this.movement, attachments: Object.values(selectedFiles).map((file: File) => file)};

    if (this.shadowRoot) {
      this.shadowRoot.removeChild(evt.target);
    }
  }

  async getExchangeRate(accountId: number | undefined, target: 'from' | 'to') {
    if (!accountId) return false;

    const response = await loadExchangeRate(accountId)
    if (!response.ok) return false;

    if (target === 'from') {
      if (this.exchangeRateFrom === response.cur_ex) return false;
      this.exchangeRateFrom = response.cur_ex;
    } else {
      if (this.exchangeRateTo === response.cur_ex) return false;
      this.exchangeRateTo = response.cur_ex;
    }
    return true;
  }

  async updateExchangeRate(accountId: number | undefined, target: 'from' | 'to', getRate: boolean) {
    if (getRate) {
      const result = await this.getExchangeRate(accountId, target);
      if (!result) return;
    }

    if (
      this.exchangeRateFrom === undefined ||
      this.exchangeRateTo === undefined ||
      this.exchangeRateFrom === 0 ||
      this.exchangeRateTo === 0 ||
      this.movement.amount === undefined
    ) return;

    if (this.movement.movement_type === MovementType.TRANSFER) {
      this.movement = {...this.movement, transfer_movement_amount: this.movement.amount * this.exchangeRateTo / this.exchangeRateFrom};
    }
  }

  async loadLastMovement(payee: Payee | undefined, movementType: MovementType) {
    if (this.movement.id !== 0) return;
    if (!payee) return;
    if (this.movementChanged) return;

    const response = await loadLastMovement(payee, movementType);
    if (!response.ok) return;

    const newMovement = {
      ...response.movement,
      id: 0,
      status: 0,
      date: new Date().toISOString(),
      fiscal_date: new Date().toISOString(),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    this.movement = {
      ...this.movement,
      ...newMovement,
    }

    this.reAssignFields()
    this.movementChanged = false;
  }

  reAssignFields() {
    // Avoid triggering change events
    this.manualChanges = true

    this.roadAccountField.initialize(String(this.movement.account?.id || ''));
    this.roadPayeeField.initialize(String(this.movement.payee?.id || ''));
    this.roadFamilyMemberField.initialize(String(this.movement.family_member?.id || ''));
    this.roadAmountField.value = String(this.movement.amount);
    this.roadDateField.value = this.movement.date;
    this.roadFiscalDateField.value = this.movement.fiscal_date;
    this.roadNotesField.value = this.movement.notes || '';

    if (this.movement.movement_type === MovementType.TRANSFER) {
      this.roadTransferAccountField.initialize(String(this.movement.transfer_movement_account_id || ''));
      this.roadTransferAmountField.value = String(this.movement.transfer_movement_amount);
    } else {
      this.roadCategoryField.initialize(String(this.movement.user_movement_category?.id || ''));
      this.roadSubCategoryField.initialize(String(this.movement.user_movement_sub_category?.id || ''));
    }

    this.manualChanges = false
  }

  renderMovementForm() {
    if (!this.movement) return html``;

    return html`
      <div class="movement-form">
        ${this.renderMovementFields()}
      </div>
    `;
  }

  renderTypeSwitcher() {
    if (this.editing) return html``;

    return html`
      <road-icon
        class="movement-form-header__type-switcher"
        icon="switch_left"
        size="sm"
        @click=${() => {
          this.handleMovementTypeChange();
          this.requestUpdate();
        }}
      >
      </road-icon>`;
  }

  renderPayeeField() {
    const label = this.movement.movement_type === MovementType.INCOME ? 'Payer' : 'Payee';

    return html`
      <road-select
        id="payee"
        class="with-label payee fixed-height-md"
        variant=${SelectVariant.DEFAULT}
        .fieldLabel=${label}
        placeholder='Payee'
        searchText='Search'
        searchable
        .initial=${String(this.movement.payee?.id || '')}
        @selected=${(e: CustomEvent) => {
          this.handlePayeeChange(e);
          this.requestUpdate();
        }}
      >
        ${this.context.payees.map(payee => {
          return html`<road-list-item style="min-width: 250px;" value="${payee.id}">${payee.name}</road-list-item>`;
        })}
      </road-select>
      ${this.renderAddPayee()}
    `;
  }

  renderAddPayee() {
    return html`
      <road-icon
        class="clickable"
        icon="add"
        @click=${() => {
          this.addPayee = true;
        }}
      >
      </road-icon>
    `;
  }

  renderCategoryField() {
    const label = 'Category';

    return html`
      <road-select
        id="accountCategory"
        class="with-label fixed-width-md fixed-height-md"
        variant=${SelectVariant.DEFAULT}
        .fieldLabel=${label}
        placeholder='Category'
        searchText='Search'
        searchable
        .initial=${String(this.movement.user_movement_category?.id || '')}
        @selected=${(e: CustomEvent) => {
          this.handleCategoryChange(e);
          this.requestUpdate();
        }}
      >
        ${this.context.userMovementCategories.map(cat => {
          return html`<road-list-item style="min-width: 250px;" value="${cat.id}">${cat.name}</road-list-item>`;
        })}
      </road-select>
    `;
  }

  renderSubCategoryField() {
    let subCats = this.context.userMovementCategories.find(cat => cat.id === this.movement.user_movement_category?.id)?.user_movement_sub_categories;
    if (!subCats) subCats = [];

    const label = 'Sub category';

    return html`
      <road-select
        id="accountSubCategory"
        class="with-label fixed-width-md fixed-height-md"
        variant=${SelectVariant.DEFAULT}
        .fieldLabel=${label}
        placeholder='Sub category'
        searchText='Search'
        searchable
        .initial=${String(this.movement.user_movement_sub_category?.id || '')}
        .value=${String(this.movement.user_movement_sub_category?.id || '')}
        @selected=${(e: CustomEvent) => {
          this.handleSubCategoryChange(e);
          this.requestUpdate();
        }}
      >
        ${subCats.map(subCat => {
          return html`<road-list-item style="min-width: 250px;" value="${subCat.id}">${subCat.name}</road-list-item>`;
        })}
      </road-select>
    `;
  }

  renderAccountField() {
    const label = this.movement.movement_type === MovementType.INCOME ? 'to Account' : 'from Account';

    return html`
      <road-select
        id="account"
        class="with-label fixed-width-md fixed-height-md"
        variant=${SelectVariant.DEFAULT}
        .fieldLabel=${label}
        placeholder=${label}
        searchText='Search'
        searchable
        .initial=${String(this.movement.account?.id || '')}
        @selected=${(e: CustomEvent) => {
          this.handleAccountChange(e);
          this.requestUpdate();
        }}
      >
        ${this.context.accounts.map(account => {
          return html`<road-list-item style="min-width: 250px;" value="${account.id}">${account.name}</road-list-item>`;
        })}
      </road-select>
    `;
  }

  renderTransferAccountField() {
    const label = 'to Account';

    return html`
      <road-select
        id="transferAccount"
        class="with-label fixed-width-md fixed-height-md"
        variant=${SelectVariant.DEFAULT}
        .fieldLabel=${label}
        placeholder='to Account'
        searchText='Search'
        searchable
        .initial=${String(this.movement.transfer_movement_account_id || '')}
        @selected=${(e: CustomEvent) => {
          this.handleTransferAccountChange(e);
          this.requestUpdate();
        }}
      >
        ${this.context.accounts.map(account => {
          return html`<road-list-item style="min-width: 250px;" value="${account.id}">${account.name}</road-list-item>`;
        })}
      </road-select>
    `;
  }

  renderFamilyMemberField() {
    const label = 'Family Member';

    return html`
      <road-select
        id="familyMember"
        class="with-label fixed-width-md fixed-height-md"
        variant=${SelectVariant.DEFAULT}
        .fieldLabel=${label}
        placeholder='Family Member'
        searchText='Search'
        searchable
        .initial=${String(this.movement.family_member?.id || '')}
        @selected=${(e: CustomEvent) => {
          this.handleFamilyMemberChange(e);
          this.requestUpdate();
        }}
      >
        ${this.context.familyMembers.map(fm => {
          return html`<road-list-item style="min-width: 250px;" value="${fm.id}">${fm.name}</road-list-item>`;
        })}
      </road-select>
    `;
  }

  renderTagsField() {
    const label = 'Tags';

    return html`
      <road-multi-select
        id="tags"
        class="with-label tags fixed-height-md"
        id="tags"
        .fieldLabel=${label}
        placeholder="Select tags"
        searchable
        @change="${(evt: CustomEvent) => {
          this.handleTagsChange(evt);
        }}"
      >
        ${repeat(
          this.context.tags,
          (tag: Tag) => `tag-${tag.id}`,
          (tag: Tag) => {
            return html`<road-list-item
                value="${tag.id}"
                label="${tag.tag}"
                ?selected=${Boolean((this.movement?.tags?.map(movTag => movTag.id) || []).includes(tag.id))}
                ?disabled="${false}"
              >
                ${tag.tag}
              </road-list-item>`
          }
        )}
    </road-multi-select>`;
  }

  renderDateField() {
    const label = 'Date';

    return html`
      <road-input
        id="date"
        class="with-label fixed-width-md fixed-height-md"
        .value=${this.movement.date}
        .fieldLabel=${label}
        placeholder='Date'
        type="date"
        intent="base"
        @change=${(e: CustomEvent) => {
          this.movement.date = e.detail.value;
          this.movement.fiscal_date = e.detail.value;
          this.requestUpdate();
        }}
      ></road-input>
    `;
  }

  renderFiscalDateField() {
    const label = 'Fiscal Date';

    return html`
      <road-input
        id="fiscalDate"
        class="with-label fixed-width-md fixed-height-md"
        .value=${this.movement.fiscal_date}
        .fieldLabel=${label}
        placeholder='Fiscal Date'
        type="date"
        intent="base"
        @change=${(e: CustomEvent) => {
          this.movement.fiscal_date = e.detail.value;
          this.requestUpdate();
        }}
      ></road-input>
    `;
  }

  renderAmountField() {
    const label = this.movement.movement_type === MovementType.TRANSFER ? 'Amount to send' : 'Amount';

    return html`
      <road-input
        id="amount"
        class="with-label fixed-width-md fixed-height-md"
        .value=${String(this.movement.amount)}
        .fieldLabel=${label}
        placeholder=${label}
        type="number"
        intent="base"
        @change=${(e: CustomEvent) => {
          this.handleAmountChange(e);
          this.requestUpdate();
        }}
      ></road-input>
    `;
  }

  renderTransferAmountField() {
    const label = 'Amount to receive';

    return html`
      <road-input
        id="transferAmount"
        class="with-label fixed-width-md fixed-height-md"
        .fieldLabel=${label}
        .value=${String(this.movement.transfer_movement_amount)}
        placeholder='Amount to receive'
        type="number"
        intent="base"
        @change=${(e: CustomEvent) => {
          this.movement.transfer_movement_amount = Number(e.detail.value);
          this.requestUpdate();
        }}
      ></road-input>
    `;
  }

  renderNotesField() {
    const label = 'Notes';

    return html`
      <road-input
        id="notes"
        class="with-label notes fixed-height-md"
        .fieldLabel=${label}
        .value=${this.movement.notes || ''}
        placeholder='Notes'
        type="text"
        intent="base"
        @change=${(e: CustomEvent) => {
          this.movement.notes = e.detail.value;
          this.requestUpdate();
        }}
      ></road-input>
    `;
  }

  renderAttachmentField() {
    if (this.shadowRoot === null) return html``;

    const label = 'Attach files';

    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.style.display = 'none';
    inputElement.setAttribute('multiple', 'true');

    inputElement.addEventListener('change', (event) => this.handleAttachmentChange(event));

    this.shadowRoot.appendChild(inputElement);

    return html`
      <road-button
        intent="primary"
        ?blurOnClick=${true}
        @click=${(e: CustomEvent) => {
          inputElement.click();
        }}
      >
        ${label}
      </road-button>
    `;
  }

  renderMovementFields() {
    let type = '';
    let icon = 'question_mark';
    switch (this.movement.movement_type) {
      case MovementType.INCOME:
        type = 'Income';
        icon = 'trending_up';
        break;
      case MovementType.EXPENSE:
        type = 'Expense';
        icon = 'trending_down';
        break;
      case MovementType.TRANSFER:
        type = 'Transfer';
        icon = 'trending_flat';
        break;
    }

    const headerClasses = classMap({
      'movement-form-header': true,
      'movement-form-header__expense': this.movement.movement_type === MovementType.EXPENSE,
      'movement-form-header__income': this.movement.movement_type === MovementType.INCOME,
      'movement-form-header__transfer': this.movement.movement_type === MovementType.TRANSFER,
    });

    const toggleIcon = this.movement.status === MovementStatus.CONFIRMED ? 'toggle_on' : 'toggle_off';
    const toggleFill = this.movement.status === MovementStatus.CONFIRMED ? 'green' : 'gray';

    return html`
      <div class="movement-form__fields">
        <div class=${headerClasses}>
          <road-icon icon="${icon}" size="sm"></road-icon>
          ${type}
          ${this.renderTypeSwitcher()}
        </div>
        <road-icon
          class="clickable"
          icon=${toggleIcon}
          fill=${toggleFill}
          size="xl"
          @click=${() => {
            this.handleStatusChange();
            this.requestUpdate();
          }}
        ></road-icon>
      </div>

      <div class="movement-form__fields">
        ${this.renderPayeeField()}
      </div>
      <div class="movement-form__fields">
        ${this.renderDateField()}
        ${this.renderFiscalDateField()}
      </div>
      ${this.movement.movement_type !== MovementType.TRANSFER
        ? html`
          <div class="movement-form__fields">
            ${this.renderCategoryField()}
            ${this.renderSubCategoryField()}
          </div>`
        : html``
      }
      <div class="movement-form__fields">
        ${this.renderAccountField()}
        ${this.renderAmountField()}
      </div>
      ${this.movement.movement_type === MovementType.TRANSFER
        ? html`
          <div class="movement-form__fields">
            ${this.renderTransferAccountField()}
            ${this.renderTransferAmountField()}
          </div>`
        : html``
      }
      </div>
      <div class="movement-form__fields">
        ${this.renderFamilyMemberField()}
        ${this.renderAttachmentField()}
      </div>
      <div class="movement-form__fields">
        ${this.renderTagsField()}
      </div>
      <div class="movement-form__fields">
        ${this.renderNotesField()}
      </div>
      <br /><br />
    `;
  }

  renderHeaderCloseButtonMarkup() {
    return html`
      <div slot="header-aside">
        <road-icon icon="close" size="sm"></road-icon>
      </div>
    `;
  }

  handleToggleModal(evt: 'closed' | 'opened' | 'cancel' | 'confirm') {
    if ((evt === 'closed' || evt === 'cancel') && this.open) {
      const event = new CustomEvent(
        evt === 'closed' ? MovementEvents.MOVEMENT_CLOSED : MovementEvents.MOVEMENT_CANCELLED,
        {
          bubbles: true,
          composed: true,
          cancelable: true,
        }
      );
      this.open = false;
      this.dispatchEvent(event);
      return;
    }

    if (evt === 'confirm' && this.open) {
      this.createOrUpdateMovement(this.movement);
      this.open = false;
      return;
    }
  }

  async createOrUpdateMovement(movement: Movement) {
    if (movement.id === 0) {
      const response = await createMovement(movement);
      if (response.ok) {
        this.dispatchEvent(new CustomEvent(
          MovementEvents.MOVEMENT_CREATED,
          {
            detail: { movement: response.movement },
            bubbles: true,
            composed: true,
            cancelable: true,
          }
        ));
      }
    } else {
      const response = await updateMovement(movement);
      if (response.ok) {
        this.dispatchEvent(new CustomEvent(
          MovementEvents.MOVEMENT_UPDATED,
          {
            detail: { movement: response.movement },
            bubbles: true,
            composed: true,
            cancelable: true,
          }
      ));
      }
    }
  }

  renderAddPayeeModal() {
    return html`
      <road-payee
        ?open=${this.addPayee}
        @closed=${this.hideAddPayeeModal}
        @cancel=${this.hideAddPayeeModal}
        @confirm=${this.applyAddPayeeModal}
      >
      </road-payee>
    `;
  }

  private hideAddPayeeModal() {
    this.addPayee = false;
    this.requestUpdate();
  }

  private applyAddPayeeModal(event: CustomEvent) {
    this.addPayee = false;
    if (!event.detail) return;

    if (event.detail.created) {
      this.context.payees.unshift(event.detail.payee);
      this.movement = {...this.movement, payee: event.detail.payee};
      this.roadPayeeField.initialize(String(this.movement.payee?.id || ''));
    }

    this.requestUpdate();
  }

  render() {
    const okLabel = this.editing ? 'Update' : 'Create';
    const cancelLabel = 'Close';

    return html`
      <road-modal
        ?open=${this.open}
        ?actions=${true}
        label="Transaction details"
        .okLabel=${okLabel}
        .cancelLabel=${cancelLabel}
        @closed=${() => this.handleToggleModal('closed')}
        @opened=${() => this.handleToggleModal('opened')}
        @cancel=${() => this.handleToggleModal('cancel')}
        @ok=${() => this.handleToggleModal('confirm')}
      >
        ${this.renderHeaderCloseButtonMarkup()}
        ${this.renderMovementForm()}
        ${this.renderAddPayeeModal()}

      </road-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-movement': RoadMovement;
  }
}
