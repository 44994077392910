import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../lib/component';

import styles from './style.scss';

export const COMPONENT_TAG = 'road-tab-panel';

@customElement(COMPONENT_TAG)
export default class RoadTabPanel extends RoadComponent {
  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @query('.road-tab-panel') $node!: HTMLElement;

  @property() name = '';

  @property({type: Boolean, reflect: true}) active = false;
  @property({type: Boolean, reflect: true}) gridPanel = false;

  static get styles() {
    return [styles];
  }

  get classes() {
    return {
      'road-tab-panel': true,
      'road-tab-panel--active': this.active,
    };
  }

  render() {
    if (!this.active) {
      this.style.display = 'none';
    } else if (this.gridPanel) {
      this.style.display = 'grid';
    } else {
      this.style.display = 'block';
    }

    return html`
      <div
        class=${classMap(this.classes)}
        role="tabpanel"
        aria-selected=${this.active ? 'true' : 'false'}
        aria-hidden=${this.active ? 'false' : 'true'}
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_TAG]: RoadTabPanel;
  }
}
