import '../icon';

import {html} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';

import {v5} from 'uuid';

import {RoadComponent} from '../../../lib/component';

import styles from './style.scss';

/**
 * Tag name for collapsible elements.
 */
export const COLLAPSIBLE_ELEMENT_TAG = 'road-collapsible';

/**
 * Enum containing all variants / styles for collapsibles.
 */
export enum CollapsibleVariant {
  BASE = 'base',
  CAPSULE = 'capsule',
}

/**
 * Namspace for generating V5 hashes.
 */
const UUID_NAMESPACE = 'bc5be6d7-6324-486f-8541-1fe0c214a874';
/**
 * Symbol to use when generating an empty UUID.
 */
const EMPTY_UUID_KEY = '_';

type CollapsibleState = {
  collapsed: boolean;
};

/**
 * Component for rendeirng collapsible content.
 */
@customElement(COLLAPSIBLE_ELEMENT_TAG)
export default class Collapsible extends RoadComponent {
  /**
   * The variant / style of the collapsible.
   */
  @property()
  variant: CollapsibleVariant = CollapsibleVariant.BASE;
  /**
   * The label to display for the collapsible.
   */
  @property()
  label = '';
  /**
   * A unique key for storing locally. Required if `persist` is true.
   */
  @property()
  key = this.label?.toLowerCase().replace(' ', '_') || '';
  /**
   * Determines whether or not the collapsible is collapsed.
   */
  @property({type: Boolean})
  collapsed = false;
  /**
   * Flag determining whether or not to persist the collapsible's state locally.
   */
  @property({type: Boolean})
  persist = false;

  /**
   * The key used for storing locally.
   */
  @state()
  private _uuid = v5(this.key || EMPTY_UUID_KEY, UUID_NAMESPACE);

  static get styles() {
    return [styles];
  }

  private get stateObject(): CollapsibleState {
    return {
      collapsed: this.collapsed,
    };
  }

  /**
   * Returns a readable UUID string.
   */
  get uuid() {
    return this._uuid.toString();
  }

  /**
   * The icon representing the collapsed state (i.e., and up or down arrow)
   */
  get icon() {
    return this.collapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
  }

  /**
   * Classes based on variants.
   */
  private classes(): ClassInfo {
    return {
      'road-collapsible--capsule': this.variant === CollapsibleVariant.CAPSULE,
    };
  }

  /**
   * Generates a JSON string of a CollapsibleState.
   */
  private serializeState() {
    return JSON.stringify(this.stateObject);
  }

  /**
   * Reads the CollapsibleState from localStorage and hydrates component.
   */
  private deserializeState() {
    const storage = localStorage.getItem(this.uuid);
    if (!storage) {
      return console.error(`Nothing persisted matching ${this.key}`);
    }

    const state = JSON.parse(storage) as CollapsibleState;
    this.collapsed = state.collapsed;

    this.requestUpdate();
  }

  /**
   * Stores a serialized CollapsibleState in localStorage.
   */
  private store() {
    localStorage.setItem(this.uuid, this.serializeState());
  }

  beforeLoad() {
    if (this.persist && !this.key) {
      throw Error(
        'You cannot persist a collapsible without a unique key; please provide one.'
      );
    }

    if (this.persist) {
      this.deserializeState();
    }
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has('collapsed')) {
      this.store();
    }
  }

  /**
   * Collpses the component.
   */
  collapse() {
    this.collapsed = true;
  }

  /**
   * Expands the component.
   */
  expand() {
    this.collapsed = false;
  }

  render() {
    return html`
      <div class="road-collapsible ${classMap(this.classes())}">
        <div
          @click=${() => {
            if (this.collapsed) return this.expand();
            if (!this.collapsed) return this.collapse();
          }}
          class="road-collapsible__header ${classMap({
            'road-collapsible__header--collapsed': this.collapsed,
          })}"
        >
          <span class="road-collapsible__label">${this.label}</span>
          <road-icon icon=${this.icon}></road-icon>
        </div>
        <div
          class="road-collapsible__body ${classMap({
            'road-collapsible__body--collapsed': this.collapsed,
          })}"
        >
          <slot></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COLLAPSIBLE_ELEMENT_TAG]: Collapsible;
  }
}
