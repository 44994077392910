import '../button';
import '../loader_button';

import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {RoadComponent} from '../../../lib/component';
import styles from './style.scss';

export const MODAL_ELEMENT_TAG = 'road-modal';
const DEFAULT_OK_LABEL = 'OK';
const DEFAULT_CANCEL_LABEL = 'Cancel';

export enum ModalStatus {
  OPENED = 'opened',
  CLOSED = 'closed',
}

/**
 * this is used as a deterministic way to know whether there is an active modal from anywhere on the page.
 * It has been added specifically to allow better positioning of dropdown-like elements placed inside a modal
 */
export const activeRoadModalRegistry: Set<RoadModal> = new Set<RoadModal>();

/**
 * Modal -- Description here
 */
@customElement(MODAL_ELEMENT_TAG)
export default class RoadModal extends RoadComponent {
  @property()
  key: string = `${new Date().getTime()}-${Math.random()}`;

  @property({type: Boolean, reflect: true})
  open = false;

  @property({type: Boolean})
  actions = false;

  @property({type: Boolean})
  async = false;

  @property({type: Boolean})
  loading = false;

  @property()
  label = '';

  @property()
  okLabel = DEFAULT_OK_LABEL;

  @property({type: Boolean})
  disablePrimaryAction: boolean = false;

  @property({type: Boolean})
  disableSecondaryAction: boolean = false;

  @property({type: Boolean})
  preventCloseOnPrimaryAction: boolean = false;

  @property()
  cancelLabel = DEFAULT_CANCEL_LABEL;

  static get styles() {
    return [styles];
  }

  get contentClasses(): unknown {
    return classMap({
      'road-modal__content--visible': this.open,
      'road-modal__content--with-label': this.label,
    });
  }

  openModal() {
    if (this.open) return;
    this.open = true;
    this.modalEventDispatch('opened');
  }

  closeModal() {
    if (!this.open) return;
    this.open = false;
    this.modalEventDispatch('closed');
  }

  modalEventDispatch(evt: 'opened' | 'cancel' | 'closed' | 'ok') {
    this.dispatchEvent(new CustomEvent(evt));
    window.dispatchEvent(
      new CustomEvent(MODAL_ELEMENT_TAG, {
        detail: {
          key: this.key,
          tag: evt,
          open: this.open,
        },
      })
    );
  }

  updated(changedProperties: Map<string, unknown>) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName !== 'open') return;
      if (oldValue === this.open) return;
      this.modalEventDispatch(this.open ? 'opened' : 'closed');
      this.open
        ? activeRoadModalRegistry.add(this)
        : activeRoadModalRegistry.delete(this);
    });
  }

  cancel() {
    if (this.open) this.open = false;
    this.modalEventDispatch('cancel');
  }

  ok() {
    if (!this.preventCloseOnPrimaryAction && this.open) {
      this.open = false;
    }
    this.modalEventDispatch('ok');
  }

  renderBody() {
    return html`
      <div class="road-modal__content__body-wrapper">
        <div
          class="road-modal__content__body"
          @scroll=${() =>
            this.dispatchEvent(new CustomEvent('scroll'))}
        >
          <slot></slot>
        </div>
      </div>`;
  }

  renderHeader() {
    if (!this.label) return html``;

    return html`
      <div class="road-modal__content__header">
        <div
          style="display: grid; align-items: center; grid-template-columns: 1fr auto;"
        >
          <div style="display: flex; align-items: center;" class="header-label">
            <slot name="header-label-prefix"></slot>
            ${this.label}
          </div>
          <div
            class="road-modal__content__header__aside"
            @click=${() => this.closeModal()}
          >
            <slot name="header-aside"></slot>
          </div>
        </div>
        <div></div>
        <div class="road-modal__content__header__border"></div>
      </div>
    `;
  }

  renderFooter() {
    if (!this.actions && !this.async) return html``;

    return html`
      <div class="road-modal__content__footer">
        <div class="road-modal__content__footer__border"></div>
        <div class="road-modal__content__footer__aside">
          <slot name="footer-aside"></slot>
        </div>
        <div class="road-modal__content__footer__main">
          <div
            class="road-modal__content__footer__cancel"
            @click="${() => this.cancel()}"
          >
            <road-button
              variant="link"
              intent="secondary"
              ?disabled=${this.disableSecondaryAction}
              ?blurOnClick=${true}
            >
              ${this.cancelLabel}
            </road-button>
            <slot name="cancel"></slot>
          </div>
          <div
            class="road-modal__content__footer__accept"
            @click=${this.ok.bind(this)}
          >
            ${this.async
              ? html`
                  <road-loader-button
                    ?disabled=${this.disablePrimaryAction ||
                    this.loading}
                    ?blurOnClick=${true}
                    ?loading=${this.loading}
                  >
                    ${this.okLabel}
                  </road-loader-button>
                `
              : html`<road-button
                  ?disabled=${this.disablePrimaryAction}
                  ?blurOnClick=${true}
                >
                  ${this.okLabel}
                </road-button>`}
            <slot name="accept"></slot>
          </div>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="road-modal__anchor" @click="${() => this.openModal()}">
        <slot name="anchor"></slot>
      </div>
      ${this.open
        ? html`
          <div class="road-modal">
            <div
              class="road-modal__backdrop"
              @click="${() => this.closeModal()}"
            ></div>
              <div class="road-modal__content ${this.contentClasses}">
                ${this.renderHeader()}
                ${this.renderBody()}
                ${this.renderFooter()}
              </div>
          </div>
        `
      : ''}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-modal': RoadModal;
  }
}
