import {html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import {RoadComponent} from '../../../../lib/component';

import styles from './style.scss';

export enum TextFiltersEvents {
  TEXT_CHANGED = 'filters-text-changed',
}

@customElement('road-text-filters')
export class RoadTextFilters extends RoadComponent {
  @property({type: String})
  text?: string;

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.text = undefined;
  }

  handleTextChange(evt: CustomEvent): void {
    this.text = evt.detail.value;
    this.dispatchTextChangedEvent(TextFiltersEvents.TEXT_CHANGED, this.text);
  }

  dispatchTextChangedEvent(evtType: string, value?: string): void {
    this.dispatchEvent(
      new CustomEvent(evtType, {
        bubbles: true,
        composed: true,
        detail: {
          value: value,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-text-filters__filter">
          <label for="from">Comments</label>
          <road-input
            id="comments"
            placeholder="Comments"
            type="text"
            .value=${this.text ?? ''}
            intent="base"
            @change=${(e: CustomEvent<string>) => {
              this.handleTextChange(e);
            }}
          >
          </road-input>
        </div>
      `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-text-filters': RoadTextFilters;
  }
}
