import {html} from 'lit';
import {property, customElement, queryAll} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import styles from './style.scss';

import {Currency} from '../../../../types/currency';
import RoadCheckbox from 'src/components/base/checkbox';

export enum CurrencyFiltersEvents {
  CURRENCIES_CHANGED = 'filters-currencies-changed',
}

@customElement('road-currency-filters')
export class RoadCurrencyFilters extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Array})
  selectedCurrencies: Currency[] = [];

  @queryAll('road-checkbox[name="currencies"]') checkboxes!: RoadCheckbox[];

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.selectedCurrencies = [];
    this.checkboxes.forEach(checkbox => checkbox.deselect());
    this.requestUpdate();
  }

  handleCurrenciesChange(evt: CustomEvent): void {
    const currency = this.context.currencies.find((currency: Currency) => currency.id === Number(evt.detail.value));
    if (!currency) return;

    if (evt.detail.checked) {
      this.selectedCurrencies.push(currency)
    } else {
      this.selectedCurrencies = this.selectedCurrencies.filter((selectedCurrency: Currency) => selectedCurrency.id !== currency.id);
    }

    this.dispatchCurrenciesChangedEvent();
  }

  dispatchCurrenciesChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent(CurrencyFiltersEvents.CURRENCIES_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: this.selectedCurrencies,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-currency-filters__filter">
          <label for="currencies">Currencies</label>
          <div class="road-currency-filters__checks-container">
            ${repeat(
              this.context.currencies,
              (currency: Currency) => `currency-filter-${currency.id}`,
              (currency: Currency) => {
                return html`<road-checkbox
                  name="currencies"
                  value="${currency.id}"
                  label="${currency.name}"
                  ?checked="${this.selectedCurrencies.includes(currency)}"
                  @change="${(evt: CustomEvent) => {
                    this.handleCurrenciesChange(evt);
                  }}"
                >
                  ${currency.name}
                </road-checkbox>`
              }
            )}
          </div>
        </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-currency-filters': RoadCurrencyFilters;
  }
}
