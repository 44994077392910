import {CSSResultArray, html, nothing} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import {classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';

import RoadFormField, {
  ActionTag,
  FormFieldSchemasAllProperties,
  FormFieldSchema,
  FormFieldValueTypeRepr,
  FORM_FIELD_STATUS,
} from '../../../components/abstract/form_field';
import {isNonEmptyString} from '../../../utils';
import {hasProperty} from '../../../utils/objects';
import {CustomInputEvent} from '../input';
import styles from './style.scss';

export const COMPONENT_TAG = 'road-form-input-field';

export enum FormInputFieldType {
  HIDDEN = 'hidden',
  TEXT = 'text',
  DATE = 'date',
  URL = 'url',
  NUMBER = 'number',
  EMAIL = 'email',
  PASSWORD = 'password',
}

@customElement(COMPONENT_TAG)
export default class RoadFormInputField extends RoadFormField {
  @property({type: String})
  name = '';

  @property({type: String})
  label = '';

  @property({type: Boolean})
  required = false;

  @state()
  schema: FormFieldSchema | null = null;

  @property()
  initial: string | number | null = null;

  @property()
  value: string | number | null = null;

  @property({type: String})
  type: FormInputFieldType = FormInputFieldType.TEXT;

  @property({type: Number})
  maxlength!: number;

  @property({type: Number})
  minlength!: number;

  @property({type: Number})
  max!: number;

  @property({type: Number})
  min!: number;

  @property({type: String})
  minDate?: string;

  @property({type: String})
  pattern!: string;

  @property({type: Boolean})
  integer = false;

  @property({type: Number, reflect: true})
  step: number | undefined;

  @property({type: String})
  width!: string;

  static get styles(): CSSResultArray {
    return [styles];
  }

  load() {
    if (this.initial) this.value = this.initial;
    if (this.type === FormInputFieldType.NUMBER) {
      this.schema = {
        type: FormFieldValueTypeRepr.NUMBER,
        min: this.min ?? Number.MIN_SAFE_INTEGER,
        max: this.max ?? Number.MAX_SAFE_INTEGER,
        integer: this.integer,
        step: this.integer ? 1 : this.step,
        pattern: this.pattern,
      };
    } else {
      this.schema = {
        type: FormFieldValueTypeRepr.STRING,
        maxlength: this.maxlength,
        minlength: this.minlength,
        pattern: this.pattern,
      };
    }
  }

  getSchemaAttributeValue(
    attributeName: keyof FormFieldSchemasAllProperties,
    fallbackValue?: unknown
  ) {
    if (!this.schema) {
      return nothing;
    }

    return hasProperty(this.schema, attributeName)
      ? ifDefined(this.schema[attributeName])
      : fallbackValue ?? nothing;
  }

  fieldMarkup() {
    if (!this.schema) {
      return html``;
    }

    const classes = {
      'road-form__field': true,
      'road-form__field--input': true,
      'road-form__field--active': this.active,
      'road-form__field--hidden': this.hidden,
      'road-form__field--input--active': this.active,
      'road-form__field--disabled': this.disabled,
      'road-form__field--input--disabled': this.disabled,
      'road-form__field--error': this.errors.length > 0,
      'road-form__field--input--error': this.errors.length > 0,
      'road-form__field--valid': this.errors.length === 0,
      'road-form__field--input--valid': this.errors.length === 0,
    };

    const styles = {
      '--road-input-width': isNonEmptyString(this.width)
        ? this.width.trim()
        : '100%',
    };

    return html`
      <div class=${classMap(classes)} style=${styleMap(styles)}>
        <road-input
          key=${this.key}
          ?active=${this.active}
          type=${this.type}
          intent=${this.intent}
          ?disabled=${this.disabled}
          ?readonly=${this.readonly}
          width=${this.width}
          name=${isNonEmptyString(this.name) ? this.name : this.key}
          value=${ifDefined(this.value)}
          ?required=${this.required}
          min=${this.getSchemaAttributeValue('min')}
          max=${this.getSchemaAttributeValue('max')}
          .minDate=${ifDefined(this.minDate)}
          minlength=${this.getSchemaAttributeValue('minlength')}
          maxlength=${this.getSchemaAttributeValue('maxlength')}
          pattern=${this.getSchemaAttributeValue('pattern')}
          step=${this.getSchemaAttributeValue('step')}
          placeholder=${ifDefined(this.placeholder)}
          @input=${(evt: CustomEvent<CustomInputEvent>) => {
            this.action({
              tag: ActionTag.UPDATE_STATUS,
              value: [
                [FORM_FIELD_STATUS.ACTIVE, true],
                [FORM_FIELD_STATUS.VISITED, true],
                [FORM_FIELD_STATUS.TOUCHED, true],
              ],
            });
            this.action({
              tag: ActionTag.UPDATE_VALUE,
              value: evt.detail.value,
            });
          }}
          @blur=${(evt: CustomEvent<CustomInputEvent>) => {
            this.action({
              tag: ActionTag.UPDATE_STATUS,
              value: [
                [FORM_FIELD_STATUS.ACTIVE, false],
                [FORM_FIELD_STATUS.TOUCHED, true],
              ],
            });

            this.action({
              tag: ActionTag.UPDATE_VALUE,
              value: evt.detail.value,
            });
          }}
        >
        </road-input>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_TAG]: RoadFormInputField;
  }
}
