import {html, nothing} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../lib/component';
import styles from './style.scss';
import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  offset,
  Placement,
  shift,
} from '@floating-ui/dom';

import {whenReady} from '../../../utils/dom';

export const TOOLTIP_ELEMENT_TAG = 'road-tooltip';
const ARROW_OFFSET = '-6px';

export enum TooltipDisplayStyle {
  BLOCK = 'block',
  NONE = 'none',
}

/**
 * All possible style variants for tooltips.
 */
export enum TooltipVariant {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

/**
 * General-use tooltip component for custom elements.
 */
@customElement(TOOLTIP_ELEMENT_TAG)
export default class RoadTooltip extends RoadComponent {
  @property()
  content = '';

  @property()
  variant: TooltipVariant = TooltipVariant.DEFAULT;

  @property({type: Boolean})
  disabled = false;

  @property({type: String})
  placement: Placement = 'top';

  @state()
  externalAnchor?: HTMLElement;

  @query('#tooltip')
  tooltip?: HTMLElement;

  @query('#anchor')
  anchor?: HTMLElement;

  @query('#arrow')
  arrow?: HTMLElement;

  get referenceElement(): HTMLElement | undefined {
    return this.externalAnchor ?? this.anchor;
  }

  static get styles() {
    return [styles];
  }

  private cancelAutoUpdate = () => {};

  private classes(): ClassInfo {
    return {
      'road-tooltip': true,
      'road-tooltip--primary': this.variant === TooltipVariant.PRIMARY,
      'road-tooltip--secondary': this.variant === TooltipVariant.SECONDARY,
      'road-tooltip--disabled': this.disabled,
    };
  }

  protected updated(changedProperties: Map<PropertyKey, unknown>): void {
    super.updated(changedProperties);
    if (changedProperties.has('externalAnchor')) {
      const oldValue = changedProperties.get('externalAnchor');
      const newValue = this.externalAnchor;

      if (oldValue !== newValue) {
        this.resetTooltip();
      }
    }
  }

  resetTooltip() {
    this.cancelAutoUpdate();
    this.calculatePositioning();
    this.listenTooltipPosition();
  }

  updateTooltipDisplay(style: TooltipDisplayStyle) {
    if (!this.tooltip) return;
    this.tooltip.style.display = style;
  }

  calculatePositioning() {
    computePosition(this.referenceElement!, this.tooltip!, {
      placement: this.placement ?? 'top',
      strategy: 'fixed',
      middleware: [offset(12), flip(), shift(), arrow({element: this.arrow!})],
    }).then(({x, y, placement, middlewareData}) => {
      Object.assign(this.tooltip!.style, {
        left: `${x}px`,
        top: `${y}px`,
      });

      const {x: arrowX, y: arrowY} = middlewareData.arrow!;

      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[placement.split('-')[0]] as string;

      Object.assign(this.arrow!.style, {
        // Intentional single equals to catch both null cases.
        // eslint-disable-next-line eqeqeq
        left: arrowX != null ? `${arrowX}px` : '',
        // eslint-disable-next-line eqeqeq
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: ARROW_OFFSET,
      });

      switch (staticSide) {
        case 'top':
          this.arrow!.classList.add('bottom');
          break;
        case 'right':
          this.arrow!.classList.add('right');
          break;
        case 'bottom':
        default:
          this.arrow!.classList.remove('bottom');
          break;
      }
    });
  }

  listenTooltipPosition() {
    this.cancelAutoUpdate = autoUpdate(
      this.referenceElement!,
      this.tooltip!,
      () => this.calculatePositioning()
    );

    /** Set initial styles of tooltip */
    Object.assign(this.tooltip!.style, {
      visibility: 'visible',
      display: 'none',
    });
  }

  rendered() {
    if (!this.tooltip || !this.referenceElement || !this.arrow) return;
    whenReady(() => this.listenTooltipPosition());
  }

  renderFloatingTooltip() {
    return html`
      <div id="tooltip" role="tooltip" class=${classMap(this.classes())}>
        ${this.content}
        <slot name="content"></slot>
        <div id="arrow" class="road-tooltip__arrow"></div>
      </div>
    `;
  }

  renderAnchorSlot() {
    if (this.externalAnchor) {
      return nothing;
    }

    return html`
      <div
        id="anchor"
        part="anchor"
        aria-describedby="tooltip"
        @mouseenter=${() => {
          this.updateTooltipDisplay(TooltipDisplayStyle.BLOCK);
        }}
        @mouseleave=${() => {
          this.updateTooltipDisplay(TooltipDisplayStyle.NONE);
        }}
        @focus=${() => {
          this.updateTooltipDisplay(TooltipDisplayStyle.BLOCK);
        }}
        @blur=${() => {
          this.updateTooltipDisplay(TooltipDisplayStyle.NONE);
        }}
      >
        <slot></slot>
      </div>
    `;
  }

  render() {
    return html` ${this.renderFloatingTooltip()} ${this.renderAnchorSlot()} `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TOOLTIP_ELEMENT_TAG]: RoadTooltip;
  }
}
