import {html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import styles from './style.scss';

import {Payee} from '../../../../types/payee';
import {SelectVariant} from '../../../base/select';
import RoadMultiSelect, {Option} from 'src/components/base/multiselect';

export enum PayeeFiltersEvents {
  PAYEES_CHANGED = 'filters-payees-changed',
}

@customElement('road-payee-filters')
export class RoadPayeeFilters extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Array})
  selectedPayees: Payee[] = [];

  @query('road-multi-select#payees') select!: RoadMultiSelect;

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.selectedPayees = [];
    this.select.deselectAllOptions();
  }

  handlePayeesChange(evt: CustomEvent): void {
    this.selectedPayees = evt.detail.selected.map((option: Option) => {
      return this.context.payees.find((payee: Payee) => payee.id === Number(option.value));
    });

    this.dispatchPayeesChangedEvent();
  }

  dispatchPayeesChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent(PayeeFiltersEvents.PAYEES_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: this.selectedPayees,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-payee-filters__filter">
          <label for="payees">Payees</label>
          <road-multi-select
            id="payees"
            placeholder="Select payees"
            searchable
            @change="${(evt: CustomEvent) => {
              this.handlePayeesChange(evt);
            }}"
          >
            ${repeat(
              this.context.payees,
              (payee: Payee) => `payee-filter-${payee.id}`,
              (payee: Payee) => {
                return html`<road-list-item
                    value="${payee.id}"
                    label="${payee.name}"
                    ?selected="${this.selectedPayees.includes(payee)}"
                    ?disabled="${false}"
                  >
                    ${payee.name}
                  </road-list-item>`
              }
            )}
          </road-multi-select>
        </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-payee-filters': RoadPayeeFilters;
  }
}
