import '../headline';

import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../lib/component';

import styles from './style.scss';

export const CALL_OUT_ELEMENT_TAG = 'road-call-out';

export enum CallOutVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

/**
 * CallOut -- Description here
 */
@customElement(CALL_OUT_ELEMENT_TAG)
export default class RoadCallOut extends RoadComponent {
  @property()
  variant: CallOutVariants = CallOutVariants.SECONDARY;

  @property()
  headline = '';

  @property()
  caption = '';

  @property({type: Boolean})
  absolute = false;

  @property({type: Boolean})
  relative = false;

  @property({type: Boolean})
  icon = true;

  static get styles() {
    return [styles];
  }

  get classes(): unknown {
    return classMap({
      'road-call-out--absolute': this.absolute,
      'road-call-out--relative': this.relative,
      'road-call-out--primary': this.variant === CallOutVariants.PRIMARY,
      'road-call-out--secondary': this.variant === CallOutVariants.SECONDARY,
    });
  }

  render() {
    return html`
      <div class="road-call-out ${this.classes}">
        <!-- Icon -->
        ${this.icon ? html` <div class="road-call-out__icon">!</div> ` : ''}

        <!-- Headline -->
        ${this.headline
          ? html`<road-headline size="md">${this.headline}</road-headline>`
          : ''}

        <!-- Caption -->
        ${this.caption
          ? html`<div class="road-call-out__caption">${this.caption}</div>`
          : ''}
        <div class="road-call-out__content">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    CALL_OUT_ELEMENT_TAG: RoadCallOut;
  }
}
