import {html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import styles from './style.scss';

import {Tag} from '../../../../types/movement';
import {SelectVariant} from '../../../base/select';
import RoadMultiSelect, {Option} from 'src/components/base/multiselect';

export enum TagFiltersEvents {
  TAGS_CHANGED = 'filters-tags-changed',
}

@customElement('road-tag-filters')
export class RoadTagFilters extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Array})
  selectedTags: Tag[] = [];

  @query('road-multi-select#tags') select!: RoadMultiSelect;

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.selectedTags = [];
    this.select.deselectAllOptions();
  }

  handleTagsChange(evt: CustomEvent): void {
    this.selectedTags = evt.detail.selected.map((option: Option) => {
      return this.context.tags.find((tag: Tag) => tag.id === Number(option.value));
    });

    this.dispatchTagsChangedEvent();
  }

  dispatchTagsChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent(TagFiltersEvents.TAGS_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: this.selectedTags,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-tag-filters__filter">
          <label for="tags">Tags</label>
          <road-multi-select
            id="tags"
            placeholder="Select tags"
            searchable
            @change="${(evt: CustomEvent) => {
              this.handleTagsChange(evt);
            }}"
          >
            ${repeat(
              this.context.tags,
              (tag: Tag) => `tag-filter-${tag.id}`,
              (tag: Tag) => {
                return html`<road-list-item
                    value="${tag.id}"
                    label="${tag.tag}"
                    ?selected="${this.selectedTags.includes(tag)}"
                    ?disabled="${false}"
                  >
                    ${tag.tag}
                  </road-list-item>`
              }
            )}
          </road-multi-select>
        </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-tag-filters': RoadTagFilters;
  }
}
