import {html} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import styles from './style.scss';

import {Account} from '../../../../types/account';
import {SelectVariant} from '../../../base/select';
import RoadMultiSelect, {Option} from 'src/components/base/multiselect';

export enum AccountFiltersEvents {
  ACCOUNTS_CHANGED = 'filters-accounts-changed',
}

@customElement('road-account-filters')
export class RoadAccountFilters extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Array})
  selectedAccounts: Account[] = [];

  @query('road-multi-select#accounts') select!: RoadMultiSelect;

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.selectedAccounts = [];
    this.select.deselectAllOptions();
  }

  handleAccountsChange(evt: CustomEvent): void {
    this.selectedAccounts = evt.detail.selected.map((option: Option) => {
      return this.context.accounts.find((account: Account) => account.id === Number(option.value));
    });

    this.dispatchAccountsChangedEvent();
  }

  dispatchAccountsChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent(AccountFiltersEvents.ACCOUNTS_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: this.selectedAccounts,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-account-filters__filter">
          <label for="accounts">Accounts</label>
          <road-multi-select
            id="accounts"
            placeholder="Select accounts"
            searchable
            @change="${(evt: CustomEvent) => {
              this.handleAccountsChange(evt);
            }}"
          >
            ${repeat(
              this.context.accounts,
              (account: Account) => `account-filter-${account.id}`,
              (account: Account) => {
                return html`<road-list-item
                    value="${account.id}"
                    label="${account.name}"
                    ?selected="${this.selectedAccounts.includes(account)}"
                    ?disabled="${false}"
                  >
                    ${account.name}
                  </road-list-item>`
              }
            )}
          </road-multi-select>
        </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-account-filters': RoadAccountFilters;
  }
}
