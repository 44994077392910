export enum SupportedLocales {
  EN = 'en',
}

export function getSupportedLocale(value: unknown): SupportedLocales {
  if (
    typeof value === 'string' &&
    Object.values<string>(SupportedLocales).includes(value)
  ) {
    return value as SupportedLocales;
  }
  return SupportedLocales.EN;
}
