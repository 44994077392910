import {fetcher} from '../utils/http';
import {RequestMethod} from '../common/constants';

import {ApiResponse} from './api';

import {UserExchangeRate} from '../types/user_exchange_rate';

export interface UserExchangeRatesResponse extends ApiResponse { exchangeRates?: UserExchangeRate[]; };

export async function loadUserExchangeRates(): Promise<UserExchangeRatesResponse> {
  const url = '/api/user_exchange_rates';

  try {
    const response = await fetcher<UserExchangeRatesResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}
