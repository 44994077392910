import '../icon';

import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {RoadComponent} from '../../../../src/lib/component';
import styles from './style.scss';
import {classMap, ClassInfo} from 'lit/directives/class-map.js';

// Constants
export const ACTION_BUTTON_ELEMENT_TAG = 'road-action-button';

// Enums
export enum ActionButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
}

/**
 * Special icon button with light background, used for signalling
 * specific actions.
 */
@customElement(ACTION_BUTTON_ELEMENT_TAG)
export default class RoadActionButton extends RoadComponent {
  @property()
  icon = '';

  @property()
  variant = '';

  @property({type: Boolean})
  outlined = false;

  static get styles() {
    return [styles];
  }

  get classes(): ClassInfo {
    return {
      'road-action-button--primary': this.variant === ActionButtonVariants.PRIMARY,
      'road-action-button--secondary': this.variant === ActionButtonVariants.SECONDARY,
      'road-action-button--green': this.variant === ActionButtonVariants.GREEN,
      'road-action-button--yellow': this.variant === ActionButtonVariants.YELLOW,
      'road-action-button--red': this.variant === ActionButtonVariants.RED,
      'road-action-button--outlined': this.outlined,
    };
  }

  render() {
    return html`
      <div class="road-action-button ${classMap(this.classes)}">
        <div class="road-action-button__icon">
          <road-icon icon=${this.icon}></road-icon>
        </div>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-action-button': RoadActionButton;
  }
}
