import {html, TemplateResult, CSSResultArray} from 'lit';
import {query} from 'lit-element';
import {StyleInfo, styleMap} from 'lit/directives/style-map.js';
import {customElement, property} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';

import RoadFloatingSurface, {
  Direction,
  FloatingSurfaceEvent,
  COMPONENT_TAG as FLOATING_SURFACE_COMPONENT_TAG,
} from '../floating_surface';
import {RoadComponent} from '../../../lib/component';
import {isNonEmptyString} from '../../../utils';
import {whenReady} from '../../../utils/dom';
import styles from './style.scss';
//import {NavEvents} from '../sidenav';
import {debounce} from '../../../utils/debounce';
import {activeRoadModalRegistry} from '../modal';

export const COMPONENT_TAG = 'road-dropdown';
const DEFAULT_SANDBOX_SELECTOR = 'body';
const SPACE_BETWEEN_ANCHOR_AND_MENU = 8; // in px;

enum DimentionPredicate {
  TOP = 'MEETS_TOP_POSITIONING_SPACE_REQUIREMENT',
  BOTTOM = 'MEETS_BOTTOM_POSITIONING_SPACE_REQUIREMENT',
  RIGHT = 'MEETS_RIGHT_POSITIONING_SPACE_REQUIREMENT',
  LEFT = 'MEETS_LEFT_POSITIONING_SPACE_REQUIREMENT',
}

export enum MsgTag {
  OPEN = 'open',
  CLOSE = 'close',
  SLOT_CHANGE = 'slotchange',
  UPDATE_DIMENSIONS = 'update-dimensions',
}

enum SlotName {
  ANCHOR = 'anchor',
  SURFACE = 'surface',
}

type Msg =
  | {
      tag: MsgTag.OPEN | MsgTag.CLOSE | MsgTag.UPDATE_DIMENSIONS;
    }
  | {tag: MsgTag.SLOT_CHANGE; evt: Event; slot: SlotName};

@customElement(COMPONENT_TAG)
export default class RoadDropdown extends RoadComponent {
  @property()
  sandboxSelector = DEFAULT_SANDBOX_SELECTOR;

  @property()
  anchorSelector = '';

  @property()
  surfaceSelector = '';

  @property({type: Boolean, reflect: true})
  active = false;

  @property({type: Boolean, reflect: true})
  disabled = false;

  @property({type: Boolean, reflect: true})
  hidden = false;

  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @property({type: Number})
  surfaceX = 0;

  @property({type: Number})
  surfaceY = 0;

  @query('#surface-container')
  $internalSurface!: RoadFloatingSurface;

  $sandbox: HTMLElement | null = null;
  $anchor: HTMLElement | null = null;
  $surface: HTMLElement | null = null;

  surfaceDirection = Direction.TOP_RIGHT;

  scrollListeners: Map<HTMLElement, EventListener> = new Map<
    HTMLElement,
    EventListener
  >();
  resizeListeners: Map<HTMLElement, EventListener> = new Map<
    HTMLElement,
    EventListener
  >();

  private listeningDOMReady = false;

  /**
   * Styles
   */
  static get styles(): CSSResultArray {
    return [styles];
  }

  load() {
    this.subscriptions();
  }

  subscriptions() {
    const onResize = () => {
      this.surfacePosition();
      // this.close();
    };

    const onScroll = () => {
      if (!this.active) return;
      this.surfacePosition();
    };

    window.addEventListener(
      FLOATING_SURFACE_COMPONENT_TAG,
      this.onFloatingSurfaceCustomEvent.bind(this)
    );
    window.addEventListener('scroll', debounce(onScroll.bind(this), 10));
    window.addEventListener('resize', debounce(onResize.bind(this), 10));
    //window.addEventListener(
    //  NavEvents.TOGGLE_COLLAPSE,
    //  this.surfacePosition.bind(this)
    //);
  }

  setSandbox(): void {
    if (document.readyState === 'loading') {
      if (!this.listeningDOMReady) {
        whenReady(() => this.setSandbox());
        this.listeningDOMReady = true;
      }

      return;
    }

    if (this.$sandbox) {
      const scrollListener = this.scrollListeners.get(this.$sandbox);
      const resizeListener = this.resizeListeners.get(this.$sandbox);

      scrollListener &&
        this.$sandbox.removeEventListener('scroll', scrollListener);
      resizeListener &&
        this.$sandbox.removeEventListener('resize', resizeListener);

      this.scrollListeners.delete(this.$sandbox);
      this.resizeListeners.delete(this.$sandbox);
    }

    let modalSandbox;

    if (activeRoadModalRegistry.size) {
      for (const modal of activeRoadModalRegistry) {
        modalSandbox = modal;
      }
      /** sandbox should now be the latest opened modal if there are more thant one that are open (even if that is an unlikely scenario) */
      this.$sandbox =
        modalSandbox ?? document.querySelector(this.sandboxSelector);
    } else {
      this.$sandbox = document.querySelector(this.sandboxSelector);
    }

    if (!this.$sandbox) {
      console.warn(
        COMPONENT_TAG,
        this.key,
        "WARNING: sandbox selector doesn't match any DOM element."
      );
    } else if (modalSandbox) {
      const onResize = () => {
        this.surfacePosition();
        this.close();
      };

      const onScroll = () => {
        if (!this.active) return;
        this.surfacePosition();
      };

      const scrollListener = debounce(onScroll.bind(this), 10);
      const resizeListener = debounce(onResize.bind(this), 10);
      modalSandbox.addEventListener('scroll', scrollListener);
      modalSandbox.addEventListener('resize', resizeListener);

      this.scrollListeners.set(modalSandbox, scrollListener);
      this.resizeListeners.set(modalSandbox, resizeListener);
    }
  }

  open(): void {
    if (this.disabled) return;
    if (!this.active) this.active = true;
    this.surfacePosition();
  }

  close(): void {
    this.active = false;
  }

  surfacePosition(): void {
    this.setSandbox();
    const surfaceContainer = this.$internalSurface.getSurfaceContainer();
    if (!(this.$sandbox && this.$anchor && this.$surface && surfaceContainer)) return;

    const dimensions: Record<string, DOMRect> = {
      $sandbox: this.$sandbox.getBoundingClientRect(),
      $anchor: this.$anchor.getBoundingClientRect(),
      $surface: surfaceContainer.getBoundingClientRect(),
    };

    const predicates = new Map<DimentionPredicate, boolean>();

    predicates.set(DimentionPredicate.BOTTOM, true);
    predicates.set(DimentionPredicate.TOP, false);

    if (
      !(window.innerHeight - dimensions.$anchor.bottom >=
      1.1 * dimensions.$surface.height + SPACE_BETWEEN_ANCHOR_AND_MENU)
    ) {
      predicates.set(DimentionPredicate.BOTTOM, false);
      predicates.set(DimentionPredicate.TOP, true);
    }

    predicates.set(DimentionPredicate.LEFT, false);
    predicates.set(DimentionPredicate.RIGHT, true);

    if (
      dimensions.$anchor.x -
        dimensions.$sandbox.x -
        SPACE_BETWEEN_ANCHOR_AND_MENU <
      dimensions.$surface.width
    ) {
      predicates.set(DimentionPredicate.LEFT, true);
      predicates.set(DimentionPredicate.RIGHT, false);
    }

    if (
      predicates.get(DimentionPredicate.TOP) &&
      predicates.get(DimentionPredicate.LEFT)
    ) {
      const positionX = dimensions.$anchor.x;
      const positionY = dimensions.$anchor.y - SPACE_BETWEEN_ANCHOR_AND_MENU;
      this.surfaceDirection = Direction.TOP_LEFT;
      this.surfaceX = positionX;
      this.surfaceY = positionY;
      return;
    }

    if (
      predicates.get(DimentionPredicate.TOP) &&
      predicates.get(DimentionPredicate.RIGHT)
    ) {
      const positionX = dimensions.$anchor.right;
      const positionY = dimensions.$anchor.y - SPACE_BETWEEN_ANCHOR_AND_MENU;

      this.surfaceDirection = Direction.TOP_RIGHT;
      this.surfaceX = positionX;
      this.surfaceY = positionY;

      return;
    }

    if (
      predicates.get(DimentionPredicate.BOTTOM) &&
      predicates.get(DimentionPredicate.LEFT)
    ) {
      const positionX = dimensions.$anchor.x;
      const positionY =
        dimensions.$anchor.bottom + SPACE_BETWEEN_ANCHOR_AND_MENU;

      this.surfaceDirection = Direction.BOTTOM_LEFT;
      this.surfaceX = positionX;
      this.surfaceY = positionY;
      return;
    }

    if (
      predicates.get(DimentionPredicate.BOTTOM) &&
      predicates.get(DimentionPredicate.RIGHT)
    ) {
      const positionX = dimensions.$anchor.right;
      const positionY =
        dimensions.$anchor.bottom + SPACE_BETWEEN_ANCHOR_AND_MENU;
      this.surfaceDirection = Direction.BOTTOM_RIGHT;
      this.surfaceX = positionX;
      this.surfaceY = positionY;

      return;
    }

    return;
  }

  onFloatingSurfaceCustomEvent(evt: Event) {
    if (!(evt instanceof CustomEvent)) return;
    if (evt.detail.key !== this.key) return;
    switch (evt.detail.tag) {
      case FloatingSurfaceEvent.OPENED: {
        this.onAction({tag: MsgTag.OPEN});
        const event = new CustomEvent(FloatingSurfaceEvent.OPENED);
        this.dispatchEvent(event);
        return;
      }

      case FloatingSurfaceEvent.CLOSED: {
        this.onAction({tag: MsgTag.CLOSE});
        const event = new CustomEvent(FloatingSurfaceEvent.CLOSED);
        this.dispatchEvent(event);
        return;
      }
    }
  }

  onAction(msg: Msg): void {
    switch (msg.tag) {
      case MsgTag.OPEN: {
        if (this.disabled) return;
        this.open();
        return;
      }

      case MsgTag.CLOSE: {
        this.close();
        return;
      }

      case MsgTag.UPDATE_DIMENSIONS: {
        this.surfacePosition();
        return;
      }

      case MsgTag.SLOT_CHANGE: {
        const $elements = (msg.evt
          .currentTarget as HTMLSlotElement).assignedElements({flatten: true});
        if (msg.slot === SlotName.ANCHOR) {
          const $anchor = isNonEmptyString(this.anchorSelector)
            ? $elements.find($el => $el.matches(this.anchorSelector))
            : $elements[0];
          if ($anchor) {
            this.$anchor = $anchor as HTMLElement;
          }
        } else if (msg.slot === SlotName.SURFACE) {
          const $surface = isNonEmptyString(this.surfaceSelector)
            ? $elements.find($el => $el.matches(this.surfaceSelector))
            : $elements[0];

          if ($surface) {
            this.$surface = $surface as HTMLElement;
          }
        }

        this.surfacePosition();

        return;
      }

      default: {
        console.warn(
          COMPONENT_TAG,
          this.key,
          'WARNING: invalid msg tag passed to msg handler.'
        );
        return;
      }
    }
  }

  get computedClasses(): Record<string, ClassInfo> {
    return {
      container: {
        'road-dropdown': true,
        'road-dropdown--hidden': this.hidden,
      },
    };
  }

  get computedStyles(): Record<string, StyleInfo> {
    return {
      surface: {
        '--road-floating-surface-min-width': `${Number(
          this.$anchor?.getBoundingClientRect().width
        )}px`,
      },
    };
  }

  render(): TemplateResult {
    return html`
      <div
        id="road-dropdown-${this.key}"
        class=${classMap(this.computedClasses.container)}
      >
        <slot
          @slotchange=${(evt: Event) =>
            this.onAction({
              tag: MsgTag.SLOT_CHANGE,
              slot: SlotName.ANCHOR,
              evt,
            })}
          name="anchor"
        ></slot>
        <road-floating-surface
          id="surface-container"
          key=${this.key}
          @opened=${this.onFloatingSurfaceCustomEvent.bind(this)}
          @closed=${this.onFloatingSurfaceCustomEvent.bind(this)}
          ?active=${this.active && !this.disabled}
          .anchor=${this.$anchor}
          x=${this.surfaceX}
          y=${this.surfaceY}
          direction=${this.surfaceDirection}
          style=${styleMap(this.computedStyles.surface)}
        >
          <slot
            name="surface"
            @slotchange=${(evt: Event) =>
              this.onAction({
                tag: MsgTag.SLOT_CHANGE,
                slot: SlotName.SURFACE,
                evt,
              })}
          ></slot>
        </road-floating-surface>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-dropdown': RoadDropdown;
  }
}
