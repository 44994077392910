import {html} from 'lit';
import {property, customElement} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../../lib/component';

import styles from './style.scss';
import {MovementType} from '../../../../types/movement_category';

@customElement('road-movement-button')
export class RoadMovementButton extends RoadComponent {
  @property({type: Boolean})
  open = false;

  @property()
  intent: 'danger' | 'primary' | 'success' = 'primary';

  static get styles() {
    return [styles];
  }

  get classes(): ClassInfo {
    return {
      'action-button': true,
      'action-button__danger': this.intent === 'danger',
      'action-button__primary': this.intent === 'primary',
      'action-button__success': this.intent === 'success',
    };
  }

  render() {
    const type = this.intent === 'danger'
      ? MovementType.EXPENSE
      : this.intent === 'success'
      ? MovementType.INCOME
      : MovementType.TRANSFER;

    const icon = this.intent === 'danger'
      ? 'remove'
      : this.intent === 'success'
      ? 'add'
      : 'swap_horiz';

    return html`
      <road-icon
        class=${classMap(this.classes)}
        icon=${icon}
        @click=${() => this.open = true}
      ></road-icon>
      <road-movement
        ?open=${this.open}
        movementType=${type}
        @movement-closed=${() => this.open = false}
        @movement-cancelled=${() => this.open = false}
        @movement-created=${() => this.open = false}
      >
      </road-movement>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-movement-button': RoadMovementButton;
  }
}
