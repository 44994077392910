import {SupportedLocales} from '../../types/locale';

/**
 * Global window constants.
 */

/**
 * Base unit for spacing in our styles.
 */
export const SPACING_UNIT = 12;

/**
 * General constants.
 */

export const ASSETS_PATH = '/assets';
export const IMAGES_PATH = '/assets/images';
export const LOCALE_DEFAULT = SupportedLocales.EN;

export enum Alignment {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum RootColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  PRIMARY_LIGHT = 'primary-light',
  SECONDARY_LIGHT = 'secondary-light',
  GREEN_LIGHT = 'green-light',
  YELLOW_LIGHT = 'yellow-light',
  RED_LIGHT = 'red-light',
}

export enum NeutralColor {
  BACKGROUND = 'background',
  HEADLINE = 'headline',
  BODY = 'body',
}

export const rootColorVarMap = new Map([
  [RootColor.PRIMARY, 'var(--road-color-primary)'],
  [RootColor.SECONDARY, 'var(--road-color-primary)'],
  [RootColor.GREEN, 'var(--road-color-success)'],
  [RootColor.YELLOW, 'var(--road-color-warning)'],
  [RootColor.RED, 'var(--road-color-danger)'],
  [RootColor.PRIMARY_LIGHT, 'var(--road-color-primary--light)'],
  [RootColor.SECONDARY_LIGHT, 'var(--road-color-primary--light)'],
  [RootColor.GREEN_LIGHT, 'var(--road-color-success--light)'],
  [RootColor.YELLOW_LIGHT, 'var(--road-color-warning--light)'],
  [RootColor.RED_LIGHT, 'var(--road-color-danger--light)'],
]);

export const rootBaseToLightMap = new Map([
  [RootColor.PRIMARY, RootColor.PRIMARY_LIGHT],
  [RootColor.SECONDARY, RootColor.SECONDARY_LIGHT],
  [RootColor.GREEN, RootColor.GREEN_LIGHT],
  [RootColor.YELLOW, RootColor.YELLOW_LIGHT],
  [RootColor.RED, RootColor.RED_LIGHT],
]);

export enum RequestMethod {
  INFO = 'INFO',
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum KeyCode {
  BACKSPACE = 8,
  TAB = 9,
  ENTER = 13,
  SHIFT = 6,
  CTRL = 17,
  ALT = 18,
  PAUSE = 19,
  CAPS_LOCK = 20,
  ESCAPE = 27,
  SPACE = 32,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  END = 35,
  HOME = 36,
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  RIGHT_ARROW = 39,
  DOWN_ARROW = 40,
  INSERT = 45,
  DELETE = 46,
  KEY_0 = 48,
  KEY_1 = 49,
  KEY_2 = 50,
  KEY_3 = 51,
  KEY_4 = 52,
  KEY_5 = 53,
  KEY_6 = 54,
  KEY_7 = 55,
  KEY_8 = 56,
  KEY_9 = 57,
  KEY_A = 65,
  KEY_B = 66,
  KEY_C = 67,
  KEY_D = 68,
  KEY_E = 69,
  KEY_F = 70,
  KEY_G = 71,
  KEY_H = 72,
  KEY_I = 73,
  KEY_J = 74,
  KEY_K = 75,
  KEY_L = 76,
  KEY_M = 77,
  KEY_N = 78,
  KEY_O = 79,
  KEY_P = 80,
  KEY_Q = 81,
  KEY_R = 82,
  KEY_S = 83,
  KEY_T = 84,
  KEY_U = 85,
  KEY_V = 86,
  KEY_W = 87,
  KEY_X = 88,
  KEY_Y = 89,
  KEY_Z = 90,
  LEFT_META = 91,
  RIGHT_META = 92,
  SELECT = 93,
  NUMPAD_0 = 96,
  NUMPAD_1 = 97,
  NUMPAD_2 = 98,
  NUMPAD_3 = 99,
  NUMPAD_4 = 100,
  NUMPAD_5 = 101,
  NUMPAD_6 = 102,
  NUMPAD_7 = 103,
  NUMPAD_8 = 104,
  NUMPAD_9 = 105,
  MULTIPLY = 106,
  ADD = 107,
  SUBTRACT = 109,
  DECIMAL = 110,
  DIVIDE = 111,
  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,
  NUM_LOCK = 144,
  SCROLL_LOCK = 145,
  SEMICOLON = 186,
  EQUALS = 187,
  COMMA = 188,
  DASH = 189,
  PERIOD = 190,
  FORWARD_SLASH = 191,
  GRAVE_ACCENT = 192,
  OPEN_BRACKET = 219,
  BACK_SLASH = 220,
  CLOSE_BRACKET = 221,
  SINGLE_QUOTE = 222,
}

/**
 * Frontend-wide error code symbols.
 */
export enum RoadErrorCodes {
  /**
   * There was an error parsing the response from the server; usually means that
   * the frotnend expected JSON, but the server returned something else.
   */
  AB_SYNTAX_ERROR = 'AB_01',
}

/**
 * Labels to use per error code when rendering notifications.
 */
export const RoadErrorLabels = new Map([
  [
    RoadErrorCodes.AB_SYNTAX_ERROR,
    'Error: Unable to process response from server.',
  ],
]);

export enum HTMLInputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  DATETIME = 'datetime-local',
  DATE = 'date',
}

/**
 * Enum of base event types.
 */
export enum ElementEventType {
  Change = 'change',
}

/**
 * Common time frames in milliseconds
 */
export const ONE_SECOND_MS = 1_000;
export const FIVE_SECOND_MS = 5 * ONE_SECOND_MS;
export const THIRTY_SECOND_MS = 30 * ONE_SECOND_MS;
export const ONE_MINUTE_MS = 60 * ONE_SECOND_MS;
export const FIVE_MINUTE_MS = 5 * ONE_MINUTE_MS;
export const TEN_MINUTE_MS = 10 * ONE_MINUTE_MS;
