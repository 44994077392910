import {html} from 'lit';
import {property, customElement, queryAll} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import styles from './style.scss';

import {FamilyMember} from '../../../../types/family_member';
import RoadCheckbox from 'src/components/base/checkbox';

export enum FamilyMemberFiltersEvents {
  FAMILY_MEMBERS_CHANGED = 'filters-family-members-changed',
}

@customElement('road-family-member-filters')
export class RoadFamilyMemberFilters extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Array})
  selectedFamilyMembers: FamilyMember[] = [];

  @queryAll('road-checkbox[name="familyMembers"]') checkboxes!: RoadCheckbox[];

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.selectedFamilyMembers = [];
    this.checkboxes.forEach(checkbox => checkbox.deselect());
    this.requestUpdate();
  }

  handleFamilyMembersChange(evt: CustomEvent): void {
    const familyMember = this.context.familyMembers.find((ac: FamilyMember) => ac.id === Number(evt.detail.value));
    if (!familyMember) return;

    if (evt.detail.checked) {
      this.selectedFamilyMembers.push(familyMember)
    } else {
      this.selectedFamilyMembers = this.selectedFamilyMembers.filter((ac: FamilyMember) => ac.id !== familyMember.id);
    }

    this.dispatchFamilyMembersChangedEvent();
  }

  dispatchFamilyMembersChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent(FamilyMemberFiltersEvents.FAMILY_MEMBERS_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: this.selectedFamilyMembers,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-family-member-filters__filter">
          <label for="familyMembers">Family Members</label>
          <div class="road-family-member-filters__checks-container">
            ${repeat(
              this.context.familyMembers,
              (familyMember: FamilyMember) => `family-member-filter-${familyMember.id}`,
              (familyMember: FamilyMember) => {
                return html`<road-checkbox
                  name="familyMembers"
                  value="${familyMember.id}"
                  label="${familyMember.name}"
                  ?checked="${this.selectedFamilyMembers.includes(familyMember)}"
                  @change="${(evt: CustomEvent) => {
                    this.handleFamilyMembersChange(evt);
                  }}"
                >
                  ${familyMember.name}
                </road-checkbox>`
              }
            )}
          </div>
        </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-family-member-filters': RoadFamilyMemberFilters;
  }
}
