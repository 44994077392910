import {html} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';
import {StyleInfo, styleMap} from 'lit/directives/style-map.js';
import {ifDefined} from 'lit/directives/if-defined.js';

import {RoadComponent} from '../../../lib/component';
import {isNonEmptyString} from '../../../utils';
import styles from './style.scss';

import inputStyles from '../../abstract/style.scss';
import {HTMLInputTypes} from '../../../common/constants';
import {FormInputFieldType} from '../form_input_field';

export interface CustomInputEvent {
  $input: HTMLInputElement | HTMLTextAreaElement;
  value: string;
  [k: string]: unknown;
}

export type CustomKeydownEvent = CustomEvent<{
  $input: HTMLInputElement | HTMLTextAreaElement;
  value: string;
  'native.keydown': KeyboardEvent;
  [k: string]: unknown;
}>;

export type CustomKeyupEvent = CustomEvent<{
  $input: HTMLInputElement | HTMLTextAreaElement;
  value: string;
  'native.keyup': KeyboardEvent;
  [k: string]: unknown;
}>;

export type RoadInputChangeEvent = CustomEvent<{
  $input: HTMLInputElement | HTMLTextAreaElement;
  value: string;
  [k: string]: unknown;
}>;

export const COMPONENT_TAG = 'road-input';

export enum Variant {
  OUTLINED = 'outlined',
  UNIBORDER = 'uniborder',
  BORDERLESS = 'borderless',
  BORDERLESS_NO_BG = 'borderless-no-bg',
  UNDERLINE = 'underline',
  INLINE = 'inline',
  SUBHEADING = 'subheading',
  HEADING = 'heading',
  NODE_TITLE = 'node-title',
}

export enum Intent {
  BASE = 'base',
  SUCCESS = 'success',
  DANGER = 'danger',
}

export const DEFAULT_TYPE = 'text';
export const DEFAULT_MAX_LENGTH = Number.MAX_SAFE_INTEGER;
export const DEFAULT_MIN_LENGTH = 0;

type Msg =
  | {tag: 'focus'; evt: FocusEvent}
  | {tag: 'focusin'; evt: FocusEvent}
  | {tag: 'focusout'; evt: FocusEvent}
  | {tag: 'blur'; evt: FocusEvent}
  | {tag: 'input'; evt: InputEvent}
  | {tag: 'change'; evt: Event}
  | {tag: 'keyup'; evt: KeyboardEvent}
  | {tag: 'keydown'; evt: KeyboardEvent};

/**
 * RoadInput
 */
@customElement(COMPONENT_TAG)
export default class RoadInput extends RoadComponent {
  @query('input')
  $input!: HTMLInputElement | HTMLTextAreaElement;

  @property({reflect: true})
  key = `${new Date().getTime()}-${Math.random()}`;

  @property()
  variant: Variant = Variant.OUTLINED;

  @property()
  intent: Intent = Intent.BASE;

  @property({type: String})
  name = '';

  @property({type: String})
  fieldLabel = '';

  @property({type: String})
  width = '';

  @property({type: String, reflect: true})
  value = '';

  @property()
  step = '';

  @property({type: Boolean})
  hasIcon = false;

  @property({type: Boolean})
  autofocus = false;

  @property({type: String})
  type: HTMLInputElement['type'] = DEFAULT_TYPE;

  @property({type: String})
  pattern = '';

  @property({type: String})
  placeholder = '';

  @property({type: Number})
  maxlength: number = DEFAULT_MAX_LENGTH;

  @property({type: Number})
  minlength: number = DEFAULT_MIN_LENGTH;

  @property({type: Number})
  minValue = Number.MIN_SAFE_INTEGER;

  @property({type: Number})
  maxValue = Number.MAX_SAFE_INTEGER;

  @property({type: String})
  minDate?: string;

  @property({type: String})
  maxDate?: string;

  @property()
  initialWidth = '';

  @property({type: Boolean, reflect: true})
  required = false;

  @property({type: Boolean, reflect: true})
  disabled = false;

  @property({type: Boolean, reflect: true})
  readonly = false;

  @state()
  protected _rendered = false;

  @state()
  private inlineWidth = '0px';

  /**
   * Styles
   */
  static get styles() {
    return [styles, inputStyles];
  }

  get computedClasses(): Record<string, ClassInfo> {
    return {
      container: {
        'road-input-container': true,
        'road-input-container--outlined': this.variant === Variant.OUTLINED,
        'road-input-container--uniborder': this.variant === Variant.UNIBORDER,
        'road-input-container--borderless': this.variant === Variant.BORDERLESS,
        'road-input-container--underline': this.variant === Variant.UNDERLINE,
        'road-input-container--inline': this.variant === Variant.INLINE,
        'road-input-container--heading': this.variant === Variant.HEADING,
        'road-input-container--subheading': this.variant === Variant.SUBHEADING,
        'road-input-container--borderless-no-bg':
          this.variant === Variant.BORDERLESS_NO_BG && !this.disabled,
        'road-input-container--borderless-no-bg--disabled':
          this.variant === Variant.BORDERLESS_NO_BG && this.disabled,
        'road-input-container--success': this.intent === Intent.SUCCESS,
        'road-input-container--danger': this.intent === Intent.DANGER,
      },
      input: {
        'road-input': true,
        'road-input--icon': this.hasIcon,
        'road-input--outlined': this.variant === Variant.OUTLINED,
        'road-input--uniborder': this.variant === Variant.UNIBORDER,
        'road-input--borderless': this.variant === Variant.BORDERLESS,
        'road-input--underline': this.variant === Variant.UNDERLINE,
        'road-input--inline': this.variant === Variant.INLINE,
        'road-input--heading': this.variant === Variant.HEADING,
        'road-input--subheading': this.variant === Variant.SUBHEADING,
        'road-input--borderless-no-bg':
          this.variant === Variant.BORDERLESS_NO_BG,
        'road-input--success': this.intent === Intent.SUCCESS,
        'road-input--danger': this.intent === Intent.DANGER,
        'road-input--disabled': this.disabled,
        'road-input--node-title': this.variant === Variant.NODE_TITLE,
      },
    };
  }

  get inlineVariants(): Set<Variant> {
    return new Set([Variant.INLINE, Variant.UNDERLINE, Variant.HEADING, Variant.SUBHEADING]);
  }

  get computedStyles(): Record<string, StyleInfo> {
    return {
      input: {
        width: this.inlineVariants.has(this.variant)
          ? `${this.inlineWidth}`
          : 'var(--road-input-native-width, var(--road-input-width))',
      },
    };
  }

  rendered() {
    if (this.inlineVariants.has(this.variant)) {
      const markAsRendered = () => {
        this._rendered = true;
        this.setInlineWidth();
      };

      if (document.readyState === 'complete') {
        markAsRendered();
      }

      document.addEventListener('readystatechange', (e: Event) => {
        if ((e.target as Document).readyState === 'complete') {
          this._rendered = true;
          markAsRendered();
          this.requestUpdate();
        }
      });
    } else {
      this._rendered = true;
    }
  }

  setInlineWidth() {
    const tmp = document.createElement('span') as HTMLElement;
    tmp.className = 'inline-input-clone';

    if (this.variant === Variant.UNDERLINE) {
      tmp.className = 'underline-input-clone';
    }

    if (this.variant === Variant.HEADING) {
      tmp.className = 'heading-input-clone';
    }

    if (this.variant === Variant.SUBHEADING) {
      tmp.className = 'subheading-input-clone';
    }

    const value = (this.value || this.placeholder)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    tmp.innerText = value;

    document.body.appendChild(tmp);
    const width = tmp.getBoundingClientRect().width;
    this.inlineWidth =
      !this._rendered && this.initialWidth ? this.initialWidth : `${width}px`;
    document.body.removeChild(tmp);
  }

  getNativeValidation(): {
    valid: boolean;
    message: string;
    details?: ValidityState;
  } {
    if (!this.$input) {
      return {
        valid: false,
        message: '',
      };
    }

    return {
      valid: this.$input.checkValidity(),
      message: this.$input.validationMessage,
      details: this.$input.validity,
    };
  }

  reset(text?: string) {
    if (!this.$input) return;
    const _text = isNonEmptyString(text) ? text.trim() : '';
    this.$input.value = _text;
    this.$input.setAttribute('value', _text);
  }

  onAction(msg: Msg) {
    this.dispatchEvent(
      new CustomEvent<CustomInputEvent>(msg.tag, {
        detail: {
          [`native.${msg.tag}`]: msg.evt,
          $input: this.$input,
          value: this.$input.value,
        },
      })
    );

    window.dispatchEvent(
      new CustomEvent(COMPONENT_TAG, {
        detail: {
          key: this.key,
          [`native.${msg.tag}`]: msg.evt,
          $input: this.$input,
          value: this.$input.value,
        },
      })
    );

    this.value = this.$input.value;
  }

  updated(changedProperties: Map<string, unknown>) {
    changedProperties.forEach((_, propName) => {
      if (propName !== 'autofocus') return;
      if (this.$input && this.autofocus) {
        this.$input.focus();
      }
    });

    if (
      this.inlineVariants.has(this.variant) &&
      (changedProperties.has('value') || changedProperties.has('placeholder'))
    ) {
      this.setInlineWidth();
    }
  }

  getMin() {
    if (this.type === FormInputFieldType.DATE) return this.minDate ?? '';
    if (this.type === FormInputFieldType.NUMBER) return this.minValue ?? '';
    return '';
  }

  getMax() {
    if (this.type === FormInputFieldType.DATE) return this.maxDate ?? '';
    if (this.type === FormInputFieldType.NUMBER) return this.maxValue ?? '';
    return '';
  }

  inputMarkup() {
    if (
      [HTMLInputTypes.DATE, HTMLInputTypes.DATETIME].includes(
        this.type as HTMLInputTypes
      ) &&
      this.value &&
      typeof this.value === 'string' &&
      this.$input
    ) {
      if (!(this.$input as HTMLInputElement).valueAsNumber) {
        (this.$input as HTMLInputElement).valueAsDate = new Date(this.value);
      }
    }

    return html`
      <input
        part="field"
        style=${styleMap(this.computedStyles.input)}
        id="road-input-${this.key}"
        class=${classMap(this.computedClasses.input)}
        .type=${this.type}
        .name=${this.name}
        .step=${this.step}
        min=${this.getMin()}
        max=${this.getMax()}
        .pattern=${this.pattern}
        .placeholder=${this.placeholder}
        ?required=${this.required}
        ?disabled=${this.disabled}
        ?readonly=${this.readonly}
        maxlength=${ifDefined(this.maxlength)}
        minlength=${ifDefined(this.minlength)}
        .value=${this.value}
        ?autofocus=${this.autofocus}
        @focus=${(evt: FocusEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'focus', evt});
        }}
        @focusin=${(evt: FocusEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'focusin', evt});
        }}
        @focusout=${(evt: FocusEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'focusout', evt});
        }}
        @blur=${(evt: FocusEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'blur', evt});
        }}
        @input=${(evt: InputEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'input', evt});
        }}
        @change=${(evt: InputEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'change', evt});
        }}
        @keyup=${(evt: KeyboardEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'keyup', evt});
        }}
        @keydown=${(evt: KeyboardEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'keydown', evt});
        }}
      />
      ${this.fieldLabel !== ''
        ? html`<label class="floating-label"><span class="floating-label">${this.fieldLabel}</span></label>`
        : ''}
    `;
  }

  /**
    Renderer
   * @override
   */
  render() {
    if (this.hasIcon) {
      return html`
        <div
          style="width: ${isNonEmptyString(this.width)
            ? this.width
            : 'var(--road-input-width, auto)'}"
          class=${classMap(this.computedClasses.container)}
        >
          <div class="road-input__icon">
            <slot name="icon"></slot>
          </div>
          ${this.inputMarkup()}
        </div>
      `;
    }
    return this.inputMarkup();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-input': RoadInput;
  }
}
