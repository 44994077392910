import {html, TemplateResult, CSSResultArray} from 'lit';
import {customElement, property, query, queryAll} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';
import {styleMap} from 'lit/directives/style-map.js';
import {classMap} from 'lit/directives/class-map.js';

import RoadListItem, {
  COMPONENT_TAG as ROAD_LIST_ITEM_COMPONENT_TAG,
} from '../list_item';
import RoadDropdown, {MsgTag as DropdownMsgTag} from '../dropdown';
import RoadInput, {Variant as RoadInputVariant} from '../input';
import RoadList from '../list';
import {debounce} from '../../../utils/debounce';
import {fuzzyMatch} from '../../../utils/strings';
import {isString, isNonEmptyString, isBool, isPlainObject} from '../../../utils';
import {RoadComponent} from '../../../lib/component';

import styles from './style.scss';
import inputStyles from '../../abstract/style.scss';

export interface Option {
  value: string;
  label: string;
  selected: boolean;
  disabled?: boolean;
  hidden?: boolean;
  tags?: string[];
  document?: Record<string, unknown> | {[k: string]: any};
}

export interface AsyncQuery {
  requests: number;
  more: boolean;
  results: Set<Option['value']>;
}

export interface AsyncFetchDetail {
  options: Option[];
  selected: Option[];
  search: string;
  state: AsyncQuery;
}

export interface MultiselectChangeDetail {
  open: boolean;
  selected: Option[];
  key: string;
}

export const COMPONENT_TAG = 'road-multi-select';
const EMPTY_SEARCH_OPTIONS_MESSAGE = 'There are no results to display.';
const NO_SEARCH_RESULTS_MESSAGE = 'There are no results matching your query.';
const DEFAULT_MAX_OPTIONS = 1000;
const DEFAULT_MAX_SELECTED_OPTIONS = DEFAULT_MAX_OPTIONS;
const DEFAULT_DELAY_INTERVAL = 10;
const ASYNC_SEARCH_DELAY_INTERVAL = 300;
const SEARCH_INPUT_PROCESSING_DELAY = 100;
const ASYNC_FETCH_DELAY_INTERVAL = 100;
const EMPTY_SEARCH_TEXT_REF = '∅';
enum SelectionAction {
  ADD = 'add',
  REMOVE = 'remove',
}
const SELECTION_ACTION_TUPLE = Object.freeze([
  SelectionAction.ADD,
  SelectionAction.REMOVE,
]);

@customElement(COMPONENT_TAG)
export default class RoadMultiSelect extends RoadComponent {
  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @property({type: Boolean, reflect: true})
  open = false;

  @property({type: Boolean, reflect: true})
  disabled = false;

  @property({type: Boolean, reflect: true})
  taggable = false;

  @property({type: Boolean, reflect: true})
  searchable = false;

  @property({type: Boolean, reflect: true})
  isTyping = false;

  @property({type: Boolean, reflect: true})
  isSearching = false;

  @property({type: Boolean, reflect: true})
  isFetching = false;

  @property({type: Number, reflect: true})
  searchMinLength = 0;

  @property({type: String})
  fieldLabel = '';

  @property({type: Boolean, reflect: true})
  async = false;

  @property({attribute: 'max-selected', type: Number})
  maxSelected = DEFAULT_MAX_SELECTED_OPTIONS;

  @property({attribute: 'max-options', type: Number})
  maxOptions: number = DEFAULT_MAX_OPTIONS;

  @property()
  placeholder = 'Select';

  @property()
  placeholderIcon = '';

  @property({type: Object, attribute: false})
  asyncState: Record<string | symbol, AsyncQuery> = {};

  @property({type: String, attribute: false})
  searchText = '';

  @property({type: Boolean, attribute: false})
  searchFocus = false;

  @property({attribute: 'has-controlled-anchor', type: Boolean})
  hasControlledAnchor = false;

  @property({type: Object, attribute: false, reflect: true})
  options: Record<string, Option> = {};

  @property({type: Array, attribute: false})
  selected: Option[] = [];

  @property({type: Object, attribute: false})
  searchTimeoutRef: {ref: number | null; input: string} = {
    ref: null,
    input: '',
  };

  @property({attribute: 'unique-empty-state-message', type: String})
  uniqueEmptyMessage = EMPTY_SEARCH_OPTIONS_MESSAGE;

  @property({attribute: 'unique-no-results-message', type: String})
  uniqueNoResultsMessage = NO_SEARCH_RESULTS_MESSAGE;

  autoAsyncLoadingIntervalRef: number | null = null;

  customOptionMarkup:
    | ((option: Option) => TemplateResult | TemplateResult[])
    | null = null;

  asyncOptionMarkup:
    | ((option: Option) => TemplateResult | TemplateResult[])
    | null = null;

  @query('road-input')
  $searchInput!: RoadInput;

  @query('road-dropdown')
  $dropdown!: RoadDropdown;

  @query('#road-multi-select-dropdown-anchor')
  $dropdownAnchor!: HTMLElement;

  @query('#road-multi-select-dropdown-surface')
  $dropdownSurface!: HTMLElement;

  @query('#road-multi-select-options')
  $optionNodesContainer!: HTMLElement;

  @queryAll('road-list')
  $optionNodesListContainers!: NodeListOf<HTMLElement>;

  optionNodesContainerScrollListenerWithDebounce = debounce(
    this.optionNodesContainerScrollListener.bind(this),
    ASYNC_FETCH_DELAY_INTERVAL
  );

  private debouncedHandleKeydown = debounce(
    this.handleKeydown.bind(this),
    ASYNC_SEARCH_DELAY_INTERVAL
  );

  static get styles(): CSSResultArray {
    return [styles, inputStyles];
  }

  get isSingleSelect(): boolean {
    return this.maxSelected === 1;
  }

  get hasFetched(): boolean {
    return this.async && this.getAsyncState(EMPTY_SEARCH_TEXT_REF).requests > 0;
  }

  get optionsArray() {
    if (!isPlainObject(this.options)) return [];
    const selected = new Set(this.selectedValues);
    const options = [] as Option[];
    for (const value in this.options) {
      const option = this.options[value];
      if (!this.optionRecordValidation(option)) {
        delete this.options[value];
        continue;
      }
      if (selected.has(option.value)) option.selected = true;
      options.push(option);
    }

    return options;
  }

  get $optionNodes() {
    const $options = new Map<string, RoadListItem>();
    const $nodeLists = Array.from(this.$optionNodesListContainers) as RoadList[];
    for (const $list of $nodeLists) {
      for (const $item of $list.getListItems()) {
        $options.set($item.value, $item);
        if (!this.options[$item.value]) {
          this.options[$item.value] = this.buildOptionRecordFromNode($item);
          continue;
        }
        const option = this.options[$item.value];
        if (option.selected && !$item.selected) $item.selected = true;
      }
    }
    return $options;
  }

  get selectedValues() {
    return this.selected.map(s => s.value);
  }

  get anchorIcon() {
    return this.open && !this.disabled
      ? 'keyboard_arrow_up'
      : 'keyboard_arrow_down';
  }

  get customOptions() {
    return this.optionsArray.filter(option => option.tags?.includes('custom'));
  }

  get slottedOptions() {
    return this.optionsArray.filter(option => option.tags?.includes('slotted'));
  }

  get asyncOptions() {
    return this.optionsArray.filter(option => option.tags?.includes('async'));
  }

  get hiddenOptions() {
    return this.optionsArray.filter(option => option.hidden);
  }

  get areAllOptionsHidden() {
    const totalOptionsCount = this.optionsArray.length;
    return this.hiddenOptions.length === totalOptionsCount;
  }

  get hasMaxAllowedSelectedOptions() {
    return (
      this.maxSelected !== DEFAULT_MAX_SELECTED_OPTIONS &&
      this.selected.length === this.maxSelected
    );
  }

  get isSearchTextTooSmall() {
    if (!isNonEmptyString(this.searchText)) return true;
    return this.searchText.trim().length < this.searchMinLength;
  }

  get isOptionNodesContainerScrollableY() {
    if (!this.$optionNodesContainer) return false;
    return (
      this.$optionNodesContainer.scrollHeight >
      this.$optionNodesContainer.clientHeight
    );
  }

  handleKeydown(e: CustomEvent) {
    const trimmedSearchInput = this.$searchInput.value.trim();
    const originalEvent = e.detail['native.keydown'];

    if (this.isTyping || !trimmedSearchInput.length) return;

    if (this.searchable && this.taggable && originalEvent.key === 'Enter') {
      const newCustomOption = this.createCustomOption(trimmedSearchInput, {
        selected: !this.hasMaxAllowedSelectedOptions,
        disabled: false,
        hidden: false,
      });

      this.options[trimmedSearchInput]
        ? this.toggleOptionSelected(trimmedSearchInput)
        : this.addCustomOptions([newCustomOption]);
      this.$searchInput.reset();
      this.searchText = '';
      if (this.isSearching) {
        this.dispatchEvent(new CustomEvent('cancel-asyncfetch'));
        this.isSearching = false;
      }
      this.resetOptionNodesVisibility();
    }
  }

  optionRecordValidation(option: Option) {
    if (!isPlainObject(option)) return false;
    if (!isNonEmptyString(option.label)) return false;
    if (!isNonEmptyString(option.value)) return false;

    return true;
  }

  toggleOptionSelected(optionKey: string) {
    const option = this.options[optionKey];

    this.updateSelectedOptions(
      SELECTION_ACTION_TUPLE[+option.selected],
      option
    );
  }

  maxSelectedGuard() {
    if (this.selected.length > this.maxSelected) {
      this.selected = this.selected.slice(this.maxSelected);
    }

    if (this.hasMaxAllowedSelectedOptions) {
      for (const optionValue in this.options) {
        const $option = this.$optionNodes.get(optionValue) as RoadListItem;
        if (!$option) continue;
        const option = this.options[optionValue];

        if (!$option.selected) {
          $option.disabled = true;
          option.disabled = true;
        }

        if (
          this.isSingleSelect &&
          $option.selected &&
          this.selected[0].value !== $option.value
        ) {
          $option.selected = false;
          option.selected = false;
        }
      }
      return;
    }

    if (this.selected.length < this.maxSelected) {
      for (const optionValue in this.options) {
        const $option = this.$optionNodes.get(optionValue) as RoadListItem;
        if (!$option) continue;
        const option = this.options[optionValue];

        if (!$option.selected) {
          $option.disabled = false;
          option.disabled = false;
        }
      }
      return;
    }
  }

  extractOptionText(option: Option) {
    const text = option.label || option.value;
    if (!isNonEmptyString(text)) return '';
    return text.trim();
  }

  extractOptionTextFromNode($node: RoadListItem) {
    const text = $node.label || $node.value;
    if (!isNonEmptyString(text)) return '';
    return text.trim();
  }

  updateSelectedOptions(action: string, option: Option) {
    if (!isPlainObject(option)) return;
    if (!isPlainObject(this.options[option.value])) return;
    if (option.disabled) return;

    if (action === SelectionAction.ADD) {
      if (
        this.maxSelected !== DEFAULT_MAX_SELECTED_OPTIONS &&
        this.selected.length === this.maxSelected
      )
        return;

      this.options[option.value].selected = true;

      this.selected = this.selected
        .filter(s => s.value !== option.value)
        .concat(option);

      return;
    }

    if (action === SelectionAction.REMOVE) {
      this.options[option.value].selected = false;
      const $option = this.$optionNodes.get(option.value) as RoadListItem;
      if (!$option) return;
      $option.selected = false;
      this.selected = this.selected.filter(s => s.value !== option.value);
      return;
    }
  }

  optionNodesContainerScrollListener() {
    const {scrollHeight, scrollTop, clientHeight} = this.$optionNodesContainer;
    const hasReachedTheEndOfScroll =
      clientHeight !== scrollHeight && scrollTop + clientHeight >= scrollHeight;
    if (hasReachedTheEndOfScroll) {
      this.doAsyncFetch(this.searchText);
    }
  }

  doAsyncFetch(input?: string, isSearching = false) {
    const search = isString(input)
      ? input.trim().toLowerCase()
      : this.searchText.trim().toLowerCase();

    const statekey = this.getAsyncStateKey(search);
    if (!isNonEmptyString(statekey)) return;
    const asyncState = this.getAsyncState(statekey);

    if (!asyncState.more) return;

    if (this.isFetching || this.isSearching) {
      this.dispatchEvent(new CustomEvent('cancel-asyncfetch'));
    }

    if (isSearching) {
      this.isSearching = true;
    } else {
      this.isFetching = true;
    }

    this.dispatchEvent(
      new CustomEvent('asyncfetch', {
        detail: {
          options: this.optionsArray,
          selected: this.selected,
          search,
          state: asyncState,
        },
      })
    );
  }

  applySearchFilter() {
    const options = this.optionsArray;
    const $optionNodes = this.$optionNodes;
    const needle = this.searchText.toLowerCase();

    for (const option of options) {
      if (!isPlainObject(option)) continue;

      const optionText = this.extractOptionText(option);
      const ismatch =
        isNonEmptyString(optionText) &&
        fuzzyMatch(needle, optionText.toLowerCase());
      option.hidden = !ismatch;
      if (!option.tags?.includes('slotted')) continue;

      const $option = $optionNodes.get(option.value);
      if ($option) $option.hidden = !ismatch;
    }
  }

  applyFilters() {
    this.applySearchFilter();
    return;
  }

  resetOptionNodesVisibility() {
    const $optionNodes = this.$optionNodes;
    for (const option of this.hiddenOptions) {
      option.hidden = false;
      if (option.tags?.includes('slotted')) {
        const $option = $optionNodes.get(option.value);
        if ($option) $option.hidden = false;
      }
    }
  }

  handleSearchInput(input: string) {
    if (this.isTyping) return;
    if (this.isFetching) {
      this.dispatchEvent(new CustomEvent('cancel-asyncfetch'));
      this.isFetching = false;
    }

    this.searchText = input;

    if (this.async && this.optionsArray.length < this.maxOptions) {
      this.doAsyncFetch(input, true);
      return;
    }
    this.applyFilters();
    this.isSearching = false;
    return;
  }

  buildOptionRecordFromNode($node: RoadListItem) {
    const text = this.extractOptionTextFromNode($node);
    return {
      value: $node.value,
      label: text,
      disabled: $node.disabled,
      selected: $node.selected,
      hidden: $node.hidden,
      tags: JSON.parse($node.dataset.tags || '[]') as string[],
      document: Object.assign(
        {},
        $node.dataset,
        isPlainObject($node.document) ? $node.document : {}
      ),
    };
  }

  registerSlottedOptionNodes(evt: Event) {
    const $slottedNodes = (evt.currentTarget as HTMLSlotElement)
      .assignedNodes({
        flatten: true,
      })
      .filter(
        $node => $node.nodeName === ROAD_LIST_ITEM_COMPONENT_TAG.toUpperCase()
      ) as RoadListItem[];

    for (const $node of $slottedNodes) {
      if (this.options[$node.value]) continue;

      $node.addEventListener('selected', (evt: Event) => {
        if (!(evt instanceof CustomEvent)) return;
        if (
          !isNonEmptyString(evt.detail.value) ||
          evt.detail.value !== $node.value
        )
          return;

        this.updateSelectedOptions(
          SelectionAction.ADD,
          this.options[$node.value]
        );
        return;
      });

      $node.addEventListener('deselected', (evt: Event) => {
        if (!(evt instanceof CustomEvent)) return;
        if (
          !isNonEmptyString(evt.detail.value) ||
          evt.detail.value !== $node.value
        )
          return;
        this.updateSelectedOptions(SelectionAction.REMOVE, this.options[$node.value]);
        return;
      });

      this.options[$node.value] = this.buildOptionRecordFromNode($node);
      if (!this.options[$node.value].tags?.includes('slotted')) {
        this.options[$node.value].tags?.push('slotted');
      }

      if ($node.selected && !$node.disabled) {
        this.selected = this.selected.concat(this.options[$node.value]);
      }
    }
  }

  createCustomOption(
    text: string,
    flags: {selected: boolean; disabled: boolean; hidden: boolean},
    tags?: string[],
    document?: Record<string, unknown>
  ) {
    const _text = isNonEmptyString(text) ? text.trim() : '';
    const _tags = Array.isArray(tags)
      ? new Set(tags.filter(isNonEmptyString))
      : new Set<string>();

    _tags.add('custom');

    const _document = isPlainObject(document) ? document : document;

    return {
      value: _text,
      label: _text,
      ...flags,
      tags: Array.from(_tags.keys()),
      document: _document,
    } as Option;
  }

  addCustomOptions(options: Option[]) {
    for (let idx = 0, len = options.length; idx < len; idx++) {
      const option = options[idx];
      if (this.options[option.value]) continue;
      this.options[option.value] = option;
      if (option.selected) {
        this.updateSelectedOptions(SelectionAction.ADD, option);
      }
    }
  }

  getAsyncStateKey(search: string) {
    if (!isNonEmptyString(search)) return EMPTY_SEARCH_TEXT_REF;
    return btoa(search.toLowerCase());
  }

  decodeAsyncStateKey(statekey: string) {
    if (!isNonEmptyString(statekey)) return '';
    if (statekey === EMPTY_SEARCH_TEXT_REF) return '';
    return atob(statekey);
  }

  addAsyncState(key: string) {
    if (!isNonEmptyString(key)) return;
    if (isPlainObject(this.asyncState[key])) return;
    this.asyncState[key] = {
      more: true,
      requests: 0,
      results: new Set<Option['value']>(),
    };
  }

  getAsyncState(key: string) {
    if (!isPlainObject(this.asyncState[key])) {
      this.addAsyncState(key);
      return this.asyncState[key];
    }
    return this.asyncState[key];
  }

  addAsyncOptions(options: Option[], meta: {search: string; more: boolean}) {
    if (!this.async || this.isTyping) return;
    if (!this.isFetching && !this.isSearching) return;
    if (!isPlainObject(meta) || !isBool(meta.more)) return;
    const statekey = this.getAsyncStateKey(meta.search);
    if (!isNonEmptyString(statekey)) return;
    const asyncState = this.getAsyncState(statekey);
    asyncState.requests += 1;
    asyncState.more = meta.more;

    for (let idx = 0, len = options.length; idx < len; idx++) {
      const option = options[idx];
      asyncState.results.add(option.value);
      if (this.options[option.value]) continue;
      option.disabled = this.hasMaxAllowedSelectedOptions;
      option.hidden = this.isTyping || !this.isSearchTextTooSmall;
      this.options[option.value] = option;
      if (option.selected)
        this.updateSelectedOptions(SelectionAction.ADD, option);
    }

    this.applyFilters();

    if (this.isFetching && !this.isSearching) {
      this.isFetching = false;
    } else if (!this.isFetching && this.isSearching) {
      this.$optionNodesContainer.scrollTop = 0;
      this.isSearching = false;
    }
    return;
  }

  deselectAllOptions() {
    this.optionsArray.forEach(option => this.updateSelectedOptions(SelectionAction.REMOVE, option));
  }

  deselectOptions(options: Option[]) {
    for (let idx = 0, len = options.length; idx < len; idx++) {
      const option = options[idx];
      this.updateSelectedOptions(SelectionAction.REMOVE, option);
    }
  }

  deleteOptions(options: Option[]) {
    for (let idx = 0, len = options.length; idx < len; idx++) {
      const option = options[idx];
      this.updateSelectedOptions(SelectionAction.REMOVE, option);
      this.options[option.value] = {} as Option;
    }
  }

  defaultCustomOptionMarkup(option: Option) {
    if (this.customOptionMarkup instanceof Function) {
      return this.customOptionMarkup(option);
    }

    const text = this.extractOptionText(option);
    return html`
      <road-list-item
        value=${option.value}
        label=${text}
        ?selected=${Boolean(option.selected)}
        ?disabled=${Boolean(option.disabled)}
        @selected=${this.onOptionSelected.bind(this)}
        @deselected=${this.onOptionDeselected.bind(this)}
        ?hidden=${Boolean(option.hidden)}
        data-tags=${JSON.stringify(option.tags || [])}
        document=${JSON.stringify(
          isPlainObject(option.document) ? option.document : '{}'
        )}
      >
        <div
          style="display: flex; align-items: center; justify-content: space-between; width: 100%;"
        >
          <div>${text}</div>
          <div
            @click=${(e: MouseEvent) => {
              e.stopImmediatePropagation();
              this.deleteOptions([option]);
            }}
          >
            <mwc-icon style="--mdc-icon-size: 16px;" icon="close"
              >close</mwc-icon
            >
          </div>
        </div>
      </road-list-item>
    `;
  }

  defaultAsyncOptionMarkup(option: Option) {
    if (this.asyncOptionMarkup instanceof Function) {
      return this.asyncOptionMarkup(option);
    }

    const text = this.extractOptionText(option);
    return html`
      <road-list-item
        value=${option.value}
        label=${text}
        ?selected=${Boolean(option.selected)}
        ?disabled=${Boolean(option.disabled)}
        @selected=${this.onOptionSelected.bind(this)}
        @deselected=${this.onOptionDeselected.bind(this)}
        ?hidden=${Boolean(option.hidden)}
        data-tags=${JSON.stringify(option.tags || [])}
        document=${JSON.stringify(
          isPlainObject(option.document) ? option.document : '{}'
        )}
      >
        ${text}
      </road-list-item>
    `;
  }

  onOptionSelected(evt: Event) {
    if (!(evt instanceof CustomEvent)) return;
    if (!isNonEmptyString(evt.detail.value)) return;
    this.updateSelectedOptions(
      SelectionAction.ADD,
      this.options[evt.detail.value]
    );
    return;
  }

  onOptionDeselected(evt: Event) {
    if (!(evt instanceof CustomEvent)) return;
    if (!isNonEmptyString(evt.detail.value)) return;
    this.updateSelectedOptions(SelectionAction.REMOVE, this.options[evt.detail.value]);
    return;
  }

  clearOptions(): void {
    for (const key in this.options) {
      this.updateSelectedOptions(SelectionAction.REMOVE, this.options[key]);
    }
  }

  searchInputMarkup() {
    const handleSearchInput = debounce(
      this.handleSearchInput.bind(this),
      ASYNC_SEARCH_DELAY_INTERVAL
    );

    const oninput = (e: Event) => {
      if (!(e instanceof CustomEvent)) return;
      this.isTyping = true;
      this.searchTimeoutRef.input = e.detail.value.trim().toLowerCase();
      if (this.searchTimeoutRef.ref)
        clearTimeout(this.searchTimeoutRef.ref as number);
      this.searchTimeoutRef.ref = window.setTimeout(() => {
        this.isTyping = false;
        handleSearchInput(this.searchTimeoutRef.input);
        if (!this.searchTimeoutRef.ref) return;
        clearTimeout(this.searchTimeoutRef.ref as number);
        this.searchTimeoutRef.ref = null;
        this.searchTimeoutRef.input = '';
      }, SEARCH_INPUT_PROCESSING_DELAY);
    };

    const inputHandler = debounce(oninput.bind(this), DEFAULT_DELAY_INTERVAL);

    if (!this.searchable) return html``;
    return html`
      <road-input
        hasicon
        key=${this.key}
        type="search"
        @input=${inputHandler}
        @keydown=${(e: CustomEvent) => {
          this.debouncedHandleKeydown(e);
        }}
        minlength=${this.searchMinLength}
        variant=${RoadInputVariant.BORDERLESS}
        ?autofocus=${this.searchFocus}
        ?disabled=${!this.searchable}
        placeholder=${this.taggable ? 'Search or add a value' : 'Search'}
      >
        <div slot="icon">
          <mwc-icon icon="search">search</mwc-icon>
        </div>
      </road-input>
    `;
  }

  generatedOptionsMarkup(tag: 'custom' | 'async') {
    switch (tag) {
      case 'custom': {
        return html`
          ${repeat(
            this.customOptions,
            (_, idx: number) => `repeatkey:${COMPONENT_TAG}-${this.key}-${idx}`,
            this.defaultCustomOptionMarkup.bind(this)
          )}
        `;
      }

      case 'async': {
        return html`
          ${repeat(
            this.asyncOptions,
            (_, idx: number) => `repeatkey:${COMPONENT_TAG}-${this.key}-${idx}`,
            this.defaultAsyncOptionMarkup.bind(this)
          )}
        `;
      }
    }
  }

  dropdownSurfaceMarkup() {
    const classes = classMap({
      'road-multi-select__surface': true,
      'road-multi-select__surface--has-search': this.searchable,
    });

    const hasCustomOptions =
      this.searchable && this.taggable && Boolean(this.customOptions.length);

    const customOptionsDividerClasses = classMap({
      'road-multi-select__surface__sep': true,
      'road-multi-select__surface__sep--active':
        hasCustomOptions &&
        !this.areAllOptionsHidden &&
        !this.customOptions.every(o => o.hidden),
    });

    const asyncOptionsListStyles = styleMap({
      display: this.async ? 'grid' : 'none',
    });

    const asyncSearchOptionsLoadingIndicatorClasses = classMap({
      'road-multi-select__surface__search-loading-screen':
        this.async && this.open && this.searchable,
      'road-multi-select__surface__search-loading-screen--active':
        this.async &&
        this.open &&
        this.searchable &&
        this.isSearching &&
        !this.isFetching,
    });

    const asyncScrollOptionsLoadingIndicatorClasses = classMap({
      'road-multi-select__surface__scroll-loading-screen':
        this.async && this.open,
      'road-multi-select__surface__scroll-loading-screen--active':
        this.async &&
        this.open &&
        !(this.isSearching || this.isTyping) &&
        this.isFetching,
      'road-multi-select__surface__scroll-loading-screen--disabled': !this.async,
    });

    return html`
      <div
        slot="surface"
        class=${classes}
        id="road-multi-select-dropdown-surface"
        style="${styleMap({minWidth: this.searchable ? '250px' : ''})}"
      >
        <div
          id="road-multi-select-search"
          style=${styleMap({display: this.searchable ? 'block' : 'none'})}
        >
          <div class="road-multi-select__surface__search">
            ${this.searchInputMarkup()}
          </div>
          <div style="height:6px;"></div>
          ${this.async
            ? html`
                <div class=${asyncSearchOptionsLoadingIndicatorClasses}>
                  <span class="road-multi-select__surface__search__label">
                    Searching...
                  </span>
                  <road-loader loading></road-loader>
                </div>
              `
            : ''}
        </div>

        <div
          id="road-multi-select-options"
          class="road-multi-select__surface__options"
        >
          <div style="height:6px;"></div>
          <road-list
            style=${styleMap({display: hasCustomOptions ? 'grid' : 'none'})}
          >
            ${this.generatedOptionsMarkup('custom')}
          </road-list>
          <div class=${customOptionsDividerClasses}></div>
          <road-list>
            <slot
              @slotchange=${this.registerSlottedOptionNodes.bind(this)}
            ></slot>
          </road-list>
          <div style="height:6px;"></div>
          <road-list style=${asyncOptionsListStyles}>
            ${this.generatedOptionsMarkup('async')}
          </road-list>
          ${this.renderEmptySearchState()} ${this.renderNoSearchResults()}
        </div>
        <div class=${asyncScrollOptionsLoadingIndicatorClasses}>
          <road-loader loading></road-loader>
        </div>
      </div>
    `;
  }

  renderEmptySearchState() {
    return !this.searchText &&
      this.areAllOptionsHidden &&
      (!this.async || (this.hasFetched && !this.isSearching))
      ? html`<div class="no-results no-results--search">
          ${this.uniqueEmptyMessage}
        </div>`
      : '';
  }

  renderNoSearchResults() {
    return this.searchText &&
      this.areAllOptionsHidden &&
      (!this.async || (this.async && !this.isFetching && !this.isSearching))
      ? html`<div class="no-results no-results--search">
          ${this.uniqueNoResultsMessage}
        </div>`
      : '';
  }

  anchorLabelMarkup(option: Option) {
    const text = this.extractOptionText(option);

    const anchorLabelClasses = classMap({
      'road-multi-select__anchor__label--disabled': this.disabled,
    });

    return html`
      <div
        class="road-multi-select__anchor__label ${anchorLabelClasses}"
        data-value=${option.value}
        data-label=${text}
        title=${text}
        data-disabled=${option.disabled}
        data-selected=${option.selected}
      >
        <div class="road-multi-select__anchor__label__text">${text}</div>
        <div
          class="road-multi-select__anchor__label__icon"
          @click=${(e: MouseEvent) => {
            if (this.disabled) return;
            e.stopImmediatePropagation();
            this.deselectOptions([option]);
          }}
        >
          <mwc-icon style="--mdc-icon-size: 16px;" icon="close">close</mwc-icon>
        </div>
      </div>
    `;
  }

  anchorMarkupLabels() {
    if (!this.selected.length) {
      if (this.placeholderIcon) return html`<road-icon size="sm" icon=${this.placeholderIcon}></road-icon>`;

      return html`${this.placeholder}`;
    }

    return html`
      ${repeat(
        this.selected,
        (_, i: number) => `repeatkey:${COMPONENT_TAG}-${this.key}-${i}`,
        this.anchorLabelMarkup.bind(this)
      )}
    `;
  }

  anchorMarkup() {
    if (this.hasControlledAnchor) {
      return html`
        <div slot="anchor" id="road-multi-select-${this.key}-anchor">
          <slot
            name="anchor"
            @click=${() => (this.open = !this.open)}
            tabindex="1"
            id="road-multi-select-dropdown-anchor"
          ></slot>
        </div>
      `;
    }

    const anchorClasses = classMap({
      'road-multi-select': true,
      'road-multi-select--open': this.open && !this.disabled,
      'road-multi-select--disabled': this.disabled,
      'road-multi-select--has-selection': this.selected.length,
    });

    return html`
      <div
        class=${anchorClasses}
        tabindex="1"
        slot="anchor"
        id="road-multi-select-dropdown-anchor"
        @click=${() => (this.open = !this.open)}
      >
        <div class="road-multi-select__anchor__labels">
          ${this.anchorMarkupLabels()}
        </div>
        <div class="road-multi-select__anchor__icon">
          <mwc-icon icon=${this.anchorIcon}>${this.anchorIcon}</mwc-icon>
        </div>
      </div>
    `;
  }

  setCustomMarkup(
    type: 'custom' | 'async',
    handler: (option: Option) => TemplateResult | TemplateResult[]
  ): void {
    if (type === 'custom') this.customOptionMarkup = handler;
    if (type === 'async') this.asyncOptionMarkup = handler;
    this.requestUpdate();
  }

  updated(changedProperties: Map<string, unknown>) {
    changedProperties.forEach((oldValue, propName) => {
      switch (propName) {
        case 'open': {
          if (oldValue === this.open) return;
          this.dispatchEvent(
            new CustomEvent('openstate', {
              detail: {
                open: this.open,
              },
            })
          );

          if (this.open && this.$dropdown) {
            this.$dropdown.onAction({tag: DropdownMsgTag.UPDATE_DIMENSIONS});
          }

          this.autoAsyncLoadingIntervalRef = window.setInterval(() => {
            if (
              this.async &&
              this.open &&
              this.isOptionNodesContainerScrollableY
            ) {
              if (this.autoAsyncLoadingIntervalRef) {
                clearInterval(this.autoAsyncLoadingIntervalRef as number);
                this.autoAsyncLoadingIntervalRef = null;
              }
            } else if (
              this.async &&
              this.open &&
              !this.isTyping &&
              !this.isSearching &&
              !this.isFetching &&
              !this.isOptionNodesContainerScrollableY &&
              this.optionsArray.length < this.maxOptions
            ) {
              this.doAsyncFetch(this.searchText);
            }
          }, DEFAULT_DELAY_INTERVAL * 50);
          return;
        }
        case 'searchText': {
          if (this.autoAsyncLoadingIntervalRef) {
            clearInterval(this.autoAsyncLoadingIntervalRef as number);
            this.autoAsyncLoadingIntervalRef = null;
          }

          this.autoAsyncLoadingIntervalRef = window.setInterval(() => {
            if (
              this.async &&
              this.open &&
              this.isOptionNodesContainerScrollableY
            ) {
              if (this.autoAsyncLoadingIntervalRef) {
                clearInterval(this.autoAsyncLoadingIntervalRef as number);
                this.autoAsyncLoadingIntervalRef = null;
              }
            } else if (
              this.async &&
              this.open &&
              !this.isTyping &&
              !this.isSearching &&
              !this.isFetching &&
              !this.isOptionNodesContainerScrollableY &&
              this.optionsArray.length < this.maxOptions
            ) {
              this.doAsyncFetch(this.searchText);
            }
          }, DEFAULT_DELAY_INTERVAL * 50);
          break;
        }

        case 'maxSelected': {
          if (oldValue === this.maxSelected) return;
          if (
            Number.isNaN(this.maxSelected) ||
            !Number.isSafeInteger(this.maxSelected) ||
            this.maxSelected < 0
          ) {
            this.maxSelected = DEFAULT_MAX_SELECTED_OPTIONS;
          }
          break;
        }

        case 'maxOptions': {
          if (oldValue === this.maxOptions) return;
          if (
            Number.isNaN(this.maxOptions) ||
            !Number.isSafeInteger(this.maxOptions) ||
            this.maxOptions < 0
          ) {
            this.maxOptions = DEFAULT_MAX_OPTIONS;
          }
          break;
        }

        case 'selected': {
          this.maxSelectedGuard();

          const timeout = setTimeout(() => {
            this.dispatchEvent(
              new CustomEvent('change', {
                detail: {
                  open: this.open,
                  selected: this.selected,
                  key: this.key,
                },
              })
            );

            window.dispatchEvent(
              new CustomEvent(COMPONENT_TAG, {
                detail: {
                  open: this.open,
                  selected: this.selected,
                  key: this.key,
                },
              })
            );

            if (this.open && this.$dropdown) {
              this.$dropdown.onAction({
                tag: DropdownMsgTag.UPDATE_DIMENSIONS,
              });
            }

            clearTimeout(timeout);
          }, DEFAULT_DELAY_INTERVAL);

          return;
        }
      }
    });
  }

  reset() {
    //todo
  }

  init() {
    if (!this.searchable) {
      this.taggable = false;
    }

    if (this.async && this.$optionNodesContainer) {
      this.$optionNodesContainer.removeEventListener(
        'scroll',
        this.optionNodesContainerScrollListenerWithDebounce
      );

      this.$optionNodesContainer.addEventListener(
        'scroll',
        this.optionNodesContainerScrollListenerWithDebounce
      );
    }
  }

  onDropdownOpened() {
    if (this.open) return;
    const timeout = setTimeout(() => {
      this.open = true;
      this.searchFocus = true;
      clearTimeout(timeout);
    }, DEFAULT_DELAY_INTERVAL);
    return;
  }

  onDropdownClosed() {
    if (!this.open) return;
    const timeout = setTimeout(() => {
      this.open = false;
      this.searchFocus = false;
      clearTimeout(timeout);
    }, DEFAULT_DELAY_INTERVAL);
    return;
  }

  render() {
    return html`
      <div id="road-multi-select-${this.key}">
        <road-dropdown
          key=${this.key}
          ?active=${this.open}
          ?disabled=${this.disabled}
          @opened=${this.onDropdownOpened.bind(this)}
          @closed=${this.onDropdownClosed.bind(this)}
        >
          ${this.anchorMarkup()} ${this.dropdownSurfaceMarkup()}
        </road-dropdown>
        ${this.fieldLabel !== ''
          ? html`<label class="floating-label"><span class="floating-label">${this.fieldLabel}</span></label>`
          : ''}
      </div>
    `;
  }

  firstUpdated() {
    this.init();
    const interval = setInterval(() => {
      if (this.async && this.open && this.isOptionNodesContainerScrollableY) {
        clearInterval(interval);
      } else if (
        this.async &&
        this.open &&
        !this.isTyping &&
        !this.isSearching &&
        !this.isFetching &&
        !this.isOptionNodesContainerScrollableY
      ) {
        this.doAsyncFetch(this.searchText);
      }
    }, DEFAULT_DELAY_INTERVAL * 100);
  }
}
