import {CSSResultArray, html, TemplateResult} from 'lit';
import {property} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../lib/component';
import {isNil, isNonEmptyString, isString} from '../../../utils';
import {checkEquality} from '../../../utils/arrays';
import styles from './style.scss';

export const COMPONENT_TAG = 'road-form-field';

export enum DispatchMsg {
  ERRORS = 'errors',
  STATUS = 'status',
  VALUE = 'value',
  FIRST_TOUCHED = 'first-touched',
}

export enum ActionTag {
  UPDATE_STATUS = 'update:status',
  UPDATE_VALUE = 'update:value',
  RESET = 'reset',
}

export const FORM_FIELD_STATUS = {
  ACTIVE: 'active',
  VISITED: 'visited',
  TOUCHED: 'touched',
  MODIFIED: 'modified',
  PRISTINE: 'pristine',
  DIRTY: 'dirty',
} as const;

export type FormFieldStatus = typeof FORM_FIELD_STATUS[keyof typeof FORM_FIELD_STATUS];

export type Action =
  | {
      tag: ActionTag.UPDATE_STATUS;
      value: Array<[FormFieldStatus, boolean]>;
    }
  | {tag: ActionTag.UPDATE_VALUE; value: FormFieldValueType}
  | {tag: ActionTag.RESET; value?: FormFieldValueType};

export type FormFieldValueType = string | number | boolean | string[];

export enum FormFieldValueTypeRepr {
  STRING = 'string',
  NUMBER = 'number',
  BOOL = 'boolean',
  STRING_ARRAY = '[string]',
  CUSTOM = '*',
}

export enum FormFieldStatusColor {
  BASE = 'base',
  SUCCESS = 'success',
  DANGER = 'danger',
}

export interface FormFieldCustomValidationReport {
  readonly state: boolean;
  readonly message: ReadonlyArray<string>;
}

export interface FormFieldBooleanSchema {
  type: FormFieldValueTypeRepr.BOOL;
}

export interface FormFieldNumberSchema {
  type: FormFieldValueTypeRepr.NUMBER;
  min?: number;
  max?: number;
  step?: number;
  pattern?: string;
  integer: boolean;
}

export interface FormFieldStringSchema {
  type: FormFieldValueTypeRepr.STRING;
  minlength?: number;
  maxlength?: number;
  pattern?: string;
}

export interface FormFieldStringArraySchema {
  type: FormFieldValueTypeRepr.STRING_ARRAY;
  minsize?: number;
  maxsize?: number;
  options?: string[];
}

export interface FormFieldCustomSchema {
  type: FormFieldValueTypeRepr.CUSTOM;
  validator: (
    value: FormFieldValueType | null
  ) => FormFieldCustomValidationReport;
}

export type FormFieldSchema =
  | FormFieldBooleanSchema
  | FormFieldNumberSchema
  | FormFieldStringSchema
  | FormFieldStringArraySchema
  | FormFieldCustomSchema;

export type FormFieldSchemasAllProperties = {
  type: FormFieldValueTypeRepr;
} & Omit<FormFieldBooleanSchema, 'type'> &
  Omit<FormFieldNumberSchema, 'type'> &
  Omit<FormFieldStringSchema, 'type'> &
  Omit<FormFieldStringArraySchema, 'type'> &
  Omit<FormFieldCustomSchema, 'type'>;

export default abstract class RoadFormField extends RoadComponent {
  /** should be the key used in the JSON or FormData payload... key will be used as a fallback if this value is not set */
  abstract name: string;

  abstract label: string;

  abstract required: boolean;

  abstract schema: FormFieldSchema | null;

  /** initial value for the field... useful for `update` forms  where fields may or may not be prefilled with existing values. */
  abstract initial: FormFieldValueType | null;

  abstract value: FormFieldValueType | null;

  @property({type: String})
  key = `${new Date().getTime()}-${Math.random()}`;

  @property({type: String})
  placeholder = '';

  @property({type: String})
  description = '';

  @property({type: Boolean})
  disabled = false;

  @property({type: Boolean})
  readonly = false;

  /** determins if the use is actively interacting/filling this field */
  @property({type: Boolean})
  active = false;

  /** `true` if this field has ever gained and lost focus. `false` otherwise */
  @property({type: Boolean})
  touched = false;

  /** `true` if this field's value has ever been changed. `false` otherwise. Once `true`, it will remain `true` for the lifetime of the field, or until the form is reset. */
  @property({type: Boolean})
  modified = false;

  /** `true` if this field has ever gained focus. `false` otherwise. */
  @property({type: Boolean})
  visited = false;

  /** `true` if the current value is === to the initial value, `false` if the values are !==. */
  @property({type: Boolean})
  pristine = false;

  /** `true` when the value of the field is not equal to the initial value; `false` if the values are equal */
  @property({type: Boolean})
  dirty = false;

  @property({type: Boolean})
  invalid = false;

  @property({type: Boolean})
  hidden = false;

  @property({type: Array})
  errors: string[] = [];

  static get styles(): CSSResultArray {
    return [styles];
  }

  firstUpdated() {
    this.initialize();
  }

  initialize(initial: FormFieldValueType | null = this.initial) {
    this.initial = initial;
    this.value = this.initial;
    this.emit(DispatchMsg.VALUE);
  }

  /** for UX purposes; used to visually show the field's error state. Later on, a color could also be added to explicitly indicated a field's valid state (i.e green) */
  get intent() {
    if (this.errors.length && this.touched) return FormFieldStatusColor.DANGER;
    return FormFieldStatusColor.BASE;
  }

  protected resetState() {
    this.errors = [];
    this.dirty = false;
    this.hidden = false;
    this.visited = false;
    this.touched = false;
    this.pristine = false;
    this.modified = false;
    this.active = false;
  }

  resetInitialValue(value: FormFieldValueType | null = this.initial) {
    this.value = value;
  }

  reset(value: FormFieldValueType | null = this.initial) {
    this.resetInitialValue(value);
    this.resetState();
    this.emit(DispatchMsg.STATUS);
    this.emit(DispatchMsg.ERRORS);
    this.emit(DispatchMsg.VALUE);
  }

  emit(msg: DispatchMsg) {
    // Only begin event emission if touched.
    if (!this.touched && msg !== DispatchMsg.FIRST_TOUCHED) return;

    switch (msg) {
      case DispatchMsg.ERRORS: {
        this.dispatchEvent(
          new CustomEvent(DispatchMsg.ERRORS, {detail: {errors: this.errors}})
        );

        return;
      }

      case DispatchMsg.FIRST_TOUCHED: {
        if (!this.touched) this.touched = true;
        this.dispatchEvent(new CustomEvent('firstTouched'));
        break;
      }

      case DispatchMsg.STATUS: {
        this.dispatchEvent(
          new CustomEvent(DispatchMsg.STATUS, {
            detail: {
              active: this.active,
              visited: this.visited,
              hidden: this.hidden,
              touched: this.touched,
              modified: this.modified,
              dirty: this.dirty,
              pristine: this.pristine,
            },
          })
        );

        return;
      }

      case DispatchMsg.VALUE: {
        this.dispatchEvent(
          new CustomEvent(DispatchMsg.VALUE, {
            detail: {value: this.value, initial: this.initial},
          })
        );

        return;
      }
    }
  }

  action(action: Action) {
    if (this.disabled || this.readonly) return;
    switch (action.tag) {
      case ActionTag.UPDATE_STATUS: {
        let firstTouched = false;
        for (const [key, value] of action.value) {
          if (key === 'touched' && !this.touched) {
            firstTouched = true;
          }

          this[key] = value;
        }

        this.runStatusSync();
        if (firstTouched) this.emit(DispatchMsg.FIRST_TOUCHED);
        return;
      }

      case ActionTag.UPDATE_VALUE: {
        this.value = action.value;
        this.runStatusSync();
        this.runValidation();
        this.emit(DispatchMsg.VALUE);
        this.requestUpdate();
        return;
      }

      case ActionTag.RESET: {
        this.reset();
        return;
      }
    }
  }

  runValidation() {
    if (this.readonly || this.disabled || !this.schema) return;
    switch (this.schema.type) {
      case FormFieldValueTypeRepr.STRING: {
        this.errors = this.runStringValidation(this.value, this.schema);
        break;
      }
      case FormFieldValueTypeRepr.NUMBER: {
        this.errors = this.runNumberValidation(this.value, this.schema);
        break;
      }
      case FormFieldValueTypeRepr.BOOL: {
        this.errors = this.runBooleanValidation(this.value, this.schema);
        break;
      }
      case FormFieldValueTypeRepr.STRING_ARRAY: {
        this.errors = this.runArrayStringValidation(this.value, this.schema);
        break;
      }
      case FormFieldValueTypeRepr.CUSTOM: {
        const {message} = this.schema.validator(this.value);
        this.errors = message.slice();
        break;
      }
    }
    this.emit(DispatchMsg.ERRORS);
  }

  private runStringValidation(
    value: unknown,
    schema: FormFieldSchema
  ): string[] {
    if (schema.type !== FormFieldValueTypeRepr.STRING)
      return ['Validation schema mismatch'];
    if (!this.touched) return [];

    if (!this.required && isNil(value)) return [];

    if (this.required && !isNonEmptyString(value)) return ['Required'];

    if (!isString(value)) return ['Must be a string'];

    const errors = new Set<string>();
    const {
      minlength = 0,
      maxlength = Number.MAX_SAFE_INTEGER,
      pattern = '',
    } = schema;

    if (Number.isSafeInteger(maxlength) && value.trim().length > maxlength) {
      errors.add('Too long');
    }

    if (Number.isSafeInteger(minlength) && value.trim().length < minlength) {
      errors.add('Too short');
    }

    if (!new RegExp(pattern).test(value)) {
      errors.add('Pattern mismatch');
    }

    return Array.from(errors.values());
  }

  private runNumberValidation(
    value: unknown,
    schema: FormFieldSchema
  ): string[] {
    if (schema.type !== FormFieldValueTypeRepr.NUMBER)
      return ['Validation schema mismatch'];
    if (!this.touched) return [];

    if (!this.required && isNil(value)) return [];

    const _value = Number(value);

    if (this.required && Number.isNaN(_value)) return ['Required'];
    if (Number.isNaN(_value)) return ['Must be a number'];

    const errors = new Set<string>();

    const {min = 0, max = Number.MAX_SAFE_INTEGER, integer = false} = schema;

    if (Number.isSafeInteger(max) && _value > max) {
      errors.add('Too big');
    }

    if (Number.isSafeInteger(min) && _value < min) {
      errors.add('Too small');
    }

    if (integer && !Number.isSafeInteger(_value)) {
      errors.add('Must be an integer');
    }

    return Array.from(errors.values());
  }

  /** This is kind of silly... but yeah, no value type left behind... */
  private runBooleanValidation(
    value: unknown,
    schema: FormFieldSchema
  ): string[] {
    if (schema.type !== FormFieldValueTypeRepr.BOOL)
      return ['Validation schema mismatch'];
    if (!this.touched) return [];
    if (!this.required && isNil(value)) return [];
    if (this.required && typeof value !== 'boolean') return ['Required'];
    if (typeof value !== 'boolean') return ['Must be a boolean value'];
    return [];
  }

  private runArrayStringValidation(
    value: unknown,
    schema: FormFieldSchema
  ): string[] {
    if (schema.type !== FormFieldValueTypeRepr.STRING_ARRAY)
      return ['Validation schema mismatch'];
    if (!this.touched) return [];
    if (!this.required && isNil(value)) return [];

    if (
      this.required &&
      (!Array.isArray(value) ||
        !Boolean(value.length) ||
        value.includes(this.placeholder))
    )
      return ['Required'];

    if (!Array.isArray(value)) return ['Malformed input'];

    const errors = new Set<string>();
    const {
      minsize = 0,
      maxsize = Number.MAX_SAFE_INTEGER,
      options = [],
    } = schema;

    if (Array.isArray(options) && value.length) {
      // All available options in the select field.
      const _options = new Set<string>(options.filter(isString));
      if (
        // Ensures that every selected value is present in the above set of ptions.
        !value.every(item => item === this.placeholder || _options.has(item))
      ) {
        errors.add('Includes invalid option');
      }
    }

    if (Number.isSafeInteger(minsize) && value.length < minsize) {
      errors.add('Too few selected');
    }

    if (Number.isSafeInteger(maxsize) && value.length > maxsize) {
      errors.add('Too many selected');
    }

    return Array.from(errors.values());
  }

  /** maintains some invariants... for example, it doesn't make sense for a field to be `touched` but not `visited` */
  runStatusSync() {
    if (this.readonly || this.disabled) return;
    const status = new Map<FormFieldStatus, boolean>();
    if (this[FORM_FIELD_STATUS.ACTIVE] && !this[FORM_FIELD_STATUS.VISITED]) {
      status.set(FORM_FIELD_STATUS.VISITED, true);
    }

    if (this[FORM_FIELD_STATUS.TOUCHED] && this[FORM_FIELD_STATUS.VISITED]) {
      status.set(FORM_FIELD_STATUS.VISITED, true);
    }

    const currentValueIsInitialValue = (() => {
      if (Array.isArray(this.value) && Array.isArray(this.initial)) {
        return checkEquality<string | number>(this.value, this.initial);
      }
      return this.value === this.initial;
    })();

    status.set(FORM_FIELD_STATUS.PRISTINE, currentValueIsInitialValue);

    status.set(FORM_FIELD_STATUS.DIRTY, !currentValueIsInitialValue);

    if (!currentValueIsInitialValue) {
      status.set(FORM_FIELD_STATUS.MODIFIED, true);
    }

    for (const [key, value] of status) {
      this[key] = value;
    }

    this.emit(DispatchMsg.STATUS);
  }

  /** this is used by the FormController */
  beforeFormSubmission() {
    this.active = false;
    this.visited = true;
    this.touched = true;
    this.runStatusSync();
    this.runValidation();
  }

  labelMarkup() {
    return html`
      <div class="road-form__field__header">
        <div class="road-form__field__label">
          <slot name="label">${this.label}</slot>
        </div>
        ${this.description
          ? html`<div class="road-form__field__description">
              ${this.description}
            </div>`
          : ''}
      </div>
    `;
  }

  errorsMarkup() {
    return html`
      <ul class="road-form__field__errors">
        ${repeat(
          this.errors,
          (_, idx: number) => `repeatkey:${COMPONENT_TAG}-${this.key}-${idx}`,
          (error: string) => {
            return html`<li class="road-form__field__error">${error}</li> `;
          }
        )}
      </ul>
    `;
  }

  /** must be implemented by components extending this component */
  abstract fieldMarkup(): TemplateResult | TemplateResult[];

  render() {
    return html`
      <div class="road-form__field-container" ?hidden=${this.hidden}>
        ${this.labelMarkup()}
        <!---->
        <div class="road-form__field">${this.fieldMarkup()}</div>
        <!---->
        ${this.errorsMarkup()}
      </div>
    `;
  }
}
