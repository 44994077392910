import {html} from 'lit';
import {property, state, customElement, query} from 'lit/decorators.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import {User} from '../../../../types/user';
import {Payee} from '../../../../types/payee';
import {
  createPayee,
  updatePayee,
} from '../../../../services/payees';

import styles from './style.scss';

@customElement('road-payee')
export class RoadPayee extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Boolean})
  open = false;

  @property({type: Object})
  payee!: Payee;

  @property({type: Object})
  user!: User;

  @state()
  editing = false;

  static get styles() {
    return [styles];
  }

  load() {
    if (this.payee) {
      this.editing = true;
    } else {
      this.emptyPayee();
    }
  }

  emptyPayee() {
    this.payee = {
      id: 0,
      name: '',
      user: this.user,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
  }


  handleNameChange(evt: CustomEvent) {
    this.payee.name = evt.detail.value;
  }

  renderPayeeForm() {
    if (!this.payee) return html``;

    return html`
      <div class="payee-form">
        ${this.renderPayeeFields()}
      </div>
    `;
  }

  renderPayeeField() {
    const label = 'Payee';

    return html`
      <road-input
        id="payee"
        class="with-label fixed-width-md fixed-height-md"
        .fieldLabel=${label}
        placeholder='Payee'
        .value=${String(this.payee.name)}
        @change=${(e: CustomEvent) => {
          this.handleNameChange(e);
          this.requestUpdate();
        }}
      >
      </road-input>
    `;
  }

  renderPayeeFields() {
    return html`
      <div class="payee-form__fields">
        ${this.renderPayeeField()}
      </div>
    `;
  }

  renderHeaderCloseButtonMarkup() {
    return html`
      <div slot="header-aside">
        <road-icon icon="close" size="sm"></road-icon>
      </div>
    `;
  }

  handleToggleModal(evt: 'closed' | 'opened' | 'cancel' | 'confirm') {
    if ((evt === 'closed' || evt === 'cancel') && this.open) {
      this.open = false;
      this.dispatchEvent(new CustomEvent(evt));
      return;
    }

    if (evt === 'confirm' && this.open) {
      this.createOrUpdatePayee(this.payee);
      this.open = false;
      return;
    }

    if (evt === 'closed' && this.open) {
      this.open = false;
      this.dispatchEvent(new CustomEvent('closed'));
      return;
    }
  }

  async createOrUpdatePayee(payee: Payee) {
    if (payee.id === 0) {
      const response = await createPayee(payee);
      if (response.ok) {
        this.dispatchEvent(new CustomEvent(
          'confirm',
          { detail: { payee: response.payee, created: true }}
        ));
      }
    } else {
      const response = await updatePayee(payee);
      if (response.ok) {
        this.dispatchEvent(new CustomEvent(
          'confirm',
          { detail: { payee: response.payee, updated: true }}
        ));
      }
    }
  }

  render() {
    const okLabel = this.editing ? 'Update' : 'Create';
    const cancelLabel = 'Close';

    return html`
      <road-modal
        ?open=${this.open}
        ?actions=${true}
        label="Payee details"
        .okLabel=${okLabel}
        .cancelLabel=${cancelLabel}
        @closed=${() => this.handleToggleModal('closed')}
        @opened=${() => this.handleToggleModal('opened')}
        @cancel=${() => this.handleToggleModal('cancel')}
        @ok=${() => this.handleToggleModal('confirm')}
      >
        ${this.renderHeaderCloseButtonMarkup()}
        ${this.renderPayeeForm()}

      </road-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-payee': RoadPayee;
  }
}
