import {html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import {RoadComponent} from '../../../../lib/component';

import styles from './style.scss';

@customElement('road-payee-button')
export class RoadPayeeButton extends RoadComponent {
  @property({type: Boolean})
  open = false;

  static get styles() {
    return [styles];
  }

  render() {
    return html`
      <road-icon
        class="clickable"
        icon="storefront"
        @click=${() => this.open = true}
      ></road-icon>
      <road-payee
        ?open=${this.open}
        @closed=${() => this.open = false}
        @cancel=${() => this.open = false}
        @confirm=${() => this.open = false}
      >
      </road-payee>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-payee-button': RoadPayeeButton;
  }
}
