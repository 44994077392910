import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {styleMap} from 'lit/directives/style-map.js';
import {RoadComponent} from '../../../lib/component';
import styles from './style.scss';

export const SPINNER_ELEMENT_TAG = 'road-loader';

/**
 * Alternative to bar loader; a typical spinning loader component
 */
@customElement(SPINNER_ELEMENT_TAG)
export default class RoadLoader extends RoadComponent {
  @property({type: Boolean, reflect: true})
  loading = false;

  @property({type: Boolean})
  showLoader = false;

  @property({type: Boolean})
  size = true;

  @query('#content')
  content?: HTMLElement;

  static get styles() {
    return [styles];
  }

  updated(changedProperties: Map<string, unknown>) {
    changedProperties.forEach((oldValue, propName) => {
      if (
        propName === 'loading' &&
        this.loading === false &&
        Boolean(oldValue) === true
      ) {
        if (this.content) {
          this.showLoader = false;
          this.content.classList.remove('faded-out');
          this.content.classList.add('fade-in');

          setTimeout(() => {
            if (!this.content) return;
            this.content.classList.remove('fade-in');
            this.content.classList.add('faded-in');
          }, 400);
        }
      }

      if (
        propName === 'loading' &&
        this.loading === true &&
        Boolean(oldValue) === false
      ) {
        if (this.content) {
          this.content.classList.add('fade-out');

          setTimeout(() => {
            if (!this.content) return;
            this.content.classList.remove('fade-out');
            this.content.classList.add('faded-out');
            this.showLoader = true;
          }, 400);
        }
      }
    });
  }

  beforeLoad() {
    if (this.loading) this.showLoader = true;
  }

  loaderMarkup() {
    if (!this.showLoader) {
      return html``;
    }

    return html`<div class="road-loader"></div>`;
  }

  contentMarkup() {
    const contentStyle = {
      display: this.showLoader ? 'none' : 'initial',
    };

    return html`
      <div id="content" style="${styleMap(contentStyle)}">
        <slot></slot>
      </div>
    `;
  }

  render() {
    return html`${this.loaderMarkup()} ${this.contentMarkup()}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-loader': RoadLoader;
  }
}
