import {html} from 'lit';
import {property, customElement, queryAll} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../../lib/component';

import styles from './style.scss';

import {MovementStatus, statusDescriptions} from '../../../../types/movement';
import RoadCheckbox from 'src/components/base/checkbox';

export enum MovementStatusFiltersEvents {
  MOVEMENT_STATUS_CHANGED = 'filters-movement-statuses-changed',
}

const movementStatuses = [
  MovementStatus.UNCONFIRMED,
  MovementStatus.CONFIRMED,
  MovementStatus.RECONCILING,
];

@customElement('road-movement-status-filters')
export class RoadMovementStatusFilters extends RoadComponent {
  @property({type: Array})
  selectedMovementStatuses: MovementStatus[] = [];

  @queryAll('road-checkbox[name="movement-statuses"]') checkboxes!: RoadCheckbox[];

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.selectedMovementStatuses = [];
    this.checkboxes.forEach(checkbox => checkbox.deselect());
    this.requestUpdate();
  }

  handleMovementStatusesChange(evt: CustomEvent): void {
    const movementStatus = movementStatuses.find((status: MovementStatus) => status === Number(evt.detail.value));
    if (movementStatus === undefined) return;

    if (evt.detail.checked) {
      this.selectedMovementStatuses.push(movementStatus)
    } else {
      this.selectedMovementStatuses = this.selectedMovementStatuses.filter((status: MovementStatus) => status !== movementStatus);
    }

    this.dispatchMovementStatusesChangedEvent();
  }

  dispatchMovementStatusesChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent(MovementStatusFiltersEvents.MOVEMENT_STATUS_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: this.selectedMovementStatuses,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-movement-status-filters__filter">
          <label for="movement-statuses">Status</label>
          <div class="road-movement-status-filters__checks-container">
            ${repeat(
              movementStatuses,
              (movementStatus: MovementStatus) => `movement-status-filter-${movementStatus}`,
              (movementStatus: MovementStatus) => {
                const statusName = statusDescriptions.get(movementStatus)?.description || '';
                const statusIcon = statusDescriptions.get(movementStatus)?.icon || '';
                return html`<road-checkbox
                  name="movement-statuses"
                  value="${movementStatus}"
                  label="${statusName}"
                  ?checked="${this.selectedMovementStatuses.includes(movementStatus)}"
                  @change="${(evt: CustomEvent) => {
                    this.handleMovementStatusesChange(evt);
                  }}"
                >
                  <road-icon icon="${statusIcon}" size="sm"></road-icon>
                  ${statusName}
                </road-checkbox>`
              }
            )}
          </div>
        </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-movement-status-filters': RoadMovementStatusFilters;
  }
}
