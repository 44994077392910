import {LitElement, html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';
import styles from './style.scss';
import { RoadComponent } from '../../../lib/component';

export const COMPONENT_TAG = 'road-button';

export enum Variant {
  BASE = 'base',
  OUTLINED = 'outlined',
  LINK = 'link',
}

export enum Intent {
  BASE = 'base',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
}

export enum Size {
  SM = 'sm',
  BASE = 'base',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum Type {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

/**
 * RoadButton
 */
@customElement('road-button')
export default class RoadButton extends RoadComponent {
  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @property()
  label = '';

  @property()
  href = '';

  @property()
  type: Type = Type.BUTTON;

  @property()
  size: Size = Size.BASE;

  @property({type: Boolean})
  hasIcon = false;

  @property({type: Boolean})
  appendIcon = false;

  @property({type: Boolean})
  noPadding = false;

  @property({type: Boolean})
  rounded = false;

  @property({type: Boolean, reflect: true})
  disabled = false;

  @property({type: Boolean, reflect: true})
  blurOnClick = false;

  @property({type: Boolean})
  elevated = false;

  @property()
  variant: Variant = Variant.BASE;

  @property()
  intent: Intent = Intent.PRIMARY;

  @query('button')
  $button!: HTMLButtonElement;

  /**
   * Styles
   */
  static get styles() {
    return [styles];
  }

  get classNames(): ClassInfo {
    return {
      'road-btn': true,
      'road-btn--rounded': this.rounded,
      'road-btn--disabled': this.disabled,
      'road-btn--no-padding': this.noPadding,
      'road-btn--blur-onclick': this.blurOnClick,
      'road-btn--icon': this.hasIcon,
      'road-btn--sm': this.size === Size.SM,
      'road-btn--md': this.size === Size.MD,
      'road-btn--lg': this.size === Size.LG,
      'road-btn--xl': this.size === Size.XL,
      'road-btn--primary': this.intent === Intent.PRIMARY,
      'road-btn--secondary': this.intent === Intent.SECONDARY,
      'road-btn--success': this.intent === Intent.SUCCESS,
      'road-btn--warning': this.intent === Intent.WARNING,
      'road-btn--danger': this.intent === Intent.DANGER,
      'road-btn--info': this.intent === Intent.INFO,
      'road-btn--outlined': this.variant === Variant.OUTLINED,
      'road-btn--outlined--primary':
        this.variant === Variant.OUTLINED && this.intent === Intent.PRIMARY,
      'road-btn--outlined--secondary':
        this.variant === Variant.OUTLINED && this.intent === Intent.SECONDARY,
      'road-btn--outlined--success':
        this.variant === Variant.OUTLINED && this.intent === Intent.SUCCESS,
      'road-btn--outlined--warning':
        this.variant === Variant.OUTLINED && this.intent === Intent.WARNING,
      'road-btn--outlined--danger':
        this.variant === Variant.OUTLINED && this.intent === Intent.DANGER,
      'road-btn--outlined--info':
        this.variant === Variant.OUTLINED && this.intent === Intent.INFO,
      'road-btn--link': this.variant === Variant.LINK,
      'road-btn--link--primary':
        this.variant === Variant.LINK && this.intent === Intent.PRIMARY,
      'road-btn--link--secondary':
        this.variant === Variant.LINK && this.intent === Intent.SECONDARY,
      'road-btn--link--success':
        this.variant === Variant.LINK && this.intent === Intent.SUCCESS,
      'road-btn--link--warning':
        this.variant === Variant.LINK && this.intent === Intent.WARNING,
      'road-btn--link--danger':
        this.variant === Variant.LINK && this.intent === Intent.DANGER,
      'road-btn--link--info':
        this.variant === Variant.LINK && this.intent === Intent.INFO,
      'road-btn--elevated': this.elevated,
      'road-btn--elevated--secondary':
        this.elevated && this.intent === Intent.SECONDARY,
    };
  }

  blur() {
    if (this.$button) {
      this.$button.blur();
    }
  }

  link() {
    if (!this.href) return;
    location.href = this.href;
  }

  /**
    Renderer
   * @override
   */
  render() {
    const contentMarkup = () => {
      if (!this.hasIcon) return html`<slot></slot>`;

      const icon = html`
        <div class="road-btn--icon${this.appendIcon ? '__append' : '__prepend'}">
          <slot name="icon"></slot>
        </div>
      `;

      const label = html`
        <div class="road-btn--icon__label">
          <slot></slot>
        </div>
      `;

      return this.appendIcon ? html`${label} ${icon}` : html`${icon} ${label}`;
    };

    return html`
      <button
        type=${this.type}
        class=${classMap(this.classNames)}
        style=${styleMap({
          width: 'var(--road-button-width, initial)',
        })}
        ?disabled=${this.disabled}
        @click=${() => {
          this.blurOnClick && this.blur();
          if (this.href) this.link();
        }}
      >
        ${contentMarkup()}
      </button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-button': RoadButton;
  }
}
