import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../lib/component';

import styles from './style.scss';

export const COMPONENT_TAG = 'road-tab';

@customElement(COMPONENT_TAG)
export default class RoadTab extends RoadComponent {
  @property()
  key: string = `${new Date().getTime()}-${Math.random()}`;

  @query('.road-tab') $node!: HTMLElement;

  @property() panel = '';

  @property({type: Boolean, reflect: true}) active = false;

  @property({type: Boolean, reflect: true}) disabled = false;

  static get styles() {
    return [styles];
  }

  focus(options?: FocusOptions) {
    this.$node?.focus(options);
  }

  blur() {
    this.$node?.blur();
  }

  get classes() {
    return {
      'road-tab': true,
      'road-tab--active': this.active,
      'road-tab--disabled': this.disabled,
    };
  }

  render() {
    return html`
      <div
        class=${classMap(this.classes)}
        role="tab"
        aria-controls=${this.panel}
        aria-disabled=${this.disabled ? 'true' : 'false'}
        aria-selected=${this.active ? 'true' : 'false'}
        tabindex=${this.disabled || !this.active ? '-1' : '0'}
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_TAG]: RoadTab;
  }
}
