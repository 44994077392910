import {html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import {RoadComponent} from '../../../lib/component';

import {User} from '../../../types/user';

import styles from './style.scss';

@customElement('road-user-profile')
export class RoadUserProfile extends RoadComponent {
  @property({type: Object})
  user!: User;

  static get styles() {
    return [styles];
  }

  render() {
    return html`<div class="row">
      <div class="col-12"><div class="card"><div class="card-body">
      <p>Name: ${this.user.name}</p>
      <p>Email: ${this.user.email}</p>
      <p>Currency: ${this.user.default_currency.name}</p>
      <p>Sign in count: ${this.user.sign_in_count}</p>
      <p>Current sign in: ${this.user.current_sign_in_at}</p>
      <p>IP: ${this.user.current_sign_in_ip}</p>
      <p>Subscription: <b>${this.user.user_subscription.name}</b> (${this.user.user_subscription.max_accounts_text} |
        ${this.user.user_subscription.max_currencies_text} |
        ${this.user.user_subscription.max_transactions_text})</p>
      </div></div></div></div>`;
  }

  // This is needed to make the component render in the shadow dom
  // It uses css from the parent component (not defined here)
  createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-user-profile': RoadUserProfile;
  }
}
