import '../icon';
import '../list';
import '../dropdown';

import {html} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';
import {styleMap} from 'lit/directives/style-map.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../lib/component';
import {isNonEmptyString} from '../../../utils';
import RoadListItem from '../list_item';
import RoadInput, {Variant as RoadInputVariant} from '../input';
import {fuzzyMatch} from '../../../utils/strings';
import RoadIcon from '../icon';

import styles from './style.scss';
import inputStyles from '../../abstract/style.scss';

export interface CustomSelectEvent {
  value: string;
  label: string;
  key: string;
}

export enum SelectVariant {
  DEFAULT = 'default',
  INLINE = 'filter',
  CONDENSED = 'condensed',
  CONDENSED_SQUARE = 'condensed-square',
  CONDENSED_ROUNDED_SQUARE = 'condensed-rounded-square',
}

export const COMPONENT_TAG = 'road-select';

@customElement(COMPONENT_TAG)
export default class RoadSelect extends RoadComponent {
  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @property()
  variant: SelectVariant = SelectVariant.DEFAULT;

  @property({type: Boolean})
  active = false;

  @property({type: Boolean})
  formfield = false;

  @property({type: Boolean, reflect: true})
  formfieldErrors = false;

  @property({type: Boolean})
  disabled = false;

  @property({type: Boolean})
  searchable = false;

  @property({type: Boolean})
  loading = false;

  @property({type: Boolean})
  selectionRequired = false;

  @property({attribute: 'has-controlled-anchor', type: Boolean})
  hasControlledAnchor = false;

  @property()
  searchText = '';

  @property({type: Boolean})
  searchFocus = false;

  @property()
  placeholder = 'Select';

  @property()
  placeholderIcon?: string;

  @property({type: String})
  initial = '';

  @property({type: String})
  label = '';

  @property({type: String})
  fieldLabel = '';

  @query('road-input')
  $input!: RoadInput;

  @query('#deselect-button')
  $resetButton!: RoadIcon;

  @state()
  value = '';

  @state()
  $options: Set<HTMLElement> = new Set<HTMLElement>();

  @state()
  firstUpdateCompleted = false;

  initialize(value: string = this.initial) {
    this.value = value;
    for (const $el of this.$options) {
      const $option = $el as RoadListItem;
      $option.selected = $option.value === this.value;
      if ($option.selected) $option.dispatchSelectionEvent();
    }
  }

  beforeLoad() {
    this.initialize();
  }

  updated(changedProperties: Map<string, unknown>) {
    if (!this.firstUpdateCompleted) {
      this.firstUpdateCompleted = true;
      return;
    }

    changedProperties.forEach((oldValue, propName) => {
      if (propName !== 'value') return;
      if (oldValue === this.value) return;
      this.dispatchEvent(
        new CustomEvent<CustomSelectEvent>('selected', {
          detail: {value: this.value, label: this.label, key: this.key},
        })
      );
      window.dispatchEvent(
        new CustomEvent(COMPONENT_TAG, {
          detail: {value: this.value, label: this.label, key: this.key},
        })
      );
    });
  }

  /**
   * Styles
   */
  static get styles() {
    return [styles, inputStyles];
  }

  get computedClasses(): Record<string, ClassInfo> {
    return {
      anchor: {
        'road-select': true,
        'road-select--inline': this.variant === SelectVariant.INLINE,
        'road-select--condensed': this.variant === SelectVariant.CONDENSED,
        'road-select--condensed-square':
          this.variant === SelectVariant.CONDENSED_SQUARE,
        'road-select--condensed-rounded-square':
          this.variant === SelectVariant.CONDENSED_ROUNDED_SQUARE,
        'road-select--active': this.active,
        'road-select--error': this.formfield && this.formfieldErrors,
        'road-select--disabled': this.disabled,
        'road-select--loading': this.loading,
        'road-select--has-selection': isNonEmptyString(this.value),
      },
    };
  }

  get anchorLabel() {
    if (isNonEmptyString(this.value) && isNonEmptyString(this.label)) return this.label

    if (this.placeholderIcon) return html`<road-icon size="sm" icon=${this.placeholderIcon}></road-icon>`;

    return this.placeholder;
  }

  get computedIcon() {
    if (this.value && !this.selectionRequired) {
      return 'close';
    } else {
      return this.active ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
    }
  }

  onDropdownOpened() {
    if (this.active) return;
    const timeout = setTimeout(() => {
      this.active = true;
      this.searchFocus = true;
      clearTimeout(timeout);
      this.dispatchEvent(new CustomEvent('focus'));
    }, 100);
    return;
  }

  onDropdownClosed() {
    if (!this.active) return;

    this.dispatchEvent(new CustomEvent('closed'));
    const timeout = setTimeout(() => {
      this.active = false;
      this.searchFocus = false;
      clearTimeout(timeout);
      this.dispatchEvent(new CustomEvent('blur'));
    }, 100);
    return;
  }

  onSlotChange(evt: Event) {
    const $options = (evt.currentTarget as HTMLSlotElement)
      .assignedNodes()
      .reduce((collection: Set<HTMLElement>, node: Node) => {
        const $el = node as HTMLElement;
        if ($el.localName !== 'road-list-item' || collection.has($el)) {
          return collection;
        }

        $el.addEventListener('selected', (evt: Event) => {
          if (evt instanceof CustomEvent) {
            for (const $option of this.$options) {
              if (!$option.isSameNode($el)) {
                ($option as RoadListItem).selected = false;
              }
            }

            this.value = evt.detail.value;
            this.label = evt.detail.label;

            if (!isNonEmptyString(this.label)) {
              this.label = $el.textContent || '';
            }

            const timeout = setTimeout(() => {
              if (this.active) {
                this.active = false;
              }
              this.searchFocus = false;
              clearTimeout(timeout);
            }, 100);
          }
        });

        $el.addEventListener('deselected', (evt: Event) => {
          if (evt instanceof CustomEvent) {
            if (evt.detail.value === this.value) {
              this.value = '';
              this.label = '';

              const timeout = setTimeout(() => {
                this.searchFocus = false;
                clearTimeout(timeout);
              }, 100);
            }
          }
        });

        collection.add($el);

        return collection;
      }, new Set<HTMLElement>(this.$options));

    for (const $el of $options) {
      const $option = $el as RoadListItem;
      $option.selected = $option.value === this.value;
      if ($option.selected) $option.dispatchSelectionEvent();
    }

    this.$options = $options;
  }

  onSearchUpdate(evt: Event) {
    if (evt instanceof CustomEvent) {
      this.searchText = evt.detail.value;
      const $options = this.$options;

      if (!isNonEmptyString(this.searchText)) {
        for (const $el of $options) {
          const $option = $el as RoadListItem;
          if ($option.style.display === 'none') {
            $option.style.display = 'flex';
          }
        }

        return;
      }

      for (const $el of $options) {
        const $option = $el as RoadListItem;
        const label =
          $option.textContent || $option.innerText || $option.innerHTML || '';

        const needle = this.searchText.trim().toLowerCase();
        const ismatch =
          isNonEmptyString(label) &&
          fuzzyMatch(needle, label.trim().toLowerCase());

        $option.style.display = ismatch ? 'flex' : 'none';
      }
    }
  }

  deselect() {
    this.value = '';
    this.label = '';

    for (const el of this.$options) {
      const listEl = el as RoadListItem;
      if (listEl.selected) listEl.selected = false;
    }
    this.active = false;

    const event = new CustomEvent<CustomSelectEvent>('cleared');
    this.dispatchEvent(event);
  }

  renderSurface() {
    if (this.searchable) {
      return html`
        <div
          slot="surface"
          class="road-select__surface road-select__surface--has-search"
          ?loading=${this.loading}
        >
          <road-input
            hasicon
            key=${this.key}
            type="search"
            minlength=${1}
            @input=${this.onSearchUpdate.bind(this)}
            @change=${this.onSearchUpdate.bind(this)}
            variant=${RoadInputVariant.BORDERLESS}
            ?autofocus=${this.searchFocus}
          >
            <div slot="icon">
              <road-icon icon="search"></road-icon>
            </div>
          </road-input>
          <road-list ?loading=${this.loading}>
            <slot @slotchange=${this.onSlotChange.bind(this)}></slot>
          </road-list>
        </div>
      `;
    }

    return html`
      <road-list slot="surface" class="road-select__surface" ?loading=${this.loading}>
        <slot @slotchange=${this.onSlotChange.bind(this)}></slot>
      </road-list>
    `;
  }

  renderAnchor() {
    if (this.hasControlledAnchor) {
      return html`
        <div slot="anchor" id="road-select-${this.key}-anchor">
          <slot
            name="anchor"
            @click=${() => (this.active = !this.active)}
            tabindex="1"
          ></slot>
        </div>
      `;
    }
    return html`
      <div
        @click=${() => (this.active = !this.active)}
        class=${classMap(this.computedClasses.anchor)}
        tabindex="1"
        slot="anchor"
        id="road-select-${this.key}-anchor"
      >
        <div
          class="road-select__anchor__label ${classMap({
            'road-select__anchor__label--populated': this.value,
          })}"
        >
          ${this.anchorLabel}
        </div>
        <div
          class="road-select__anchor__icon"
          class=${classMap({
            'road-select__anchor__icon--close': this.value,
          })}
        >
          <road-icon
            id="deselect-button"
            @click=${(e: MouseEvent) => {
              if (this.value && !this.selectionRequired) {
                e.stopImmediatePropagation();
                this.deselect();
              }
            }}
            style=${styleMap({
              '--road-icon-size': this.value ? '16px' : 'initial',
            })}
            icon=${this.computedIcon}
          ></road-icon>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div id="road-select-${this.key}" ?loading=${this.loading}>
        <road-dropdown
          @opened=${this.onDropdownOpened.bind(this)}
          @closed=${this.onDropdownClosed.bind(this)}
          key=${this.key}
          ?active=${this.active}
        >
          ${this.renderAnchor()} ${this.renderSurface()}
        </road-dropdown>
        ${this.fieldLabel !== ''
          ? html`<label class="floating-label"><span class="floating-label">${this.fieldLabel}</span></label>`
          : ''}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_TAG]: RoadSelect;
  }
}
