import {html, TemplateResult} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';

import {classMap} from 'lit/directives/class-map.js';
import {repeat} from 'lit/directives/repeat.js';
import {styleMap} from 'lit/directives/style-map.js';

import {RoadComponent} from '../../../lib/component';
import {isPlainObject, isNonEmptyString} from '../../../utils/index';
import styles from './style.scss';
import {Alignment} from '../../../common/constants';

import {DEFAULT_SVG_ICON_SIZE} from '../../../components/base/icon';

const COMPONENT_TAG = 'road-table';
const DEFAULT_EMPTY_STATE: TableEmptyStateSpec = {
  description: 'No data available in table',
  icon: 'error',
  svgIcon: false,
  svgIconSize: DEFAULT_SVG_ICON_SIZE,
};

export enum TableUIEvent {
  ROW_CLICK = 'row-click',
  CELL_CLICK = 'cell-click',
  ROW_SELECTION = 'row-selection',
}

export interface TableRowClickEvent {
  readonly tag: TableUIEvent.ROW_CLICK;
  readonly evt: MouseEvent;
  readonly ctx: {readonly row: unknown; readonly idx: number} & Record<
    string,
    unknown
  >;
}

interface TableRowSelectEvent {
  readonly tag: TableUIEvent.ROW_SELECTION;
  readonly evt: CustomEvent<number>;
  readonly ctx: {readonly row: unknown; readonly idx: number} & Record<
    string,
    unknown
  >;
}

export interface TableRowCellClickEvent {
  readonly tag: TableUIEvent.CELL_CLICK;
  readonly evt: MouseEvent;
  readonly ctx: {
    readonly row: unknown;
    readonly rowIdx: number;
    readonly col: TableColumn;
    readonly colIdx: number;
  } & Record<string, unknown>;
}

type Msg = TableRowClickEvent | TableRowCellClickEvent | TableRowSelectEvent;

export interface TableColumn<T = unknown> {
  readonly key?: string;
  readonly label: string;
  readonly width?: number | string;
  readonly align?: Alignment;
  readonly color?: string;
  readonly weight?: number | string;
  readonly headerCellMarkup?: TableHeaderCellContentRender;
  readonly rowCellMarkup?: TableRowCellContentRender<T>;
}

/** Signature of a custom rendering function used for table header cell  */
export interface TableHeaderCellContentRender {
  (col: TableColumn, idx: number, ...args: unknown[]): TemplateResult;
}

/** Signature of a custom rendering function used for table row cell  */
export interface TableRowCellContentRender<T = unknown> {
  (
    row: T,
    rowIdx: number,
    col: TableColumn<T>,
    colIdx: number,
    ...args: unknown[]
  ): TemplateResult;
}

export interface TableEmptyStateSpec {
  description: string;
  icon?: string;
  svgIcon?: boolean;
  svgIconSize?: string | number;
  size?: string;
  fill?: string;
}

/**
 * Table -- Description here
 */
@customElement(COMPONENT_TAG)
export default class RoadTable extends RoadComponent {
  @query('table')
  $table!: HTMLTableElement;

  @property({type: Boolean})
  multiselect = false;

  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @property({type: Array})
  columns: TableColumn[] = [];

  @property({type: Array})
  rows: unknown[][] = [];

  @property({type: Array, reflect: true})
  selected: number[] = [];

  @property({type: Array})
  columnFilter: string[] = [];

  @property({type: Object})
  headerCellMarkups: Record<string, TableHeaderCellContentRender> = {};

  @property({type: Object})
  rowCellMarkups: Record<string, TableRowCellContentRender> = {};

  @property({type: Boolean})
  hideControls = false;

  @property({type: Object})
  emptyState: TableEmptyStateSpec = DEFAULT_EMPTY_STATE;

  @state()
  protected get _columns() {
    const columnFilter = new Set(this.columnFilter);
    return Array.isArray(this.columns)
      ? this.columns.filter(
          c =>
            isPlainObject(c) &&
            isNonEmptyString(c.label) &&
            (columnFilter.has(c.label) || columnFilter.has(c.key as string))
        )
      : [];
  }

  @state()
  protected get _selected() {
    return Array.isArray(this.selected)
      ? new Set<number>(this.selected)
      : new Set<number>();
  }

  static get styles() {
    return [styles];
  }

  renderCell(
    row: unknown[],
    i: number,
    col: TableColumn,
    j: number,
    ...args: unknown[]
  ) {
    if (!Array.isArray(row) || row.length <= j) {
      return html`
        <td
          data-table=${this.key}
          data-col-idx=${j}
          data-row-idx=${i}
          @click=${(evt: MouseEvent) =>
            this.actionHandler({
              tag: TableUIEvent.CELL_CLICK,
              evt,
              ctx: {row, rowIdx: i, col, colIdx: j},
            })}
        ></td>
      `;
    }

    const classenames = {
      'road-table__cell': true,
      'road-table__cell--centered': col.align === Alignment.CENTER,
      'road-table__cell--right-aligned': col.align === Alignment.RIGHT,
    };

    const rowCellMarkup = col.rowCellMarkup
      ? col.rowCellMarkup
      : this.rowCellMarkups[col.key || col.label];

    return html`
      <td data-table=${this.key} data-col-idx=${j} data-row-idx=${i}>
        <div
          class=${classMap(classenames)}
          @click=${(evt: MouseEvent) =>
            this.actionHandler({
              tag: TableUIEvent.CELL_CLICK,
              evt,
              ctx: {row, rowIdx: i, col, colIdx: j},
            })}
        >
          ${rowCellMarkup
            ? rowCellMarkup(row, i, col, j, ...args)
            : html`${row[j]}`}
        </div>
      </td>
    `;
  }

  renderColumn(col: TableColumn, idx: number, ...args: unknown[]) {
    const width = (someval: unknown): string => {
      if (isNonEmptyString(someval)) return someval.trim();
      if (typeof someval === 'number' && !isNaN(someval)) return `${someval}px`;
      return 'auto';
    };

    const fontweight = (someval: unknown): string => {
      if (isNonEmptyString(someval)) return someval.trim();
      if (typeof someval === 'number' && !isNaN(someval)) return `${someval}`;
      return 'normal';
    };

    const color = (someval: unknown) => {
      if (!isNonEmptyString(someval))
        return 'var(--road-table-header-color, var(--road-table-color, inherit))';

      return `var(--road-color-${someval}, var(--road-table-header-color, var(--road-table-color, ${someval}, inherit)))`;
    };

    const styles = {
      width: width(col.width),
      'min-width': 'var(--road-table-col-min-width)',
      weight: fontweight(col.weight),
      color: color(col.color),
    };

    const classenames = {
      'road-table__column': true,
      'road-table__cell': true,
      'road-table__cell--centered': col.align === Alignment.CENTER,
      'road-table__cell--right-aligned': col.align === Alignment.RIGHT,
    };

    const headerCellMarkup = col.headerCellMarkup
      ? col.headerCellMarkup
      : this.headerCellMarkups[col.key || col.label];

    return html`
      <th style=${styleMap(styles)} data-table=${this.key} data-col-idx=${idx}>
        <div class=${classMap(classenames)}>
          ${headerCellMarkup
            ? headerCellMarkup(col, idx, ...args)
            : html`${col.label}`}
        </div>
      </th>
    `;
  }

  renderEmptyState() {
    if (this.rows.length) return html``;
    const emptyState = {...DEFAULT_EMPTY_STATE, ...this.emptyState};
    return html`
      <div class="empty-container">
        <div class="road-table--empty">
          <div class="road-table--empty__icon">
            <road-icon
              icon=${emptyState.icon}
              size=${emptyState.size}
              ?svg=${emptyState.svgIcon}
              svgIconSize=${emptyState.svgIconSize}
            ></road-icon>
          </div>
          <div class="road-table--empty__description">
            ${emptyState.description}
          </div>
        </div>
      </div>
    `;
  }

  actionHandler(msg: Msg) {
    const dispatchEvent = (payload: {
      type: TableUIEvent;
      data: Record<string, unknown>;
    }) => {
      this.dispatchEvent(
        new CustomEvent(payload.type, {
          detail: {
            ...payload.data,
          },
        })
      );

      window.dispatchEvent(
        new CustomEvent(COMPONENT_TAG, {
          detail: {
            tag: payload.type,
            key: this.key,
            ...payload.data,
          },
        })
      );
    };

    switch (msg.tag) {
      case TableUIEvent.ROW_CLICK:
      case TableUIEvent.CELL_CLICK: {
        dispatchEvent({
          type: msg.tag,
          data: {
            selected: this.selected,
            ...msg.ctx,
          },
        });
        return;
      }

      case TableUIEvent.ROW_SELECTION: {
        if (this._selected.has(msg.ctx.idx)) {
          this.selected = this.selected.filter(idx => idx !== msg.ctx.idx);
        } else if (this.multiselect) {
          this.selected.push(msg.ctx.idx);
          this.selected.sort();
        } else {
          this.selected = [msg.ctx.idx];
        }

        dispatchEvent({
          type: msg.tag,
          data: {
            selected: this.selected,
            ...msg.ctx,
          },
        });
        break;
      }

      default: {
        console.warn(
          '[road-table] invalid msg argument passed to action handler'
        );
        return;
      }
    }
  }

  render() {
    return html`
      <div class="road-table-container">
        ${!this.hideControls
          ? html`
              <div
                class="road-table-container__controls
                road-table-container__controls--padded"
              >
                <slot name="controls-top"></slot>
              </div>
            `
          : ''}
        <div class="road-table">
          <table>
            <thead>
              <tr class="road-table__columns">
                ${repeat(
                  this._columns,
                  (_, i: number) => {
                    return `repeatkey:road-table-${this.key}-col-${i}`;
                  },
                  this.renderColumn.bind(this)
                )}
              </tr>
            </thead>
            <tbody>
              ${repeat(
                this.rows,
                (_, i: number) => `repeatkey:road-table-${this.key}-row-${i}`,
                (row: unknown[], i: number) => {
                  return html`
                    <tr
                      @click=${(evt: MouseEvent) => {
                        this.actionHandler({
                          tag: TableUIEvent.ROW_CLICK,
                          evt,
                          ctx: {row, idx: i},
                        });
                      }}
                      class="road-table__row"
                      data-row-idx=${i}
                    >
                      ${repeat(
                        this._columns,
                        (_, j: number) =>
                          `repeatkey:road-table-${this.key}-table-cell-${i}-${j}`,
                        (col: TableColumn, j: number) => {
                          return this.renderCell(row, i, col, j);
                        }
                      )}
                    </tr>
                  `;
                }
              )}
            </tbody>
          </table>
          ${this.renderEmptyState()}
        </div>
        ${!this.hideControls
          ? html`
              <div
                class="road-table-container__controls
                road-table-container__controls--padded"
              >
                <slot name="controls-bottom"></slot>
              </div>
            `
          : ''}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-table': RoadTable;
  }
}
