import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {ref} from 'lit/directives/ref.js';

import {RoadComponent} from '../../../lib/component';

import {isNonEmptyString} from '../../../utils';

import styles from './style.scss';

export const COMPONENT_TAG = 'road-checkbox';

export interface CustomSelectEvent {
  readonly value: string;
  readonly label: string;
  readonly key: string;
  readonly disabled: boolean;
  readonly checked: boolean;
  readonly required: boolean;
  readonly invalid: boolean;
  readonly readonly: boolean;
  readonly name: string;
  readonly $node: HTMLInputElement;
}

export enum CUSTOM_EVENT {
  CHANGE = 'change',
  BLUR = 'blur',
  FOCUS = 'focus',
}

@customElement(COMPONENT_TAG)
export default class RoadCheckbox extends RoadComponent {
  @property()
  key: string = `${new Date().getTime()}-${Math.random()}`;

  @property()
  value: string = '';

  @property()
  label: string = '';

  @property()
  name: string = '';

  @property()
  icon: string = '';

  @property({type: Boolean, reflect: true})
  checked: boolean = false;

  @property({type: Boolean, reflect: true})
  readonly: boolean = false;

  @property({type: Boolean, reflect: true})
  required: boolean = false;

  @property({type: Boolean, reflect: true})
  disabled: boolean = false;

  @property({type: Boolean, reflect: true})
  invalid: boolean = false;

  $node!: HTMLInputElement;

  static get styles() {
    return [styles];
  }

  private dispatchChangeEvent() {
    const evt = {
      key: this.key,
      name: this.name,
      value: this.value,
      label: this.label,
      checked: this.checked,
      disabled: this.disabled,
      required: this.required,
      readonly: this.readonly,
      invalid: this.invalid,
      $node: this.$node,
    };

    this.dispatchEvent(
      new CustomEvent<CustomSelectEvent>(CUSTOM_EVENT.CHANGE, {
        detail: evt,
      })
    );

    window.dispatchEvent(
      new CustomEvent<CustomSelectEvent>(
        `${COMPONENT_TAG}:${CUSTOM_EVENT.CHANGE}`,
        {
          detail: evt,
        }
      )
    );
  }

  select() {
    this.checked = true;
    this.$node.checked = true;
    this.requestUpdate();
  }

  deselect() {
    this.checked = false;
    this.$node.checked = false;
    this.requestUpdate();
  }

  updated(changedProperties: Map<string, unknown>) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName !== 'checked') return;
      if (oldValue === this.checked) return;
      this.dispatchChangeEvent();
    });
  }

  handleClick() {
    if (this.readonly || this.disabled) return;
    this.checked = !this.checked;
    // if (this.$node) this.$node.blur();
    // this.dispatchChangeEvent();
  }

  labelMarkup() {
    if (!isNonEmptyString(this.label)) return html``;

    return html`
      <label
        class="road-checkbox__label"
        for="road-checkbox-${this.key}-input"
        id="road-checkbox-${this.key}-label"
        @click=${() => this.handleClick()}
        @focus=${() => this.dispatchEvent(new CustomEvent(CUSTOM_EVENT.FOCUS))}
        @blur=${() => this.dispatchEvent(new CustomEvent(CUSTOM_EVENT.BLUR))}
        ?checked=${this.checked}
        ?disabled=${this.disabled}
        ?readonly=${this.readonly}
        ?invalid=${this.invalid}
      >
        <div class="road-checkbox__label__content">
          ${this.renderIcon()} ${this.label}
        </div>
      </label>
    `;
  }

  renderIcon() {
    if (!isNonEmptyString(this.icon)) return html``;

    return html`
      <road-icon
        class="road-checkbox__icon"
        icon="${this.icon}"
      </road-icon>
    `;
  }

  inputMarkup() {
    const classes = {
      'road-checkbox__input': true,
      'road-checkbox__input--invalid': this.invalid,
      'road-checkbox__input--disabled': this.disabled,
      'road-checkbox__input--readonly': this.readonly,
      'road-checkbox__input--checked': this.checked,
    };

    return html`
      <input
        ${ref(this.registerInputNode.bind(this))}
        aria-label=${this.label}
        @click=${() => this.handleClick()}
        class=${classMap(classes)}
        type="checkbox"
        name=${this.name}
        ?required=${this.required}
        @focus=${() => this.dispatchEvent(new CustomEvent(CUSTOM_EVENT.FOCUS))}
        @blur=${() => this.dispatchEvent(new CustomEvent(CUSTOM_EVENT.BLUR))}
        ?checked=${this.checked}
        ?disabled=${this.disabled}
        ?readonly=${this.readonly}
        ?invalid=${this.invalid}
        value=${this.value}
        id="road-checkbox-${this.key}-input"
      />
    `;
  }

  registerInputNode($node: Element | undefined) {
    if (!$node) return;
    this.$node = $node as HTMLInputElement;
  }

  render() {
    return html`
      <div id="road-checkbox-${this.key}" class="road-checkbox">
        ${this.inputMarkup()} ${this.labelMarkup()}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-checkbox': RoadCheckbox;
  }
}
