import {Currency} from './currency';
import {User} from './user';

export interface Account {
  id: number;
  name: string;
  balance: number;
  favorite: number;
  icon: string;
  status: number;
  created_at: string;
  updated_at: string;
  account_category: AccountCategory;
  currency: Currency;
  user: User;
}

export interface AccountCategory {
  id: number;
  name: string;
  description: string;
  chapter_name: string;
}

export const accountCategoryIcons = new Map<string, string>([
  ['Cash', 'account_balance_wallet'],
  ['Credit card', 'credit_card'],
  ['Bank account', 'account_balance'],
  ['Investment', 'trending_up'],
  ['Loan', 'money'],
  ['Other asset', 'question_mark'],
  ['Property', 'house'],
  ['Vehicle', 'directions_car'],
  ['Crypto', 'currency_bitcoin'],
  ['Wallet', 'phone_android'],
]);
