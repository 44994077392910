import {html} from 'lit';
import {property, customElement, queryAll} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../../lib/component';

import {RoadContext} from '../../context';

import styles from './style.scss';

import {UserMovementCategory, UserMovementSubCategory} from '../../../../types/movement_category';
import RoadCheckbox from 'src/components/base/checkbox';

export enum MovementCategoryFiltersEvents {
  MOVEMENT_CATEGORIES_CHANGED = 'filters-movementCategories-changed',
  MOVEMENT_SUB_CATEGORIES_CHANGED = 'filters-movementSubCategories-changed',
}

@customElement('road-movement-category-filters')
export class RoadMovementCategoryFilters extends RoadComponent {
  private context = new RoadContext(this);

  @property({type: Object})
  selectedMovementCategories = new Set<UserMovementCategory>();

  @property({type: Object})
  selectedMovementSubCategories = new Set<UserMovementSubCategory>();

  @queryAll('road-checkbox[name="movementCategories"]') catCheckboxes!: RoadCheckbox[];
  @queryAll('road-checkbox[name="movementSubCategories"]') subCatCheckboxes!: RoadCheckbox[];

  static get styles() {
    return [styles];
  }

  findMovementSubCategory(id: Number): UserMovementSubCategory | void {
    let subCat: UserMovementSubCategory | void = undefined;

    let found = false;
    this.context.userMovementCategories.forEach((mc: UserMovementCategory) => {
      mc.user_movement_sub_categories.forEach((msc: UserMovementSubCategory) => {
        if (msc.id === id) {
          found = true;
          subCat = msc;
        }
      });
      if (found) return;
    });

    return subCat;
  }

  resetFilters() {
    this.selectedMovementCategories = new Set<UserMovementCategory>();
    this.selectedMovementSubCategories = new Set<UserMovementSubCategory>();
    this.catCheckboxes.forEach(checkbox => checkbox.deselect());
    this.subCatCheckboxes.forEach(checkbox => checkbox.deselect());
    this.requestUpdate();
  }

  handleMovementCategoryChange(evt: CustomEvent): void {
    const userMovementCategory = this.context.userMovementCategories.find((mc: UserMovementCategory) => mc.id === Number(evt.detail.value));
    if (!userMovementCategory) return;

    const subCategories = userMovementCategory.user_movement_sub_categories;
    const subCategoryIds = subCategories.map((subCategory: UserMovementSubCategory) => subCategory.id);

    if (evt.detail.checked) {
      this.selectedMovementCategories.add(userMovementCategory);
      subCategories.forEach((subCategory: UserMovementSubCategory) => {
        this.selectedMovementSubCategories.add(subCategory);
        this.subCatCheckboxes.forEach(checkbox => { if (checkbox.value === String(subCategory.id)) checkbox.select(); })
      });
    } else {
      this.selectedMovementCategories.delete(userMovementCategory);
      subCategories.forEach((subCategory: UserMovementSubCategory) => this.selectedMovementSubCategories.delete(subCategory));
      this.subCatCheckboxes.forEach(checkbox => {
        if (subCategoryIds.includes(Number(checkbox.value))) {
          if (checkbox.checked) checkbox.deselect();
        }
      })
    }

    this.dispatchMovementSubCategoriesChangedEvent();
  }

  handleMovementSubCategoryChange(evt: CustomEvent): void {
    const userMovementSubCategory = this.findMovementSubCategory(Number(evt.detail.value));
    if (!userMovementSubCategory) return;

    if (evt.detail.checked) {
      this.selectedMovementSubCategories.add(userMovementSubCategory);
    } else {
      this.selectedMovementSubCategories.delete(userMovementSubCategory);
    }

    this.dispatchMovementSubCategoriesChangedEvent();
  }

  dispatchMovementSubCategoriesChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent(MovementCategoryFiltersEvents.MOVEMENT_SUB_CATEGORIES_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: Array.from(this.selectedMovementSubCategories),
        },
      })
    );
  }

  renderMovementSubCategories(userMovementCategory: UserMovementCategory) {
    return html`<div class="road-movement-category-filters__checks-container">
      ${repeat(
        userMovementCategory.user_movement_sub_categories,
        (userMovementSubCategory: UserMovementSubCategory) => `movement-sub-category-filter-${userMovementSubCategory.id}`,
        (userMovementSubCategory: UserMovementSubCategory) => {
          return html`<road-checkbox
              name="movementSubCategories"
              value="${userMovementSubCategory.id}"
              label="${userMovementSubCategory.name}"
              ?checked="${this.selectedMovementSubCategories.has(userMovementSubCategory)}"
              @change="${(evt: CustomEvent) => {
                this.handleMovementSubCategoryChange(evt);
              }}"
            >
              ${userMovementSubCategory.name}
            </road-checkbox>
          </div>`;
        }
      )}
    </div>`;
  }

  renderMovementCategory(userMovementCategory: UserMovementCategory) {
    return html`<div class="road-movement-category-filters__category-container">
      <road-checkbox
        name="movementCategories"
        value="${userMovementCategory.id}"
        label="${userMovementCategory.name}"
        ?checked="${this.selectedMovementCategories.has(userMovementCategory)}"
        @change="${(evt: CustomEvent) => {
          this.handleMovementCategoryChange(evt);
        }}"
      >
        ${userMovementCategory.name}
      </road-checkbox>
      ${this.renderMovementSubCategories(userMovementCategory)}
    </div>`;
  }

  render() {
    return html`
        <div class="road-movement-category-filters__filter">
          <label for="movementCategories">Categories</label>
          ${repeat(
            this.context.userMovementCategories,
            (userMovementCategory: UserMovementCategory) => `movement-category-filter-${userMovementCategory.id}`,
            (userMovementCategory: UserMovementCategory) => this.renderMovementCategory(userMovementCategory)
          )}
        </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-movement-category-filters': RoadMovementCategoryFilters;
  }
}
