import {html} from 'lit';
import {property, customElement, state, query} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import {RoadComponent} from '../../../../lib/component';

import {Payee} from '../../../../types/payee';
import {loadPayees, deletePayee} from '../../../../services/payees';

import styles from './style.scss';

@customElement('road-payees-list')
export class RoadPayeesList extends RoadComponent {
  @property({type: Array})
  payees: Payee[] = [];

  @state()
  loading = false;

  @state()
  showPayee = false;

  @state()
  confirmDelete = false;

  @property({type: Object})
  selectedPayee?: Payee

  static get styles() {
    return [styles];
  }

  setInitialProperties() {
    this.payees = [];
    this.loading = false;
  }

  async load() {
    this.setInitialProperties();
    this.loadPayees();
  }

  async loadPayees() {
    if (this.loading) return;

    this.loading = true;
    const response = await loadPayees();
    this.loading = false;

    if (!response.ok) return alert(response?.errors?.join('\n'));

    this.payees = response.payees!;
    this.requestUpdate();
  }

  deleteConfirmation() {
    if (this.selectedPayee === undefined) return;

    const okLabel = 'Delete';
    const cancelLabel = 'Cancel';

    return html`
      <road-modal
        ?open=${this.confirmDelete}
        ?actions=${true}
        label="Confirm delete"
        .okLabel=${okLabel}
        .cancelLabel=${cancelLabel}
        @closed=${() => this.confirmDelete = false}
        @cancel=${() => this.confirmDelete = false}
        @ok=${() => this.delete(this.selectedPayee!)}
      >
        Are you sure you want to delete payee ${this.selectedPayee.name}?
      </road-modal>`;
  }

  async delete(payee: Payee) {
    const response = await deletePayee(payee)

    if (!response.ok) return alert(response?.errors?.join('\n'))

    const index = this.payees.findIndex(m => m.id === payee.id)

    if (index >= 0) {
      this.payees.splice(index, 1);
      this.selectedPayee = undefined;
    }

    this.requestUpdate();
  }

  renderPayees() {
    return html`<div class="payees-index">
      <div class="payees-container">
        ${repeat(
          this.payees,
          (payee: Payee) => payee.id,
          (payee: Payee) => this.renderPayee(payee),
        )}
      </div></div>
    `;
  }

  renderPayee(payee: Payee) {
    return html`
      <div class="clickable"
        @click="${() => { this.showPayeeModal(payee);}}"
      >
        <road-tooltip content="Delete this payee">
          <road-icon
            icon="delete_forever"
            fill="primary--dark"
            @click=${(e: CustomEvent) => {
              e.stopPropagation();
              e.preventDefault();
              this.selectedPayee = payee;
              this.confirmDelete = true;
            }}
          >
          </road-icon>
        </road-tooltip>
        ${payee.name}
      </div>`;
}

  renderPayeeModal() {
    if (this.selectedPayee) {
      return html`
        <road-payee
          ?open=${this.showPayee}
          .payee=${this.selectedPayee}
          .payees=${this.payees}
          @closed=${this.hidePayeeModal}
          @cancel=${this.hidePayeeModal}
          @confirm=${this.applyPayeeModal}
        >
        </road-payee>
      `;
    }

    return html`
        <road-payee
          ?open=${this.showPayee}
          .payees=${this.payees}
          @closed=${this.hidePayeeModal}
          @cancel=${this.hidePayeeModal}
          @confirm=${this.applyPayeeModal}
        >
        </road-payee>
    `;
  }

  private showPayeeModal(payee: Payee | undefined = undefined) {
    this.selectedPayee = payee;
    this.showPayee = true;
    this.requestUpdate();
  }

  private hidePayeeModal() {
    this.selectedPayee = undefined;
    this.showPayee = false;
    this.requestUpdate();
  }

  private applyPayeeModal(event: CustomEvent) {
    this.selectedPayee = undefined;
    this.showPayee = false;
    if (!event.detail) return;

    if (event.detail.created) {
      this.payees.unshift(event.detail.payee);
    } else if (event.detail.updated) {
      const index = this.payees.findIndex(m => m.id === event.detail.payee.id)
      if (index >= 0) this.payees[index] = event.detail.payee
    }

    this.requestUpdate();
  }

  render() {
    if (this.loading && !this.payees.length) {
      return html`<road-loader loading></road-loader>`;
    }

    return html`
      ${this.renderPayees()}
      ${this.renderPayeeModal()}
      ${this.deleteConfirmation()}
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-payees-list': RoadPayeesList;
  }
}
