import {ReactiveController, ReactiveControllerHost} from 'lit';

import {Tag} from '../../../types/movement';
import {Account, AccountCategory} from '../../../types/account';
import {UserMovementCategory} from '../../../types/movement_category';
import {Currency} from '../../../types/currency';
import {FamilyMember} from '../../../types/family_member';
import {Payee} from '../../../types/payee';

import {
  loadTags,
  loadUserMovementCategories,
  loadFamilyMembers,
} from '../../../services/movements';

import {
  loadAccounts,
  loadCurrencies,
  loadAccountCategories,
} from '../../../services/accounts';

import {
  loadPayees,
} from '../../../services/payees';

enum Context {
  ACCOUNTS = 'accounts',
  ACCOUNT_CATEGORIES = 'accountCategories',
  CURRENCIES = 'currencies',
  PAYEES = 'payees',
  USER_MOVEMENT_CATEGORIES = 'userMovementCategories',
  FAMILY_MEMBERS = 'familyMembers',
  TAGS = 'tags',
}

enum LoadingStates {
  INITIAL = 'initial',
  LOADING = 'loading',
  LOADED ='loaded',
}

export class RoadContext implements ReactiveController {
  private host: ReactiveControllerHost;

  private static contextStates = new Map<Context, Set<ReactiveControllerHost>>(
    [
      [Context.ACCOUNTS, new Set()],
      [Context.ACCOUNT_CATEGORIES, new Set()],
      [Context.CURRENCIES, new Set()],
      [Context.PAYEES, new Set()],
      [Context.USER_MOVEMENT_CATEGORIES, new Set()],
      [Context.FAMILY_MEMBERS, new Set()],
      [Context.TAGS, new Set()],
    ]
  );

  private static accounts: Account[] = [];
  private static accountCategories: AccountCategory[] = [];
  private static currencies: Currency[] = [];
  private static payees: Payee[] = [];
  private static userMovementCategories: UserMovementCategory[] = [];
  private static familyMembers: FamilyMember[] = [];
  private static tags: Tag[] = [];

  private static loadingStates = {
    [Context.ACCOUNTS]: LoadingStates.INITIAL,
    [Context.ACCOUNT_CATEGORIES]: LoadingStates.INITIAL,
    [Context.CURRENCIES]: LoadingStates.INITIAL,
    [Context.PAYEES]: LoadingStates.INITIAL,
    [Context.USER_MOVEMENT_CATEGORIES]: LoadingStates.INITIAL,
    [Context.FAMILY_MEMBERS]: LoadingStates.INITIAL,
    [Context.TAGS]: LoadingStates.INITIAL,
  };

  constructor(host: ReactiveControllerHost) {
    this.host = host;
    host.addController(this);
  }

  get accounts() {
    this.loadAccountsIfNeeded();
    return RoadContext.accounts;
  }

  get accountCategories() {
    this.loadAccountCategoriesIfNeeded();
    return RoadContext.accountCategories;
  }

  get currencies() {
    this.loadCurrenciesIfNeeded();
    return RoadContext.currencies;
  }

  get payees() {
    this.loadPayeesIfNeeded();
    return RoadContext.payees;
  }

  get userMovementCategories() {
    this.loadUserMovementCategoriesIfNeeded();
    return RoadContext.userMovementCategories;
  }

  get familyMembers() {
    this.loadFamilyMembersIfNeeded();
    return RoadContext.familyMembers;
  }

  get tags() {
    this.loadTagsIfNeeded();
    return RoadContext.tags;
  }

  private async loadAccountsIfNeeded() {
    RoadContext.contextStates.get(Context.ACCOUNTS)!.add(this.host);
    if (RoadContext.loadingStates[Context.ACCOUNTS] !== LoadingStates.INITIAL) return;

    RoadContext.loadingStates[Context.ACCOUNTS] = LoadingStates.LOADING;
    const response = await loadAccounts();
    RoadContext.loadingStates[Context.ACCOUNTS] = LoadingStates.LOADED;

    if (!response.ok) return;

    RoadContext.accounts = response.accounts || [];
    RoadContext.contextStates.get(Context.ACCOUNTS)!.forEach((host) => host.requestUpdate());
    //RoadContext.hosts.forEach((_entities, host) => host.requestUpdate());
  }

  private async loadAccountCategoriesIfNeeded() {
    RoadContext.contextStates.get(Context.ACCOUNT_CATEGORIES)!.add(this.host);
    if (RoadContext.loadingStates[Context.ACCOUNT_CATEGORIES] !== LoadingStates.INITIAL) return;

    RoadContext.loadingStates[Context.ACCOUNT_CATEGORIES] = LoadingStates.LOADING;
    const response = await loadAccountCategories();
    RoadContext.loadingStates[Context.ACCOUNT_CATEGORIES] = LoadingStates.LOADED;

    if (!response.ok) return;

    RoadContext.accountCategories = response.account_categories || [];
    RoadContext.contextStates.get(Context.ACCOUNT_CATEGORIES)!.forEach((host) => host.requestUpdate());
    //RoadContext.hosts.forEach((_entities, host) => host.requestUpdate());
  }

  private async loadCurrenciesIfNeeded() {
    RoadContext.contextStates.get(Context.CURRENCIES)!.add(this.host);
    if (RoadContext.loadingStates[Context.CURRENCIES] !== LoadingStates.INITIAL) return;

    RoadContext.loadingStates[Context.CURRENCIES] = LoadingStates.LOADING;
    const response = await loadCurrencies();
    RoadContext.loadingStates[Context.CURRENCIES] = LoadingStates.LOADED;

    if (!response.ok) return;

    RoadContext.currencies = response.currencies || [];
    RoadContext.contextStates.get(Context.CURRENCIES)!.forEach((host) => host.requestUpdate());
    // RoadContext.hosts.forEach((_entities, host) => host.requestUpdate());
  }

  private async loadPayeesIfNeeded() {
    RoadContext.contextStates.get(Context.PAYEES)!.add(this.host);
    if (RoadContext.loadingStates[Context.PAYEES] !== LoadingStates.INITIAL) return;

    RoadContext.loadingStates[Context.PAYEES] = LoadingStates.LOADING;
    const response = await loadPayees();
    RoadContext.loadingStates[Context.PAYEES] = LoadingStates.LOADED;

    if (!response.ok) return;

    RoadContext.payees = response.payees || [];
    RoadContext.contextStates.get(Context.PAYEES)!.forEach((host) => host.requestUpdate());
    // RoadContext.hosts.forEach((_entities, host) => host.requestUpdate());
  }

  private async loadUserMovementCategoriesIfNeeded() {
    RoadContext.contextStates.get(Context.USER_MOVEMENT_CATEGORIES)!.add(this.host);
    if (RoadContext.loadingStates[Context.USER_MOVEMENT_CATEGORIES] !== LoadingStates.INITIAL) return;

    RoadContext.loadingStates[Context.USER_MOVEMENT_CATEGORIES] = LoadingStates.LOADING;
    const response = await loadUserMovementCategories();
    RoadContext.loadingStates[Context.USER_MOVEMENT_CATEGORIES] = LoadingStates.LOADED;

    if (!response.ok) return;

    RoadContext.userMovementCategories = response.user_movement_categories || [];
    RoadContext.contextStates.get(Context.USER_MOVEMENT_CATEGORIES)!.forEach((host) => host.requestUpdate());
    // RoadContext.hosts.forEach((_entities, host) => host.requestUpdate());
  }

  private async loadFamilyMembersIfNeeded() {
    RoadContext.contextStates.get(Context.FAMILY_MEMBERS)!.add(this.host);
    if (RoadContext.loadingStates[Context.FAMILY_MEMBERS] !== LoadingStates.INITIAL) return;

    RoadContext.loadingStates[Context.FAMILY_MEMBERS] = LoadingStates.LOADING;
    const response = await loadFamilyMembers();
    RoadContext.loadingStates[Context.FAMILY_MEMBERS] = LoadingStates.LOADED;

    if (!response.ok) return;

    RoadContext.familyMembers = response.family_members || [];
    RoadContext.contextStates.get(Context.FAMILY_MEMBERS)!.forEach((host) => host.requestUpdate());
    // RoadContext.hosts.forEach((_entities, host) => host.requestUpdate());
  }

  private async loadTagsIfNeeded() {
    RoadContext.contextStates.get(Context.TAGS)!.add(this.host);
    if (RoadContext.loadingStates[Context.TAGS] !== LoadingStates.INITIAL) return;

    RoadContext.loadingStates[Context.TAGS] = LoadingStates.LOADING;
    const response = await loadTags();
    RoadContext.loadingStates[Context.TAGS] = LoadingStates.LOADED;

    if (!response.ok) return;

    RoadContext.tags = response.tags || [];
    RoadContext.contextStates.get(Context.TAGS)!.forEach((host) => host.requestUpdate());
    // RoadContext.hosts.forEach((_entities, host) => host.requestUpdate());
  }

  hostConnected() {
  }

  hostDisconnected() {
  }

  hostUpdate() {
  }
}
