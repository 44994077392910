import {DateFiltersEvents, RoadDateFilters} from './date_filters/index';
import {CurrencyFiltersEvents, RoadCurrencyFilters} from './currency_filters/index';
import {AmountFiltersEvents, RoadAmountFilters} from './amount_filters/index';
import {AccountFiltersEvents, RoadAccountFilters} from './account_filters/index';
import {AccountCategoryFiltersEvents, RoadAccountCategoryFilters} from './account_category_filters/index';
import {FamilyMemberFiltersEvents, RoadFamilyMemberFilters} from './family_member_filters/index';
import {TagFiltersEvents, RoadTagFilters} from './tag_filters/index';
import {PayeeFiltersEvents, RoadPayeeFilters} from './payee_filters/index';
import {MovementCategoryFiltersEvents, RoadMovementCategoryFilters} from './movement_category_filters/index';
import {MovementStatusFiltersEvents, RoadMovementStatusFilters} from './movement_status_filters/index';
import {TextFiltersEvents, RoadTextFilters} from './text_filters/index';

import {html, TemplateResult} from 'lit';
import {property, customElement, query} from 'lit/decorators.js';

import {RoadComponent} from '../../../lib/component';

import {Account, AccountCategory} from '../../../types/account';
import {Currency} from '../../../types/currency';
import {FamilyMember} from '../../../types/family_member';
import {MovementStatus, Tag} from '../../../types/movement';
import {UserMovementSubCategory} from 'src/types/movement_category';
import {Payee} from '../../../types/payee';

import styles from './style.scss';

export enum FilterTabsEnum {
  DATE = 'date-filter',
  TEXT = 'text-filter',
  STATUS = 'status-filter',
  CURRENCY = 'currency-filter',
  ACCOUNT = 'account-filter',
  TAG = 'tag-filter',
  ACCOUNT_CATEGORY = 'account-category-filter',
  MOVEMENT_CATEGORY = 'movement-category-filter',
  FAMILY_MEMBER = 'family-member-filter',
  PAYEE = 'payee-filter',
  AMOUNT = 'amount-filter',
}

@customElement('road-filters')
export class RoadFilters extends RoadComponent {
  @property({type: Boolean})
  open = false;

  @property({type: String})
  selectedTab: FilterTabsEnum = FilterTabsEnum.DATE;

  @property({type: Array})
  selectedCurrencies: Currency[] = [];

  @property({type: Array})
  selectedAccounts: Account[] = [];

  @property({type: Array})
  selectedPayees: Payee[] = [];

  @property({type: Array})
  selectedAccountCategories: AccountCategory[] = [];

  @property({type: Array})
  selectedFamilyMembers: FamilyMember[] = [];

  @property({type: Array})
  selectedMovementStatuses: MovementStatus[] = [];

  @property({type: Array})
  selectedTags: Tag[] = [];

  @property({type: Array})
  selectedUserMovementSubCategories: UserMovementSubCategory[] = [];

  @property({attribute: false})
  from?: Date;

  @property({attribute: false})
  to?: Date;

  @property({type: String})
  useFiscalDate = '1';

  @property({type: Number})
  min?: number;

  @property({type: Number})
  max?: number;

  @property({type: String})
  text?: string;

  @query('road-movement-category-filters') movementCategoriesFilters!: RoadMovementCategoryFilters;
  @query('road-account-category-filters')  accountCategoriesFilters!: RoadAccountCategoryFilters;
  @query('road-family-member-filters')     familyMembersFilters!: RoadFamilyMemberFilters;
  @query('road-currency-filters')          currenciesFilters!: RoadCurrencyFilters;
  @query('road-account-filters')           accountsFilters!: RoadAccountFilters;
  @query('road-amount-filters')            amountsFilters!: RoadAmountFilters;
  @query('road-payee-filters')             payeesFilters!: RoadPayeeFilters;
  @query('road-date-filters')              datesFilters!: RoadDateFilters;
  @query('road-tag-filters')               tagsFilters!: RoadTagFilters;
  @query('road-movement-status-filters')   movementStatusFilters!: RoadMovementStatusFilters;
  @query('road-text-filters')              textFilters!: RoadTextFilters;

  static get styles() {
    return [styles];
  }

  constructor() {
    super();
    this.addEventListener(DateFiltersEvents.FROM_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(DateFiltersEvents.TO_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(DateFiltersEvents.FISCAL_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(AmountFiltersEvents.MIN_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(AmountFiltersEvents.MAX_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(CurrencyFiltersEvents.CURRENCIES_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(AccountFiltersEvents.ACCOUNTS_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(PayeeFiltersEvents.PAYEES_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(AccountCategoryFiltersEvents.ACCOUNT_CATEGORIES_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(TagFiltersEvents.TAGS_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(MovementCategoryFiltersEvents.MOVEMENT_SUB_CATEGORIES_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(FamilyMemberFiltersEvents.FAMILY_MEMBERS_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(MovementStatusFiltersEvents.MOVEMENT_STATUS_CHANGED, this.handleFilterChange as EventListener);
    this.addEventListener(TextFiltersEvents.TEXT_CHANGED, this.handleFilterChange as EventListener);
  }

  private handleFilterChange: EventListener = (event: Event) => {
    const evt: CustomEvent = event as CustomEvent;

    switch (evt.type) {
      case MovementCategoryFiltersEvents.MOVEMENT_SUB_CATEGORIES_CHANGED: this.selectedUserMovementSubCategories = evt.detail.value; break;
      case AccountCategoryFiltersEvents.ACCOUNT_CATEGORIES_CHANGED:       this.selectedAccountCategories = evt.detail.value;         break;
      case FamilyMemberFiltersEvents.FAMILY_MEMBERS_CHANGED:              this.selectedFamilyMembers = evt.detail.value;             break;
      case CurrencyFiltersEvents.CURRENCIES_CHANGED:                      this.selectedCurrencies = evt.detail.value;                break;
      case AccountFiltersEvents.ACCOUNTS_CHANGED:                         this.selectedAccounts = evt.detail.value;                  break;
      case PayeeFiltersEvents.PAYEES_CHANGED:                             this.selectedPayees = evt.detail.value;                    break;
      case DateFiltersEvents.FISCAL_CHANGED:                              this.useFiscalDate = evt.detail.value;                     break;
      case AmountFiltersEvents.MIN_CHANGED:                               this.min = evt.detail.value;                               break;
      case AmountFiltersEvents.MAX_CHANGED:                               this.max = evt.detail.value;                               break;
      case DateFiltersEvents.FROM_CHANGED:                                this.from = evt.detail.value;                              break;
      case TagFiltersEvents.TAGS_CHANGED:                                 this.selectedTags = evt.detail.value;                      break;
      case DateFiltersEvents.TO_CHANGED:                                  this.to = evt.detail.value;                                break;
      case MovementStatusFiltersEvents.MOVEMENT_STATUS_CHANGED:           this.selectedMovementStatuses = evt.detail.value;          break;
      case TextFiltersEvents.TEXT_CHANGED:                                this.text = evt.detail.value;                              break;
    }
  }

  resetFilters() {
    this.from = undefined;
    this.to = undefined;
    this.min = undefined;
    this.max = undefined;
    this.useFiscalDate = '1';
    this.text = undefined;
    this.selectedCurrencies = [];
    this.selectedAccounts = [];
    this.selectedPayees = [];
    this.selectedAccountCategories = [];
    this.selectedTags = [];
    this.selectedUserMovementSubCategories = [];
    this.selectedFamilyMembers = [];
    this.selectedMovementStatuses = [];

    this.datesFilters.resetFilters();
    this.currenciesFilters.resetFilters();
    this.accountsFilters.resetFilters();
    this.amountsFilters.resetFilters();
    this.payeesFilters.resetFilters();
    this.accountCategoriesFilters.resetFilters();
    this.tagsFilters.resetFilters();
    this.movementCategoriesFilters.resetFilters();
    this.movementStatusFilters.resetFilters();
    this.familyMembersFilters.resetFilters();
    this.textFilters.resetFilters();

    this.requestUpdate();
  }

  renderTab(tab: FilterTabsEnum, icon: string) {
    return html`
      <road-tab
        slot="nav"
        id="filters-${tab}"
        panel="${tab}"
        ?active=${this.selectedTab === tab}
      >
        <road-icon icon="${icon}"></road-icon>
      </road-tab>
    `;
  }

  renderTabs(selectedTab: FilterTabsEnum) {
    return html`
      ${this.renderTab(FilterTabsEnum.DATE, 'calendar_month')}
      ${this.renderTab(FilterTabsEnum.TEXT, 'comment')}
      ${this.renderTab(FilterTabsEnum.STATUS, 'check_circle')}
      ${this.renderTab(FilterTabsEnum.AMOUNT, 'money')}
      ${this.renderTab(FilterTabsEnum.ACCOUNT, 'account_balance_wallet')}
      ${this.renderTab(FilterTabsEnum.PAYEE, 'storefront')}
      ${this.renderTab(FilterTabsEnum.CURRENCY, 'euro_symbol')}
      ${this.renderTab(FilterTabsEnum.ACCOUNT_CATEGORY, 'category')}
      ${this.renderTab(FilterTabsEnum.FAMILY_MEMBER, 'family_restroom')}
      ${this.renderTab(FilterTabsEnum.TAG, 'sell')}
      ${this.renderTab(FilterTabsEnum.MOVEMENT_CATEGORY, 'account_tree')}
    `;
  }

  renderPanel(panel: FilterTabsEnum, template: TemplateResult) {
    return html`
      <road-tab-panel class="tab-panel" id="panel-${panel}" name="${panel}">
        <div class="filters-panel">
          ${template}
        </div>
      </road-tab-panel>
    `;
  }

  renderPanelDate() {
    return html`<road-date-filters></road-date-filters>`;
  }

  renderPanelText() {
    return html`<road-text-filters></road-text-filters>`;
  }

  renderPanelStatus() {
    return html`<road-movement-status-filters></road-movement-status-filters>`;
  }

  renderPanelAmount() {
    return html`<road-amount-filters></road-amount-filters>`;
  }

  renderPanelAccount() {
    return html`<road-account-filters></road-account-filters>`;
  }

  renderPanelPayee() {
    return html`<road-payee-filters></road-payee-filters>`;
  }

  renderPanelCurrency() {
    return html`<road-currency-filters></road-currency-filters>`;
  }

  renderPanelAccountCategory() {
    return html`<road-account-category-filters></road-account-category-filters>`;
  }

  renderPanelFamilyMember() {
    return html`<road-family-member-filters></road-family-member-filters>`;
  }

  renderPanelTag() {
    return html`<road-tag-filters></road-tag-filters>`;
  }

  renderPanelMovementCategory() {
    return html`<road-movement-category-filters></road-movement-category-filters>`;
  }

  renderPanels() {
    return html`
      ${this.renderPanel(FilterTabsEnum.DATE, this.renderPanelDate())}
      ${this.renderPanel(FilterTabsEnum.TEXT, this.renderPanelText())}
      ${this.renderPanel(FilterTabsEnum.STATUS, this.renderPanelStatus())}
      ${this.renderPanel(FilterTabsEnum.AMOUNT, this.renderPanelAmount())}
      ${this.renderPanel(FilterTabsEnum.ACCOUNT, this.renderPanelAccount())}
      ${this.renderPanel(FilterTabsEnum.PAYEE, this.renderPanelPayee())}
      ${this.renderPanel(FilterTabsEnum.CURRENCY, this.renderPanelCurrency())}
      ${this.renderPanel(FilterTabsEnum.ACCOUNT_CATEGORY, this.renderPanelAccountCategory())}
      ${this.renderPanel(FilterTabsEnum.FAMILY_MEMBER, this.renderPanelFamilyMember())}
      ${this.renderPanel(FilterTabsEnum.TAG, this.renderPanelTag())}
      ${this.renderPanel(FilterTabsEnum.MOVEMENT_CATEGORY, this.renderPanelMovementCategory())}
    `;
  }

  renderHeaderCloseButtonMarkup() {
    return html`
      <div slot="header-aside">
        <road-icon icon="close" size="sm"></road-icon>
      </div>
    `;
  }

  handleToggleModal(evt: 'closed' | 'opened' | 'cancel' | 'confirm') {
    if ((evt === 'closed' || evt === 'cancel') && this.open) {
      this.open = false;
      this.dispatchEvent(new CustomEvent(evt));
      return;
    }

    if (evt === 'confirm' && this.open) {
      this.open = false;
      this.dispatchEvent(
        new CustomEvent('confirm', {
          bubbles: true,
          composed: true,
          detail: {
            selectedCurrencies: this.selectedCurrencies,
            selectedAccounts: this.selectedAccounts,
            selectedAccountCategories: this.selectedAccountCategories,
            selectedTags: this.selectedTags,
            selectedUserMovementSubCategories: this.selectedUserMovementSubCategories,
            selectedFamilyMembers: this.selectedFamilyMembers,
            selectedPayees: this.selectedPayees,
            selectedMovementStatuses: this.selectedMovementStatuses,
            min: this.min,
            max: this.max,
            notes: this.text,
            from: this.from,
            to: this.to,
            useFiscalDate: this.useFiscalDate,
          },
        })
      );
      return;
    }

    if (evt === 'closed' && this.open) {
      this.open = false;
      this.dispatchEvent(new CustomEvent('closed'));
      return;
    }
  }

  render() {
    const okLabel = 'Apply';
    const cancelLabel = 'Close';

    return html`
      <road-modal
        class="filters-modal"
        ?open=${this.open}
        ?actions=${true}
        label="Advanced Filters"
        .okLabel=${okLabel}
        .cancelLabel=${cancelLabel}
        @closed=${() => this.handleToggleModal('closed')}
        @opened=${() => this.handleToggleModal('opened')}
        @cancel=${() => this.handleToggleModal('cancel')}
        @ok=${() => this.handleToggleModal('confirm')}
      >
        ${this.renderHeaderCloseButtonMarkup()}
        <road-tab-group no-scroll-controls">
          ${this.renderTabs(this.selectedTab)} ${this.renderPanels()}
        </road-tab-group>
      </road-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-filters': RoadFilters;
  }
}
