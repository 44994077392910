import {html} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';

import {scrollIntoView, getOffset, focusVisible, watch} from '../../../utils/dom';

import {RoadComponent} from '../../../lib/component';

import RoadTab, {
  COMPONENT_TAG as ROAD_TAB_COMPONENT_TAG,
} from '../../../components/base/tab';
import RoadTabPanel, {
  COMPONENT_TAG as ROAD_TAB_PANEL_COMPONENT_TAG,
} from '../../../components/base/tab_panel';
import styles from './style.scss';

import {TabVariant} from '../../../types/tabs';

const COMPONENT_TAG = 'road-tab-group';
const TAB_CHANGE_EVENT_KEY = 'tabChange';

enum Placement {
  TOP = 'top',
  BOTTOM = 'bottom',
  START = 'start',
  END = 'end',
}

enum Activation {
  AUTO = 'auto',
  MANUAL = 'manual',
}

enum ScrollBehavior {
  SMOOTH = 'smooth',
  AUTO = 'auto',
}

enum CustomEventType {
  HIDE_TAB = 'change:tab-hide',
  SHOW_TAB = 'change:tab-show',
}

const DEFAULT_SCROLL_BEHAVIOR = ScrollBehavior.SMOOTH;

@customElement(COMPONENT_TAG)
export default class RoadTabGroup extends RoadComponent {
  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @query('.road-tab-group') tabGroup!: HTMLElement;
  @query('.road-tab-group__body') body!: HTMLElement;
  @query('.road-tab-group__nav') nav!: HTMLElement;
  @query('.road-tab-group__indicator') indicator!: HTMLElement;

  /** The placement of the tabs. */
  @property() placement: Placement = Placement.TOP;

  @property()
  variant: TabVariant = TabVariant.DEFAULT;

  /**
   * When set to auto, navigating tabs with the arrow keys will instantly show the corresponding tab panel. When set to
   * manual, the tab will receive focus but will not show until the user presses spacebar or enter.
   */
  @property() activation: Activation = Activation.AUTO;

  /** Disables the scroll arrows that appear when tabs overflow. */
  @property({attribute: 'no-scroll-controls', type: Boolean})
  noScrollControls = false;

  @state() private hasScrollControls = false;

  private activeTab!: RoadTab;
  private mutationObserver!: MutationObserver;
  private resizeObserver!: ResizeObserver;
  private tabs: RoadTab[] = [];
  private panels: RoadTabPanel[] = [];

  static get styles() {
    return [styles];
  }

  connectedCallback() {
    super.connectedCallback();
    this.resizeObserver = new ResizeObserver(() => {
      this.preventIndicatorTransition();
      this.repositionIndicator();
      this.updateScrollControls();
    });

    this.mutationObserver = new MutationObserver(mutations => {
      // Update aria labels when the DOM changes
      if (
        mutations.some(
          m =>
            !['aria-labelledby', 'aria-controls'].includes(
              m.attributeName as string
            )
        )
      ) {
        setTimeout(() => this.setAriaLabels());
      }

      // Sync tabs when disabled states change
      if (mutations.some(m => m.attributeName === 'disabled')) {
        this.syncTabsAndPanels();
      }
    });

    this.updateComplete.then(() => {
      this.syncTabsAndPanels();
      this.mutationObserver.observe(this, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      this.resizeObserver.observe(this.nav);
      focusVisible.observe(this.tabGroup);

      // Set initial tab state when the tabs first become visible
      const intersectionObserver = new IntersectionObserver(
        (entries, observer) => {
          if (entries[0].intersectionRatio > 0) {
            this.setAriaLabels();
            this.setActiveTab(this.getActiveTab() || this.tabs[0], {
              emitEvents: false,
            });
            observer.unobserve(entries[0].target);
          }
        }
      );
      intersectionObserver.observe(this.tabGroup);
    });
  }

  disconnectedCallback() {
    this.mutationObserver.disconnect();
    this.resizeObserver.unobserve(this.nav);
    focusVisible.unobserve(this.tabGroup);
  }

  /** Shows the specified tab panel. */
  show(panel: string) {
    const tab = this.tabs.find(el => el.panel === panel) as RoadTab;
    if (tab) {
      this.setActiveTab(tab, {scrollBehavior: DEFAULT_SCROLL_BEHAVIOR});
      this.dispatchEvent(
        new CustomEvent(TAB_CHANGE_EVENT_KEY, {
          detail: tab,
        })
      );
    }
  }

  getAllTabs(includeDisabled = false) {
    const slot = this.shadowRoot!.querySelector(
      'slot[name="nav"]'
    ) as HTMLSlotElement;

    if (slot) {
      return [...slot.assignedElements()].filter(($el: Element) => {
        return includeDisabled
          ? $el.tagName.toLowerCase() === ROAD_TAB_COMPONENT_TAG
          : $el.tagName.toLowerCase() === ROAD_TAB_COMPONENT_TAG &&
              !($el as any).disabled;
      }) as RoadTab[];
    }
    return [];
  }

  getAllPanels() {
    const slot = this.body?.querySelector('slot');
    if (slot) {
      return [...slot.assignedElements()].filter(
        ($el: Element) =>
          $el.tagName.toLowerCase() === ROAD_TAB_PANEL_COMPONENT_TAG
      ) as [RoadTabPanel];
    }

    return [];
  }

  getActiveTab() {
    return this.tabs.find(el => el.active);
  }

  handleClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const tab = target.closest(ROAD_TAB_COMPONENT_TAG) as RoadTab;
    const tabGroup = tab?.closest(COMPONENT_TAG);

    // Ensure the target tab is in this tab group
    if (tabGroup !== this) {
      return;
    }

    if (tab) {
      this.setActiveTab(tab, {scrollBehavior: DEFAULT_SCROLL_BEHAVIOR});
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    const tab = target.closest(ROAD_TAB_COMPONENT_TAG) as RoadTab;
    const tabGroup = tab?.closest(COMPONENT_TAG);

    // Ensure the target tab is in this tab group
    if (tabGroup !== this) {
      return;
    }

    // Activate a tab
    if (['Enter', ' '].includes(event.key)) {
      if (tab) {
        this.setActiveTab(tab, {scrollBehavior: DEFAULT_SCROLL_BEHAVIOR});
        event.preventDefault();
      }
    }

    // Move focus left or right
    if (
      [
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'Home',
        'End',
      ].includes(event.key)
    ) {
      const activeEl = document.activeElement as any;

      if (
        activeEl &&
        activeEl.tagName.toLowerCase() === ROAD_TAB_COMPONENT_TAG
      ) {
        let index = this.tabs.indexOf(activeEl);

        if (event.key === 'Home') {
          index = 0;
        } else if (event.key === 'End') {
          index = this.tabs.length - 1;
        } else if (event.key === 'ArrowLeft') {
          index = Math.max(0, index - 1);
        } else if (event.key === 'ArrowRight') {
          index = Math.min(this.tabs.length - 1, index + 1);
        }

        this.tabs[index].focus({preventScroll: true});

        if (this.activation === Activation.AUTO) {
          this.setActiveTab(this.tabs[index], {
            scrollBehavior: DEFAULT_SCROLL_BEHAVIOR,
          });
        }

        if ([Placement.TOP, Placement.BOTTOM].includes(this.placement)) {
          scrollIntoView(this.tabs[index], this.nav, 'horizontal');
        }

        event.preventDefault();
      }
    }
  }

  handleScrollToStart() {
    this.nav.scroll({
      left: this.nav.scrollLeft - this.nav.clientWidth,
      behavior: DEFAULT_SCROLL_BEHAVIOR,
    });
  }

  handleScrollToEnd() {
    this.nav.scroll({
      left: this.nav.scrollLeft + this.nav.clientWidth,
      behavior: DEFAULT_SCROLL_BEHAVIOR,
    });
  }

  @watch('noScrollControls')
  updateScrollControls() {
    if (this.nav) {
      if (this.noScrollControls) {
        this.hasScrollControls = false;
      } else {
        this.hasScrollControls =
          [Placement.TOP, Placement.BOTTOM].includes(this.placement) &&
          this.nav.scrollWidth > this.nav.clientWidth;
      }
    }
  }

  setActiveTab(
    tab: RoadTab,
    options?: {
      emitEvents?: boolean;
      scrollBehavior?: ScrollBehavior;
    }
  ) {
    options = Object.assign(
      {
        emitEvents: true,
        scrollBehavior: ScrollBehavior.AUTO,
      },
      options
    );

    if (tab && tab !== this.activeTab && !tab.disabled) {
      const previousTab = this.activeTab;
      this.activeTab = tab;

      // Sync active tab and panel
      this.tabs.map(el => (el.active = el === this.activeTab));
      this.panels.map(el => (el.active = el.name === this.activeTab.panel));
      this.syncIndicator();

      if ([Placement.TOP, Placement.BOTTOM].includes(this.placement)) {
        scrollIntoView(
          this.activeTab,
          this.nav,
          'horizontal',
          options.scrollBehavior
        );
      }

      // Emit events
      if (options.emitEvents) {
        if (previousTab) {
          this.dispatchEvent(
            new CustomEvent(CustomEventType.HIDE_TAB, {
              detail: {name: previousTab.panel},
            })
          );
        }

        this.dispatchEvent(
          new CustomEvent(CustomEventType.SHOW_TAB, {
            detail: {name: this.activeTab.panel},
          })
        );
      }
    }
  }

  setAriaLabels() {
    // Link each tab with its corresponding panel
    this.tabs.map(tab => {
      const panel = this.panels.find(
        el => el.name === tab.panel
      ) as RoadTabPanel;
      if (panel) {
        tab.setAttribute('aria-controls', panel.getAttribute('key') as string);
        panel.setAttribute(
          'aria-labelledby',
          tab.getAttribute('key') as string
        );
      }
    });
  }

  @watch('placement')
  syncIndicator() {
    if (this.indicator) {
      const tab = this.getActiveTab();

      if (tab) {
        this.indicator.style.display = 'block';
        this.repositionIndicator();
      } else {
        this.indicator.style.display = 'none';
        return;
      }
    }
  }

  repositionIndicator() {
    const currentTab = this.getActiveTab();

    if (!currentTab) {
      return;
    }

    const width = currentTab.clientWidth;
    const height = currentTab.clientHeight;
    const offset = getOffset(currentTab, this.nav);
    const offsetTop = offset.top + this.nav.scrollTop;
    const offsetLeft = offset.left + this.nav.scrollLeft;

    switch (this.placement) {
      case Placement.TOP:
      case Placement.BOTTOM:
        this.indicator.style.width = `${width}px`;
        this.indicator.style.height = 'auto';
        this.indicator.style.transform = `translateX(${offsetLeft}px)`;
        break;

      case Placement.START:
      case Placement.END:
        this.indicator.style.width = 'auto';
        this.indicator.style.height = `${height}px`;
        this.indicator.style.transform = `translateY(${offsetTop}px)`;
        break;
    }
  }

  // In some orientations, when the component is resized, the indicator's position will change causing it to animate
  // while you resize. Calling this method will prevent the transition from running on resize, which feels more natural.
  preventIndicatorTransition() {
    const transitionValue = this.indicator.style.transition;
    this.indicator.style.transition = 'none';

    requestAnimationFrame(() => {
      this.indicator.style.transition = transitionValue;
    });
  }

  // This stores tabs and panels so we can refer to a cache instead of calling querySelectorAll() multiple times.
  syncTabsAndPanels() {
    this.tabs = this.getAllTabs();
    this.panels = this.getAllPanels();
    this.syncIndicator();
  }

  get classes() {
    return {
      'road-tab-group': true,
      'road-tab-group--top': this.placement === Placement.TOP,
      'road-tab-group--bottom': this.placement === Placement.BOTTOM,
      'road-tab-group--start': this.placement === Placement.START,
      'road-tab-group--end': this.placement === Placement.END,
      'road-tab-group--has-scroll-controls': this.hasScrollControls,
    };
  }

  get tabClasses() {
    return {
      'road-tab-group__tabs': true,
      'road-tab-group__tabs--pilled': this.variant === TabVariant.PILL,
    };
  }

  get indicatorClasses() {
    return {
      'road-tab-group__indicator': true,
      'road-tab-group__indicator--pilled': this.variant === TabVariant.PILL,
    };
  }

  //TODO: revisit this when it becomes needed...
  scrollControlsMarkup() {
    if (!this.hasScrollControls) return {start: html``, end: html``};
    return {
      start: html`
        <div
          class="road-tab-group__scroll-button road-tab-group__scroll-button--start"
        >
          <road-icon
            icon="chevron-left"
            @click=${this.handleScrollToStart.bind(this)}
          ></road-icon>
        </div>
      `,
      end: html`
        <div
          class="road-tab-group__scroll-button road-tab-group__scroll-button--end"
        >
          <road-icon
            icon="chevron-right"
            @click=${this.handleScrollToStart.bind(this)}
          ></road-icon>
        </div>
      `,
    };
  }

  render() {
    const scrollControlsMarkup = this.scrollControlsMarkup();
    return html`
      <div
        class=${classMap(this.classes)}
        @click=${this.handleClick.bind(this)}
        @keydown=${this.handleKeyDown.bind(this)}
      >
        <div class="road-tab-group__nav-container">
          ${scrollControlsMarkup.start}
          <div class="road-tab-group__nav">
            <div class=${classMap(this.tabClasses)} role="tablist">
              <div class=${classMap(this.indicatorClasses)}></div>
              <slot
                name="nav"
                @slotchange=${this.syncTabsAndPanels.bind(this)}
              ></slot>
            </div>
          </div>
          ${scrollControlsMarkup.end}
        </div>
        <div class="road-tab-group__body">
          <slot @slotchange=${this.syncTabsAndPanels.bind(this)}></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_TAG]: RoadTabGroup;
  }
}
