import {html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import {RoadComponent} from '../../../../lib/component';

import styles from './style.scss';

export enum AmountFiltersEvents {
  MIN_CHANGED = 'filters-amount-min-changed',
  MAX_CHANGED = 'filters-amount-max-changed',
}

@customElement('road-amount-filters')
export class RoadAmountFilters extends RoadComponent {
  @property({type: Number})
  min?: number;

  @property({type: Number})
  max?: number;

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.min = undefined;
    this.max = undefined;
  }

  handleMinChange(evt: CustomEvent): void {
    this.min = evt.detail.value;
    this.dispatchAmountChangedEvent(AmountFiltersEvents.MIN_CHANGED, this.min);
  }

  handleMaxChange(evt: CustomEvent): void {
    this.max = evt.detail.value;
    this.dispatchAmountChangedEvent(AmountFiltersEvents.MAX_CHANGED, this.max);
  }

  dispatchAmountChangedEvent(evtType: string, value?: number): void {
    this.dispatchEvent(
      new CustomEvent(evtType, {
        bubbles: true,
        composed: true,
        detail: {
          value: value,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-amount-filters__filter">
          <label for="min">Minimum</label>
          <road-input
            id="min"
            placeholder="Minimum"
            type="number"
            .value=${this.min}
            intent="base"
            @change=${(e: CustomEvent<number>) => {
              this.handleMinChange(e);
            }}
          >
          </road-input>
        </div>

        <div class="road-amount-filters__filter">
          <label for="max">Maximum</label>
          <road-input
            id="max"
            placeholder="Maximum"
            type="number"
            .value=${this.max}
            intent="base"
            @change=${(e: CustomEvent<number>) => {
              this.handleMaxChange(e);
            }}
          >
          </road-input>
        </div>
      `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-amount-filters': RoadAmountFilters;
  }
}
