export enum RelevancyWeights {
  HIGHEST = 5,
  HIGH = 4,
  MEDIUM = 3,
  LOW = 2,
  LOWEST = 1,
}

export function applyRelevancy(weight: RelevancyWeights, value: string) {
  return value.repeat(weight);
}
