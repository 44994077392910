import {html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import {RoadComponent} from '../../../../lib/component';

import styles from './style.scss';

export enum DateFiltersEvents {
  FROM_CHANGED = 'filters-dates-from-changed',
  TO_CHANGED = 'filters-dates-to-changed',
  FISCAL_CHANGED = 'filters-dates-fiscal-changed',
}

@customElement('road-date-filters')
export class RoadDateFilters extends RoadComponent {
  @property({attribute: false})
  from?: Date;

  @property({attribute: false})
  to?: Date;

  @property({type: String})
  useFiscalDate = '1';

  static get styles() {
    return [styles];
  }

  resetFilters() {
    this.from = undefined;
    this.to = undefined;
    this.useFiscalDate = '1';
  }

  handleFromChange(evt: CustomEvent): void {
    this.from = evt.detail.value;
    this.dispatchDateChangedEvent(DateFiltersEvents.FROM_CHANGED, this.from);
  }

  handleToChange(evt: CustomEvent): void {
    this.to = evt.detail.value;
    this.dispatchDateChangedEvent(DateFiltersEvents.TO_CHANGED, this.to);
  }

  handleFiscalDateChange(): void {
    this.useFiscalDate = this.useFiscalDate == '1' ? '0' : '1';
    this.dispatchEvent(
      new CustomEvent(DateFiltersEvents.FISCAL_CHANGED, {
        bubbles: true,
        composed: true,
        detail: {
          value: this.useFiscalDate,
        },
      })
    );
  }

  dispatchDateChangedEvent(evtType: string, value?: Date): void {
    this.dispatchEvent(
      new CustomEvent(evtType, {
        bubbles: true,
        composed: true,
        detail: {
          value: value,
        },
      })
    );
  }

  render() {
    return html`
        <div class="road-date-filters__filter">
          <label for="from">From</label>
          <road-input
            id="from"
            placeholder="From"
            type="date"
            .value=${String(this.from)}
            intent="base"
            @change=${(e: CustomEvent<Date>) => {
              this.handleFromChange(e);
            }}
          >
          </road-input>
        </div>

        <div class="road-date-filters__filter">
          <label for="to">To</label>
          <road-input
            id="to"
            placeholder="To"
            type="date"
            intent="base"
            .value=${String(this.to)}
            @change=${(e: CustomEvent<Date>) => {
              this.handleToChange(e);
            }}
          >
          </road-input>
        </div>

        <div class="road-date-filters__filter">
          <label for="to">Use fiscal date</label>
          <road-icon
            class="road-date-filters__filter__fiscal-date"
            id="fiscal-date"
            size="xl"
            icon=${this.useFiscalDate == '1' ? 'toggle_on' : 'toggle_off'}
            fill=${this.useFiscalDate == '1' ? 'green' : 'gray'}
            @click=${(e: CustomEvent) => {
              e.stopPropagation();
              e.preventDefault();
              this.handleFiscalDateChange();
            }}
          >
          </road-input>
        </div>
      `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-date-filters': RoadDateFilters;
  }
}
