import {html} from 'lit';
import {property, customElement} from 'lit/decorators.js';

import {RoadComponent} from '../../../lib/component';

import styles from './style.scss';

@customElement('road-user-main-page')
export class RoadUserMainPage extends RoadComponent {
  @property({type: Boolean, attribute: 'missing-accounts'})
  missingAccounts = false;

  @property({type: Boolean, attribute: 'missing-payees'})
  missingPayees = false;

  @property({type: Boolean, attribute: 'missing-family-members'})
  missingFamilyMembers = false;

  @property({type: Boolean, attribute: 'default-categories'})
  defaultCategories = false;

  @property({type: Boolean, attribute: 'due-movements'})
  dueMovements = false;

  @property({type: String, attribute: 'missing-currency'})
  missingCurrency = '';

  static get styles() {
    return [styles];
  }

  get hasReminders() {
    return this.missingAccounts ||
           this.missingPayees ||
           this.missingFamilyMembers ||
           this.dueMovements ||
           this.defaultCategories ||
           this.missingCurrency;
  }

  renderReminders() {
    return html`
      <div class="row bg-warning py-0 my-0">
        <div class="col-12">
          ${this.missingAccounts
              ? html`<a href="accounts/new", class="btn btn-warning my-1">Create your first account</a>`
              : html``
          }
          ${this.missingPayees
              ? html`<a href="payees/new", class="btn btn-warning my-1">Create your first payee</a>`
              : html``
          }
          ${this.missingFamilyMembers
              ? html`<a href="family_members/new", class="btn btn-warning my-1">Create your first family member</a>`
              : html``
          }
          ${this.defaultCategories
              ? html`<a href="user_movement_categories", class="btn btn-warning my-1">Set up your own categories</a>`
              : html``
          }
          ${this.missingCurrency
              ? html`<a href="user_exchange_rates", class="btn btn-warning my-1">You should add the exchange rate for ${this.missingCurrency}</a>`
              : html``
          }
          ${this.dueMovements
              ? html`<a href="recurring_movements", class="btn btn-warning my-1">Recurring transactions to be paid</a>`
              : html``
          }
        </div>
      </div>
    `;
  }

  render() {
    return html`
      ${this.hasReminders ? this.renderReminders() : ''}
      <slot name="chapters"></slot>
    `;
  }

  // This is needed to make the component render in the shadow dom
  // It uses css from the parent component (not defined here)
  createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-user-main-page': RoadUserMainPage;
  }
}
