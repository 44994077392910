import '../icon';

import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';
import {RoadComponent} from '../../../lib/component';
import styles from './style.scss';
import {watch} from '../../../../src/utils/dom';

/**
 * Possible intent varients for alert components.
 */
export enum AlertIntent {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
}

/**
 * Possible sizes for alert components.
 * Only affects the width, as the height is dynamic, based on the content
 */
export enum AlertSize {
  XS = 'xs',
  MD = 'md',
  XL = 'xl',
}

/**
 * The tag name for alert messages.
 */
export const COMPONENT_TAG = 'road-alert';

const DEFAULT_TIMEOUT_MS = 15000;
const DEFAULT_FADE_MS = 1000;

/**
 * Custom element for flashes and alert messages.
 */
@customElement(COMPONENT_TAG)
export default class Alert extends RoadComponent {
  @property()
  intent: AlertIntent = AlertIntent.PRIMARY;

  @property()
  size: AlertSize = AlertSize.XS;

  @property({type: Boolean})
  dismissible = false;

  @property({type: Boolean})
  dismissed = false;

  @property({type: Number})
  timeout = DEFAULT_TIMEOUT_MS;

  @property({type: Boolean})
  pinned = false;

  @query('.road-alert')
  $alert!: HTMLInputElement;

  static get styles() {
    return [styles];
  }

  classes(): ClassInfo {
    return {
      'road-alert--primary': this.intent === AlertIntent.PRIMARY,
      'road-alert--secondary': this.intent === AlertIntent.SECONDARY,
      'road-alert--success': this.intent === AlertIntent.SUCCESS,
      'road-alert--warning': this.intent === AlertIntent.WARNING,
      'road-alert--danger': this.intent === AlertIntent.DANGER,
      'road-alert--info': this.intent === AlertIntent.INFO,
      'road-alert--xl': this.size === AlertSize.XL,
      'road-alert--md': this.size === AlertSize.MD,
      'road-alert--xs': this.size === AlertSize.XS,
      'road-alert--dismissed': this.dismissed,
    };
  }

  @watch('dismissed')
  onDismissed() {
    this.dismissed && this.dispatchEvent(new CustomEvent('closed'));
  }

  dismiss() {
    if (this.dismissed) return;

    const dismissingClass = 'road-alert--dismissing';
    this.$alert.classList.add(dismissingClass);

    setTimeout(() => {
      this.$alert.classList.remove(dismissingClass);
      this.dismissed = true;
    }, DEFAULT_FADE_MS);
  }

  load() {
    if (this.pinned) return;
    const t = setTimeout(() => {
      this.dismiss();
      clearTimeout(t);
    }, this.timeout);
  }

  firstUpdated() {
    this.dispatchEvent(new CustomEvent('loaded'));
  }

  render() {
    return html`
      <div class="road-alert ${classMap(this.classes())}">
        <div class="road-alert__content">
          <slot></slot>
        </div>
        ${this.dismissible
          ? html`
              <div class="road-alert__icon" @click=${this.dismiss.bind(this)}>
                <road-icon icon="close"></road-icon>
              </div>
            `
          : ''}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-alert': Alert;
  }
}
