import {Account} from './account';
import {User} from './user';
import {FamilyMember} from './family_member';
import {Payee} from './payee';
import {MovementType, UserMovementCategory, UserMovementSubCategory} from './movement_category';

export interface Movement {
  id: number;
  status: MovementStatus;
  date: string;
  net_amount?: number;
  amount?: number;
  sign?: number;
  fiscal_date: string;
  recurring_movement_id?: number;
  notes?: string;
  movement_type: MovementType;
  created_at: string;
  updated_at: string;
  account?: Account;
  transfer_movement_id?: number;
  transfer_movement_account_id?: number;
  transfer_movement_account_name?: string;
  transfer_movement_amount?: number;
  transfer_movement_sign?: number;
  transfer_movement_currency_id?: number;
  import_id?: number;
  tags?: Tag[];
  attachments: File[];
  transfer_movement?: Movement;
  user_movement_category?: UserMovementCategory;
  user_movement_sub_category?: UserMovementSubCategory;
  family_member?: FamilyMember;
  payee?: Payee;
  user: User;
}

export interface Tag {
  id: number;
  tag: string;
  created_at: string;
  updated_at: string;
}

export enum MovementStatus {
  UNCONFIRMED = 0,
  CONFIRMED = 1,
  RECONCILING = 2,
  }

type StatusInformation = {
  icon: string;
  description: string;
};

export const statusDescriptions = new Map<number, StatusInformation>([
  [MovementStatus.CONFIRMED, {icon: 'check_circle', description: 'confirmed'}],
  [MovementStatus.UNCONFIRMED, {icon: 'cancel', description: 'unconfirmed'}],
  [MovementStatus.RECONCILING, {icon: 'manage_search', description: 'reconciling'}],
]);
