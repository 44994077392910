import {User} from './user';

export interface UserMovementCategory {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  user: User;
  user_movement_sub_categories: UserMovementSubCategory[];
}

export interface UserMovementSubCategory {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  movement_type: MovementType;
  movement_category: UserMovementCategory;
  user: User;
}

export enum MovementType {
  TRANSFER = 0,
  INCOME = 1,
  EXPENSE = 2
}
