import {html, CSSResultArray} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../lib/component';

import {isNonEmptyString} from '../../../utils';

import {
  DEFAULT_SVG_ICON_SIZE,
  Fill as IconFill,
  Size as IconSize,
} from '../../../components/base/icon';
import styles from './style.scss';

@customElement('road-card')
export default class RoadCard extends RoadComponent {
  @property()
  key = `${new Date().getTime()}-${Math.random()}`;

  @property({type: String})
  header = '';

  @property({type: String})
  headerIcon = '';

  @property({type: String})
  headerSVGIcon = '';

  @property({type: String})
  headerIconFill: IconFill = IconFill.BASE;

  @property({type: String})
  headerIconSize: IconSize = IconSize.BASE;

  @property({type: String})
  headerSVGIconSize: string | number = DEFAULT_SVG_ICON_SIZE;

  @property({type: Boolean})
  anchor = false;

  static get styles(): CSSResultArray {
    return [styles];
  }

  get computedClasses(): Record<string, ClassInfo> {
    return {
      container: {
        'road-card': true,
        'road-card--anchor': this.anchor,
      },
      headerContainer: {
        'road-card__header': true,
      },
      headerIcon: {
        'road-card__header__icon': true,
      },
      headerLabel: {
        'road-card__header__label': true,
      },
      headerAside: {
        'road-card__header__aside': true,
      },
      main: {
        'road-card__main': true,
      },
      footer: {
        'road-card__footer': true,
      },
    };
  }

  headerIconMarkup() {
    const defaultMarkup = html`
      <div class=${classMap(this.computedClasses.headerIcon)}>
        <slot name="header-icon"></slot>
      </div>
    `;

    // if (!isNonEmptyString(this.header)) return defaultMarkup;
    const icon = isNonEmptyString(this.headerIcon)
      ? this.headerIcon
      : isNonEmptyString(this.headerSVGIcon)
      ? this.headerSVGIcon
      : '';
    if (!isNonEmptyString(icon)) return defaultMarkup;

    return html`
      <div class=${classMap(this.computedClasses.headerIcon)}>
        <road-icon
          .icon=${icon}
          ?svg=${icon === this.headerSVGIcon}
          .size=${this.headerIconSize}
          .svgIconSize=${this.headerSVGIconSize}
          .fill=${this.headerIconFill}
        ></road-icon>
      </div>
    `;
  }

  render() {
    return html`
      <div part="container" class=${classMap(this.computedClasses.container)}>
        <div class=${classMap(this.computedClasses.headerContainer)}>
          ${this.headerIconMarkup()}
          <div
            class=${classMap(this.computedClasses.headerLabel)}
            title=${this.header}
          >
            ${this.header
              ? html`<label>${this.header}</label>`
              : html`<slot name="header-label"></slot>`
            }
          </div>
          <div class=${classMap(this.computedClasses.headerAside)}>
            <slot name="header-aside"></slot>
          </div>
        </div>
        <div class=${classMap(this.computedClasses.main)}>
          <slot></slot>
        </div>
        <div class=${classMap(this.computedClasses.footer)}>
          <slot name="footer"></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-card': RoadCard;
  }
}
