import {html, CSSResultArray, TemplateResult} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';

import {RoadComponent} from '../../../lib/component';
import styles from './style.scss';

export const COMPONENT_TAG = 'road-floating-surface';

export enum Direction {
  TOP_RIGHT = 'tr',
  TOP_LEFT = 'tl',
  BOTTOM_LEFT = 'bl',
  BOTTOM_RIGHT = 'br',
}

export enum FloatingSurfaceEvent {
  OPENED = 'opened',
  CLOSED = 'closed',
}

@customElement(COMPONENT_TAG)
export default class RoadFloatingSurface extends RoadComponent {
  @property({type: Number})
  x = 0;

  @property({type: Number})
  y = 0;

  @property({type: String, reflect: true})
  direction: Direction = Direction.BOTTOM_LEFT;

  @property({type: Boolean, reflect: true})
  active = false;

  @property({type: Boolean, attribute: false})
  animating = false;

  @property({type: String, reflect: true})
  key = `${new Date().getTime()}-${Math.random()}`;

  boundClickHandler: EventListener | null = null;

  close(): void {
    this.active = false;
  }

  open(): void {
    this.animating = true;
    this.active = true;
    setTimeout(() => {
      this.animating = false;
    }, 200);
  }

  updated(changedProperties: Map<string, unknown>) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName !== 'active') return;
      if (this.active === oldValue) return;
      if (this.active && !oldValue) {
        this.boundClickHandler = this.handleClickEvents.bind(this);
        document.body.addEventListener('click', this.boundClickHandler, {
          passive: true,
          capture: true,
        });

        const customEventDetail = {
          tag: FloatingSurfaceEvent.OPENED,
          x: this.x,
          y: this.y,
          direction: this.direction,
          key: this.key,
          active: this.active,
        };

        this.dispatchEvent(
          new CustomEvent(FloatingSurfaceEvent.OPENED, {
            detail: customEventDetail,
          })
        );

        window.dispatchEvent(
          new CustomEvent(COMPONENT_TAG, {
            detail: customEventDetail,
          })
        );
        return;
      }

      if (!this.active && oldValue && this.boundClickHandler) {
        document.body.removeEventListener('click', this.boundClickHandler, {
          capture: true,
        });

        const customEventDetail = {
          tag: FloatingSurfaceEvent.CLOSED,
          x: this.x,
          y: this.y,
          direction: this.direction,
          key: this.key,
          active: this.active,
        };

        this.dispatchEvent(
          new CustomEvent(FloatingSurfaceEvent.CLOSED, {
            detail: customEventDetail,
          })
        );

        window.dispatchEvent(
          new CustomEvent(COMPONENT_TAG, {
            detail: customEventDetail,
          })
        );
        return;
      }
    });
  }

  handleClickEvents(evt: Event) {
    const path = evt.composedPath();
    if (path.indexOf(this) === -1) {
      this.close();
    }
  }

  static get styles(): CSSResultArray {
    return [styles];
  }

  getSurfaceContainer(): HTMLElement | void {
    return this.shadowRoot?.querySelector(
      '#road-floating-surface-container'
    ) as HTMLElement;
  }

  render(): TemplateResult {
    const computedStyles = {
      top: `${this.y}px`,
      left: `${this.x}px`,
    };

    const computedClasses = {
      'road-floating-surface': true,
      'road-floating-surface--active': this.active,
      [`road-floating-surface--${this.direction}`]: true,
    };

    return html`
      <div
        id="road-floating-surface-container"
        class=${classMap(computedClasses)}
        style=${styleMap(computedStyles)}
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-floating-surface': RoadFloatingSurface;
  }
}
