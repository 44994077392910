import '../../base/icon';
import '../../base/input';
import '../../base/paginator';

import {html, TemplateResult} from 'lit';
import {property} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';

import {RoadComponent} from '../../../lib/component';
import {CustomInputEvent, Variant as RoadInputVariant} from '../../base/input';
import styles from './style.scss';
import tableStyles from '../../base/table/style.scss';
import {paginate} from '../../../utils/arrays';
import {fuzzyMatch, safeToGrep} from '../../../utils/strings';

const DEFAULT_PAGE_LIMIT = 12;
const DEFAULT_EMPTY_MESSAGE = 'There is nothing to display.';
const DEFAULT_NO_MATCHES_MESSAGE = 'There are no data matching your query.';

/**
 * A client-side paginated data table meant to be extended by actual components.
 */
export abstract class DataTableComponent<T> extends RoadComponent {
  @property({type: String})
  key = `${new Date().getTime()}-${Math.random()}`;

  @property({type: Array})
  tableData: T[] = [];

  @property({type: Array})
  filteredData: T[] = [];

  @property({type: Number})
  pageLimit = DEFAULT_PAGE_LIMIT;

  @property({type: Number})
  page = 1;

  @property()
  query = '';

  /**
   * The string to display when no data is provided.
   */
  emptyMessage = DEFAULT_EMPTY_MESSAGE;

  /**
   * The string to display when there a no matches for a given query.
   */
  noMatchesMessage = DEFAULT_NO_MATCHES_MESSAGE;

  static get styles() {
    return [styles, tableStyles];
  }

  get pagedData() {
    return paginate(
      this.query ? this.filteredData : this.tableData,
      this.page,
      this.pageLimit
    );
  }

  get totalPages() {
    return Math.ceil(this.tableData.length / this.pageLimit);
  }

  nextPage() {
    this.page++;
    return;
  }

  prevPage() {
    this.page--;
    return;
  }

  firstPage() {
    this.page = 1;
    return;
  }

  lastPage() {
    this.page = this.totalPages;
    return;
  }

  gotoPage(page: number) {
    if (page < 1) {
      this.firstPage();
      return;
    }

    if (page > this.totalPages) {
      this.lastPage();
      return;
    }

    this.page = page;
    return;
  }

  updateResults() {
    let workingResults = this.tableData;

    // Filter via search text, if exists
    workingResults = workingResults.filter(item => {
      if (this.query) {
        return fuzzyMatch(
          safeToGrep(this.query),
          safeToGrep(this.haystack(item))
        );
      }
      return true;
    });

    this.page = 1;
    this.filteredData = workingResults;
    this.requestUpdate();
  }

  /**
   * haystack is used for determining which property of each data table item
   * should be searched again.
   */
  abstract haystack(item: T): string;

  /**
   * renderRow should return a TemplateResult containing a data table row's markup.
   */
  abstract renderRow(item: T): TemplateResult;

  /**
   * Should contain markup for the table header.
   */
  abstract renderHeader(): TemplateResult;

  renderCallOuts() {
    if (!this.filteredData.length) {
      return html`
        <div class="data-table__call-out-wrapper">
          <div class="data-table__call-out data-table__call-out--no-results">
            <road-icon icon="error"></road-icon>
            ${this.noMatchesMessage}
          </div>
        </div>
      `;
    }

    if (!this.tableData.length) {
      return html`
        <div
          class="data-table__call-out-wrapper data-table__call-out-wrapper--empty"
        >
          <div class="data-table__call-out data-table__call-out--empty">
            <road-icon icon="error"></road-icon>
            ${this.emptyMessage}
            <div class="data-table__call-out--empty__slot">
              <slot name="empty"></slot>
            </div>
          </div>
        </div>
      `;
    }
  }

  render() {
    return html`
      <div class="road-table-container" id="${this.key}">
        <div
          class="road-table-container__controls road-table-container__controls--padded data-table__header"
        >
          <div class="road-table__controls">
            <road-input
              hasicon
              type="search"
              placeholder="Search"
              variant=${RoadInputVariant.BORDERLESS_NO_BG}
              @input=${(e: CustomEvent<CustomInputEvent>) => {
                this.query = e.detail.value;
                this.updateResults();
              }}
            >
              <div slot="icon">
                <road-icon icon="search"></road-icon>
              </div>
            </road-input>
            <slot name="controls"></slot>
          </div>
          <slot name="header"></slot>
        </div>
        <div class="road-table data-table">
          <table>
            ${this.renderHeader()}
            <tbody>
              ${repeat(
                this.pagedData,
                (_, idx: number) => `repeatkey:${idx}:${new Date().getTime()}`,
                this.renderRow.bind(this)
              )}
            </tbody>
          </table>
          ${this.renderCallOuts()}
        </div>
        <div class="road-table-container__controls data-table__footer">
          <road-paginator
            .page=${this.page}
            total=${this.totalPages}
            @previous=${() => this.prevPage()}
            @next=${() => this.nextPage()}
            @first=${() => this.firstPage()}
            @last=${() => this.lastPage()}
            @goto=${(evt: CustomEvent) => this.gotoPage(evt.detail.value)}
          >
          </road-paginator>
        </div>
      </div>
    `;
  }
}
