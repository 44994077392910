import {AnyObject} from 'src/types/general';
import {isNil} from './index';

export function hasProperty(
  item: Record<PropertyKey, unknown>,
  property: PropertyKey
): property is keyof typeof item;
export function hasProperty<TItem, TPropName extends PropertyKey>(
  item: TItem,
  property: TPropName
): item is TItem & Record<TPropName, unknown>;
/**
 * **Careful**: Defining a PropValue without passing a type guard makes a type assertion,
 * which overrides the compiler readings and may cause bugs down the future.
 * Use it only if you understand the implications of it.
 */
export function hasProperty<TItem, TPropName extends PropertyKey, TPropValue>(
  item: TItem,
  property: TPropName
): item is TItem & Record<TPropName, TPropValue>;
export function hasProperty<TItem, TPropName extends PropertyKey, TPropValue>(
  item: TItem,
  property: TPropName,
  typeGuard: (value: unknown) => value is TPropValue
): item is TItem & Record<TPropName, TPropValue>;

export function hasProperty<TItem, TPropName extends PropertyKey, TPropValue>(
  item: TItem,
  property: TPropName,
  typeGuard?: (value: unknown) => value is TPropValue
): item is TItem & Record<TPropName, TPropValue> {
  if (isNil(item)) return false;

  try {
    const _item = item as Record<string, unknown>;
    if (property in _item) {
      return typeGuard
        ? typeGuard(_item[property as keyof typeof _item])
        : true;
    }
    return false;
  } catch {
    return false;
  }
}

export function pick<T extends AnyObject, Key extends keyof T>(
  original: T,
  keys: Key[]
): {[K in Key]: T[K]} {
  // @ts-expect-error Object will be built in the for loop
  const response: Pick<T, Key> = {};

  for (const key of keys) {
    response[key] = original[key];
  }

  return response;
}

export function omit<
  T extends AnyObject,
  Key extends Extract<keyof T, string | number>
>(original: T, keys: Key[]): Omit<T, Key> {
  // @ts-expect-error Object will be built in the for loop
  const response: {[K in keyof T]: T[K]} = {};
  const allKeys = Object.keys(original) as Array<keyof T>;
  const excludeSet = new Set(keys.map(String));
  for (const key of allKeys) {
    if (!excludeSet.has(key as string)) {
      response[key] = original[key];
    }
  }
  return response;
}
