import {getCSRFToken} from './auth';
import {namespaceIdGenerator} from './generators';
import {RequestMethod} from './../common/constants';

/**
 * Simple interface for fetcher parameters.
 */
export interface FetcherParams<K = unknown> {
  method: RequestMethod;
  body?: K | Record<string, unknown> | unknown;
  headers?: {[k: string]: string};
  signal?: AbortSignal;
  responseParser?: 'json' | 'formdata' | 'text' | 'blob' | null;
}

/**
 * Simple fetch wrap to make native fetch more usable.
 */
export async function fetcher<T, K = unknown>(
  url: string,
  params: FetcherParams<K>
): Promise<T> {
  const headers: Record<string, string> | undefined =
    params.body instanceof FormData
      ? undefined
      : {'Content-Type': 'application/json'};

  if (headers && getCSRFToken()) {
    headers['X-CSRF-Token'] = getCSRFToken();
  }

  const requestData: RequestInit = {
    signal: params.signal,
    method: params.method,
    headers: {
      ...(headers || {}),
      ...params.headers,
    },
  };

  if (params.body)
    requestData.body =
      params.body instanceof FormData
        ? params.body
        : JSON.stringify(params.body);

  try {
    const res = await fetch(url, requestData);
    let data;
    switch (params.responseParser) {
      case null:
        data = '';
        break;
      case 'formdata':
        data = await res.formData();
        break;
      case 'blob':
        data = await res.blob();
        break;
      case 'text':
        data = await res.text();
        break;
      default:
        data = await res.json();
        break;
    }

    if (res.ok) {
      return Promise.resolve(data);
    } else {
      return Promise.reject(data);
    }
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

export type URLBuilderParams = {
  [k: string]: (string | number)[] | string | number;
};

/**
 * Creates a simple URL given a path string.
 */
export function buildURL(pathStr: string, params: URLBuilderParams = {}): URL {
  const baseURL = `${location.protocol}//${location.host}`;
  const url = new URL(baseURL + pathStr);

  Object.keys(params).forEach(k => {
    const v = params[k];

    if (typeof v === 'string' || typeof v === 'number') {
      url.searchParams.set(k, String(v));
    }

    if (Array.isArray(v)) {
      v.forEach(value => {
        url.searchParams.append(`${k}[]`, String(value));
      });
    }
  });

  return url;
}

/**
 * Get default headers for requests.
 */
export function getDefaultHeaders(): Record<string, string> {
  return {
    'Content-Type': 'application/json',
    'X-CSRF-Token': getCSRFToken(),
  };
}

export type RequestExecutionHandler = (
  signal: AbortSignal,
  ...args: any[]
) => Promise<unknown | void>;

export type RequestMetadata = {
  aborted: boolean;
  timestamp: Date;
  controller: AbortController;
};

export type RequestState = {
  executionHandler: RequestExecutionHandler;
  currentRequest?: string;
  requests: Record<string, RequestMetadata>;
};

export class RequestHandler {
  protected idGenerator: IterableIterator<string>;
  private requestStateMap: Map<string, RequestState> = new Map();

  private executionHooks: ((state?: RequestState) => void)[] = [];

  private constructor(
    public requestExecutionHandlers: Map<string, RequestExecutionHandler>
  ) {
    this.idGenerator = namespaceIdGenerator('request');
    this.requestExecutionHandlers = requestExecutionHandlers;
    this.registerRequests();
  }

  addExecutionHandler(key: string, handler: RequestExecutionHandler) {
    if (!this.requestExecutionHandlers.has(key)) {
      this.requestExecutionHandlers.set(key, handler);
      this.registerRequests();
    }
  }

  removeExecutionHandler(key: string) {
    if (!this.requestExecutionHandlers.has(key)) return;

    // Aborts the current request in case it's executing.
    this.abortRequest(key);
    this.requestExecutionHandlers.delete(key);
  }

  private registerRequests() {
    const handlerEntries = this.requestExecutionHandlers.entries();
    for (const [key, executionHandler] of handlerEntries) {
      if (this.requestStateMap.has(key)) continue;
      this.requestStateMap.set(key, {
        executionHandler,
        requests: {},
      });
    }
  }

  getState(key: string): RequestState | void {
    return this.requestStateMap.get(key);
  }

  getCurrentRequestMetadata(key: string): RequestMetadata | void {
    const state = this.getState(key);
    if (!state || !state.currentRequest) return;

    const current = state.requests[state.currentRequest];
    if (!current) return;

    return current;
  }

  addHook(hook: (state?: RequestState) => void) {
    this.executionHooks.push(hook);
  }

  abortRequest(key: string) {
    const metadata = this.getCurrentRequestMetadata(key);
    if (!metadata || metadata.aborted) return;

    metadata.controller.abort();
    metadata.aborted = true;
  }

  async executeRequest<R = unknown>(
    key: string,
    ...args: any[]
  ): Promise<R | void> {
    const state = this.getState(key);
    if (!state) return;

    if (state.currentRequest) this.abortRequest(key);

    const nextRequestId = this.idGenerator.next().value;
    const controller = new AbortController();

    state.requests[nextRequestId] = {
      controller,
      timestamp: new Date(),
      aborted: false,
    };

    state.currentRequest = nextRequestId;
    const response = await state.executionHandler(controller.signal, ...args);

    this.executionHooks.forEach(hook => {
      hook(state);
    });

    return response as R;
  }

  executeAllRequests(...args: any[]) {
    for (const key of this.requestStateMap.keys()) {
      this.executeRequest(key, ...args);
    }
  }

  async executeAllAsync<R = unknown>(...args: any[]): Promise<void> {
    const executions: Promise<R | void>[] = [];
    for (const key of this.requestStateMap.keys()) {
      executions.push(this.executeRequest(key, ...args));
    }

    await Promise.all(executions);
  }

  static create(
    requestExecutionHandlers: Record<string, RequestExecutionHandler>
  ): RequestHandler {
    return new RequestHandler(
      new Map(Object.entries(requestExecutionHandlers))
    );
  }
}
