import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';

import {RoadComponent} from '../../../lib/component';

import headlinestyle from './style.scss';

export const COMPONENT_TAG = 'road-headline';

enum Size {
  XS = 'xs',
  SM = 'sm',
  BASE = 'base',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

enum Intent {
  BASE = 'base',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
}

enum IntentVariant {
  BASE = 'base',
  LIGHT = 'light',
  DARK = 'dark',
  ACCENT = 'accent',
  ALT = 'alt',
}

/**
 * RoadHeadline -- headline component for reuse
 */
@customElement(COMPONENT_TAG)
export default class RoadHeadline extends RoadComponent {
  @property()
  text = '';

  @property()
  size: Size = Size.BASE;

  @property()
  intent: Intent = Intent.BASE;

  @property()
  intentVariant: IntentVariant = IntentVariant.BASE;

  /**
   * component styles.
   */
  static get styles() {
    return [headlinestyle];
  }

  // eslint-disable-next-line complexity
  get classes(): ClassInfo {
    return {
      'road-headline': true,
      'road-headline--xs': this.size === Size.XS,
      'road-headline--sm': this.size === Size.SM,
      'road-headline--md': this.size === Size.MD,
      'road-headline--lg': this.size === Size.LG,
      'road-headline--xl': this.size === Size.XL,
      'road-headline--primary': this.intent === Intent.PRIMARY,
      'road-headline--secondary': this.intent === Intent.SECONDARY,
      'road-headline--success': this.intent === Intent.SUCCESS,
      'road-headline--warning': this.intent === Intent.WARNING,
      'road-headline--danger': this.intent === Intent.DANGER,
      'road-headline--info': this.intent === Intent.INFO,
      'road-headline--primary--dark':
        this.intentVariant === IntentVariant.DARK &&
        this.intent === Intent.PRIMARY,
      'road-headline--secondary--dark':
        this.intentVariant === IntentVariant.DARK &&
        this.intent === Intent.SECONDARY,
      'road-headline--success--dark':
        this.intentVariant === IntentVariant.DARK &&
        this.intent === Intent.SUCCESS,
      'road-headline--warning--dark':
        this.intentVariant === IntentVariant.DARK &&
        this.intent === Intent.WARNING,
      'road-headline--danger--dark':
        this.intentVariant === IntentVariant.DARK &&
        this.intent === Intent.DANGER,
      'road-headline--info--dark':
        this.intentVariant === IntentVariant.DARK &&
        this.intent === Intent.INFO,
      'road-headline--primary--light':
        this.intentVariant === IntentVariant.LIGHT &&
        this.intent === Intent.PRIMARY,
      'road-headline--secondary--light':
        this.intentVariant === IntentVariant.LIGHT &&
        this.intent === Intent.SECONDARY,
      'road-headline--success--light':
        this.intentVariant === IntentVariant.LIGHT &&
        this.intent === Intent.SUCCESS,
      'road-headline--warning--light':
        this.intentVariant === IntentVariant.LIGHT &&
        this.intent === Intent.WARNING,
      'road-headline--danger--light':
        this.intentVariant === IntentVariant.LIGHT &&
        this.intent === Intent.DANGER,
      'road-headline--info--light':
        this.intentVariant === IntentVariant.LIGHT &&
        this.intent === Intent.INFO,
      'road-headline--primary--accent':
        this.intentVariant === IntentVariant.ACCENT &&
        this.intent === Intent.PRIMARY,
      'road-headline--secondary--accent':
        this.intentVariant === IntentVariant.ACCENT &&
        this.intent === Intent.SECONDARY,
      'road-headline--success--accent':
        this.intentVariant === IntentVariant.ACCENT &&
        this.intent === Intent.SUCCESS,
      'road-headline--warning--accent':
        this.intentVariant === IntentVariant.ACCENT &&
        this.intent === Intent.WARNING,
      'road-headline--danger--accent':
        this.intentVariant === IntentVariant.ACCENT &&
        this.intent === Intent.DANGER,
      'road-headline--info--accent':
        this.intentVariant === IntentVariant.ACCENT &&
        this.intent === Intent.INFO,
      'road-headline--primary--alt':
        this.intentVariant === IntentVariant.ALT &&
        this.intent === Intent.PRIMARY,
      'road-headline--secondary--alt':
        this.intentVariant === IntentVariant.ALT &&
        this.intent === Intent.SECONDARY,
      'road-headline--success--alt':
        this.intentVariant === IntentVariant.ALT &&
        this.intent === Intent.SUCCESS,
      'road-headline--warning--alt':
        this.intentVariant === IntentVariant.ALT &&
        this.intent === Intent.WARNING,
      'road-headline--danger--alt':
        this.intentVariant === IntentVariant.ALT &&
        this.intent === Intent.DANGER,
      'road-headline--info--alt':
        this.intentVariant === IntentVariant.ALT && this.intent === Intent.INFO,
    };
  }

  /**
    component renderer
   * @override
   */
  render() {
    return html`<div class=${classMap(this.classes)}><slot></slot></div> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    COMPONENT_TAG: RoadHeadline;
  }
}
