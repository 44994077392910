import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';
import {RoadComponent} from '../../../lib/component';
import iconstyle from './style.scss';
import '@material/mwc-icon';

export const COMPONENT_TAG = 'road-icon';

export enum Size {
  BASE = 'base',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XL2 = '2xl',
  XL3 = '3xl',
  XL4 = '4xl',
  XL5 = '5xl',
}

export enum Fill {
  BASE = 'base',
  PRIMARY = 'primary',
  PRIMARY_DARK = 'primary--dark',
  SECONDARY = 'secondary',
  SECONDARY_DARK = 'secondary--dark',
  SUCCESS = 'green',
  GREEN = 'green',
  GRAY = 'gray',
  GRAY_DARK = 'gray--dark',
  SUCCESS_DARK = 'green--dark',
  GREEN_DARK = 'green--dark',
  WARNING = 'yellow',
  YELLOW = 'yellow',
  WARNING_DARK = 'yellow--dark',
  YELLOW_DARK = 'yellow--dark',
  DANGER = 'red',
  RED = 'red',
  DANGER_DARK = 'red--dark',
  RED_DARK = 'red--dark',
}

const SVGIcons = new Map<string, string>();

export const DEFAULT_SVG_ICON_SIZE = 'var(--road-icon-size, auto)';

/**
 * **RoadIcon**
 *
 * To find the available icons, access [MUI icons](https://fonts.google.com/icons?icon.platform=web&icon.set=Material+Icons)
 *
 * Be aware that we may not support some of it.
 */
@customElement(COMPONENT_TAG)
export default class RoadIcon extends RoadComponent {
  @property()
  size: Size = Size.BASE;

  @property()
  fill: Fill = Fill.BASE;

  @property()
  icon = '';

  @property({type: Boolean})
  svg = false;

  @property({type: String})
  svgIconSize = DEFAULT_SVG_ICON_SIZE;

  get classes(): ClassInfo {
    return {
      'road-icon': true,
      'road-icon--svg': this.svg,
      [`road-icon--size--${this.size}`]: true,
      [`road-icon--${this.fill}`]: true,
      [`road-icon--svg--${this.fill}`]: true,
    };
  }

  static get styles() {
    return [iconstyle];
  }

  render() {
    if (this.svg) {
      return html`
        <div class=${classMap(this.classes)}>
          <img
            src=${SVGIcons.get(this.icon) || this.icon}
            width=${this.svgIconSize}
            height=${this.svgIconSize}
          />
        </div>
      `;
    }

    return html`
      <mwc-icon class=${classMap(this.classes)}> ${this.icon} </mwc-icon>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_TAG]: RoadIcon;
  }
}
