import {fetcher} from '../utils/http';
import {RequestMethod} from '../common/constants';
import {getCSRFToken} from '../utils/auth';

import {Account, AccountCategory} from '../types/account';
import {Currency} from '../types/currency';
import {Movement, MovementStatus, Tag} from '../types/movement';
import {MovementType} from '../types/movement_category';
import {FamilyMember} from '../types/family_member';
import {UserMovementCategory, UserMovementSubCategory} from '../types/movement_category';

import {ApiResponse, PaginatedApiResponse} from './api';

export interface AdvancedFilters {
  min?: number;
  max?: number;
  from?: Date;
  to?: Date;
  notes?: string;
  useFiscalDate?: string;
  selectedCurrencies?: Currency[];
  selectedAccounts?: Account[];
  selectedTags?: Tag[];
  selectedAccountCategories?: AccountCategory[];
  selectedUserMovementCategories?: UserMovementCategory[];
  selectedUserMovementSubCategories?: UserMovementSubCategory[];
  selectedFamilyMembers?: FamilyMember[];
  selectedPayees?: FamilyMember[];
  selectedMovementStatuses?: MovementStatus[];
};

export interface FilterStore {
  status?: string;
  accountCategory?: number;
  currency?: number;
}

export interface TagsResponse extends ApiResponse { tags?: Tag[]; };

export interface FamilyMembersResponse extends ApiResponse { family_members?: FamilyMember[]; };

export interface UserMovementCategoriesResponse extends ApiResponse { user_movement_categories?: UserMovementCategory[]; };

export interface MovementResponse extends ApiResponse { movement?: Movement; };

export interface MovementsResponse extends PaginatedApiResponse { movements?: Movement[]; };

export interface MovementDeleteResponse extends ApiResponse {};

export async function createMovement(
  movement: Movement,
): Promise<MovementResponse> {

  const url = `/api/movements`
  const movementPayload = buildMovementPayload(movement);

  try {
    const response = await fetcher<MovementResponse>(
      url,
      {method: RequestMethod.POST, body: movementPayload}
    );
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    alert(e.errors.join('\n'));
    return {ok: false, errors: e.errors};
  }
}

export async function updateMovement(
  movement: Movement,
): Promise<MovementResponse> {

  let url = `/api/movements/${movement.id}`
  const movementPayload = buildMovementPayload(movement);

  try {
    const response = await fetcher<MovementResponse>(
      url,
      {method: RequestMethod.PUT, body: movementPayload}
    );
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

function buildMovementPayload(movement: Movement): FormData {
  const payload = new FormData();

  payload.append('authenticity_token', getCSRFToken());

  payload.append('movement[status]', movement.status.toString());
  payload.append('movement[date]', movement.date);
  payload.append('movement[amount]', movement.amount?.toString() || '');
  payload.append('movement[sign]', movement.sign?.toString() || '');
  payload.append('movement[fiscal_date]', movement.fiscal_date);
  payload.append('movement[recurring_movement_id]', movement.recurring_movement_id?.toString() || '');
  payload.append('movement[notes]', movement.notes || '');
  payload.append('movement[account_id]', movement.account?.id?.toString() || '');

  if (movement.movement_type === MovementType.TRANSFER) {
    payload.append('movement[transfer_movement_account_id]', movement.transfer_movement_account_id?.toString() || '');
    payload.append('movement[transfer_movement_amount]', movement.transfer_movement_amount?.toString() || '');
  } else {
    payload.append('movement[user_movement_category_id]', movement.user_movement_category?.id?.toString() || '');
    payload.append('movement[user_movement_sub_category_id]', movement.user_movement_sub_category?.id?.toString() || '');
  }

  payload.append('movement[import_id]', movement.import_id?.toString() || '');

  if (movement.tags) {
    movement.tags.forEach((tag) => {
      payload.append('movement[tags][]', tag.tag);
    });
  }

  if (movement.attachments) {
    movement.attachments.forEach((attachment) => {
      payload.append('movement[attachments][]', attachment, attachment.name);
    });
  }

  payload.append('movement[family_member_id]', movement.family_member?.id?.toString() || '');
  payload.append('movement[payee_id]', movement.payee?.id?.toString() || '');

  return payload;
}

export async function loadMovements(
  page: number,
  limit: number,
  filters: FilterStore = {},
  advancedFilters: AdvancedFilters = {},
): Promise<MovementsResponse> {
  const currencyParam = filters.currency ? `&currency_id=${filters.currency}` : '';
  const accountCategoryParam = filters.accountCategory ? `&account_category_id=${filters.accountCategory}` : '';
  const statusParam = filters.status ? `&status=${filters.status}` : '';

  const fromParam = advancedFilters.from ? `&from=${advancedFilters.from}` : '';
  const toParam = advancedFilters.to ? `&to=${advancedFilters.to}` : '';
  const useFiscalDateParam = `&use_fiscal_date=${advancedFilters.useFiscalDate}`;
  const minParam = advancedFilters.min ? `&min=${advancedFilters.min}` : '';
  const maxParam = advancedFilters.max ? `&max=${advancedFilters.max}` : '';
  const notesParam = advancedFilters.notes ? `&notes=${advancedFilters.notes}` : '';

  const currenciesParam = advancedFilters.selectedCurrencies?.length
    ? advancedFilters.selectedCurrencies.map((c) => `&currency_ids[]=${c.id}`).join('')
    : '';
  const accountsParam = advancedFilters.selectedAccounts?.length
    ? advancedFilters.selectedAccounts.map((a) => `&account_ids[]=${a.id}`).join('')
    : '';
  const accountCategoriesParam = advancedFilters.selectedAccountCategories?.length
    ? advancedFilters.selectedAccountCategories.map((a) => `&account_category_ids[]=${a.id}`).join('')
    : '';
  const tagsParam = advancedFilters.selectedTags?.length
    ? advancedFilters.selectedTags.map((t) => `&tag_ids[]=${t.id}`).join('')
    : '';
  const movSubCatsParam = advancedFilters.selectedUserMovementSubCategories?.length
    ? advancedFilters.selectedUserMovementSubCategories.map((c) => `&user_movement_sub_category_ids[]=${c.id}`).join('')
    : '';
  const familyMembersParam = advancedFilters.selectedFamilyMembers?.length
    ? advancedFilters.selectedFamilyMembers.map((f) => `&family_member_ids[]=${f.id}`).join('')
    : '';
  const payeesParam = advancedFilters.selectedPayees?.length
    ? advancedFilters.selectedPayees.map((p) => `&payee_ids[]=${p.id}`).join('')
    : '';
  const movementStatusesParam = advancedFilters.selectedMovementStatuses?.length
    ? advancedFilters.selectedMovementStatuses.map((s) => `&status[]=${s}`).join('')
    : '';

  let url = `/api/movements?page=${page}&limit=${limit}`
  if (currencyParam) url = url + currencyParam
  if (accountCategoryParam) url = url + accountCategoryParam
  if (statusParam) url = url + statusParam
  if (fromParam) url = url + fromParam
  if (toParam) url = url + toParam
  if (fromParam || toParam) url = url + useFiscalDateParam
  if (notesParam) url = url + notesParam
  if (minParam) url = url + minParam
  if (maxParam) url = url + maxParam
  if (currenciesParam) url = url + currenciesParam
  if (accountsParam) url = url + accountsParam
  if (accountCategoriesParam) url = url + accountCategoriesParam
  if (tagsParam) url = url + tagsParam
  if (movSubCatsParam) url = url + movSubCatsParam
  if (familyMembersParam) url = url + familyMembersParam
  if (payeesParam) url = url + payeesParam
  if (movementStatusesParam) url = url + movementStatusesParam

  try {
    const response = await fetcher<MovementsResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function loadFamilyMembers(): Promise<FamilyMembersResponse> {
  const url = '/api/family_members';

  try {
    const response = await fetcher<FamilyMembersResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function loadTags(): Promise<TagsResponse> {
  const url = '/api/tags';

  try {
    const response = await fetcher<TagsResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function loadUserMovementCategories(): Promise<UserMovementCategoriesResponse> {
  const url = '/api/user_movement_categories';

  try {
    const response = await fetcher<UserMovementCategoriesResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function confirmMovement(movement: Movement, status: MovementStatus): Promise<MovementResponse> {
  const action = status === MovementStatus.CONFIRMED ? 'confirm' : 'unconfirm';
  const url = `/api/movements/${movement.id}/${action}`;

  try {
    const response = await fetcher<MovementResponse>(url, {method: RequestMethod.PATCH});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function deleteMovement(movement: Movement): Promise<MovementDeleteResponse> {
  const url = `/api/movements/${movement.id}`;

  try {
    await fetcher<string>(url, {method: RequestMethod.DELETE});
    return {ok: true, errors: []};
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}
