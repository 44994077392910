import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {ifDefined} from 'lit/directives/if-defined.js';

import {RoadComponent} from '../../../lib/component';

import {isNonEmptyString} from '../../../utils';

import styles from './style.scss';

export const COMPONENT_TAG = 'road-radio';

export enum CUSTOM_EVENT {
  CHANGE = 'change',
  BLUR = 'blur',
  FOCUS = 'focus',
}

export interface CustomChangeEvent {
  value: string;
  label: string;
  key: string;
  disabled: boolean;
  checked: boolean;
  $node: HTMLElement;
}

@customElement(COMPONENT_TAG)
export default class RoadRadio extends RoadComponent {
  @property()
  key: string = `${new Date().getTime()}-${Math.random()}`;

  @property()
  value: string = '';

  @property()
  label: string = '';

  @property()
  name!: string;

  @property()
  fieldset!: string;

  @property({type: Boolean, reflect: true})
  checked: boolean = false;

  @property({type: Boolean, reflect: true})
  disabled: boolean = false;

  @property({type: Boolean, reflect: true})
  readonly: boolean = false;

  @property({type: Boolean, reflect: true})
  invalid: boolean = false;

  @query('input')
  $input!: HTMLInputElement;

  static get styles() {
    return [styles];
  }

  updated(changedProperties: Map<string, unknown>) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName !== 'checked') return;
      if (oldValue === this.checked) return;
      const evt = {
        value: this.value,
        label: this.label,
        key: this.key,
        checked: this.checked,
        disabled: this.disabled,
        $node: this.$input,
      };

      this.dispatchEvent(
        new CustomEvent<CustomChangeEvent>(CUSTOM_EVENT.CHANGE, {
          detail: evt,
        })
      );

      window.dispatchEvent(
        new CustomEvent(`${COMPONENT_TAG}:${CUSTOM_EVENT.CHANGE}`, {
          detail: evt,
        })
      );

      if (!this.checked && this.$input && this.$input.checked) {
        this.$input.checked = false;
      }
    });
  }

  labelMarkup() {
    if (!isNonEmptyString(this.label)) return html``;

    return html`
      <label
        class="road-radio__label"
        for="road-radio-${this.key}-input"
        id="road-radio-${this.key}-label"
        ?checked=${this.checked}
        ?disabled=${this.disabled}
      >
        ${this.label}
      </label>
    `;
  }

  inputMarkup() {
    return html`
      <input
        aria-label=${this.label}
        @click=${() => (this.checked = !this.checked)}
        @focus=${() => this.dispatchEvent(new CustomEvent(CUSTOM_EVENT.FOCUS))}
        @blur=${() => this.dispatchEvent(new CustomEvent(CUSTOM_EVENT.BLUR))}
        class="road-radio__input"
        type="radio"
        data-fieldset-key=${ifDefined(this.fieldset)}
        name=${ifDefined(this.name)}
        ?checked=${this.checked}
        ?disabled=${this.disabled}
        value=${this.value}
        id="road-radio-${this.key}-input"
      />
    `;
  }

  render() {
    return html`
      <div id="road-radio-${this.key}" class="road-radio">
        ${this.inputMarkup()} ${this.labelMarkup()}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_TAG]: RoadRadio;
  }
}
