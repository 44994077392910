import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';

import {RoadComponent} from '../../../lib/component';
import styles from './style.scss';

export const COMPONENT_TAG = 'road-list-item';

/**
 * RoadListItem
 */
@customElement(COMPONENT_TAG)
export default class RoadListItem extends RoadComponent {
  @property({type: Boolean})
  hasIcon = false;

  @property({type: Boolean})
  large = false;

  @property({type: Boolean, reflect: true})
  selected = false;

  @property({type: Boolean, reflect: true})
  disabled = false;

  @property({type: Boolean, reflect: true})
  hidden = false;

  @property()
  value = '';

  @property()
  label = '';

  @property()
  document: Record<string, unknown> = {};

  @property({type: Boolean})
  preventSelection = false;

  @property({type: Boolean})
  preventDeselection = false;

  @property({type: Boolean})
  selectionRequired = false;

  @property({type: Boolean, reflect: true})
  fullWidth = false;

  /**
   * Styles
   */
  static get styles() {
    return [styles];
  }

  get classes(): ClassInfo {
    return {
      'road-list-item': true,
      'road-list-item--lg': this.large,
      'road-list-item--icon': this.hasIcon,
      'road-list-item--selected': this.selected && !this.preventSelection,
      'road-list-item--disabled': this.disabled,
      'road-list-item--hidden': this.hidden,
    };
  }

  updated(changedProperties: Map<string, unknown>): void {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'selected') {
        if (oldValue === this.selected && !this.preventSelection) return;
        if (this.disabled && this.selected) {
          this.selected = false;
          return;
        }

        this.dispatchSelectionEvent();
        return;
      }

      if (propName === 'disabled') {
        if (oldValue === this.disabled) return;
        if (!this.disabled) return;
        if (this.disabled && this.selected) this.selected = false;

        this.dispatchEvent(
          new CustomEvent('disabled', {
            detail: {
              value: this.value,
              label: this.label,
              selected: this.selected,
              disabled: this.disabled,
              hidden: this.hidden,
            },
          })
        );
        return;
      }
    });
  }

  dispatchSelectionEvent(): void {
    this.dispatchEvent(
      new CustomEvent(this.selected ? 'selected' : 'deselected', {
        detail: {
          value: this.value,
          label: this.label,
          selected: this.selected,
          disabled: this.disabled,
          hidden: this.hidden,
        },
      })
    );
  }

  /**
    Renderer
   * @override
   */
  render() {
    const itemTextStyles = styleMap({
      display: 'var(--road-list-item-text-display)',
    });

    const listItemTextMarkup = () => {
      return html`
        <div class="road-list-item__text" style=${itemTextStyles}>
          <slot></slot>
        </div>
      `;
    };

    const listItemIconMarkup = ()  => {
      if (this.hasIcon) {
        return html`
          <div class="road-list-item__icon">
            <slot name="icon"></slot>
          </div>
        `
      }

      return html``
    };

    return html`
      <li
        tabindex="1"
        @click=${() => {
          if (this.disabled || this.hidden) return;

          if (this.selected && this.preventSelection) {
            this.dispatchSelectionEvent();
            return;
          }

          if (this.selectionRequired) {
            this.selected = true;
            return;
          }

          if (this.selected && this.preventDeselection) {
            return;
          }

          this.selected = !this.selected;
        }}
        @keydown=${(e: KeyboardEvent) => {
          if (e.key !== 'Enter' || this.disabled || this.hidden) return;

          if (this.selected && this.preventSelection) {
            this.dispatchSelectionEvent();
            return;
          }

          if (this.selectionRequired) {
            this.selected = true;
            return;
          }

          if (this.selected && this.preventDeselection) return;
          this.selected = !this.selected;
        }}
        ?hidden=${this.hidden}
        class=${classMap(this.classes)}
      >
        ${listItemIconMarkup()}
        ${listItemTextMarkup()}
      </li>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'road-list-item': RoadListItem;
  }
}
