import {fetcher} from '../utils/http';
import {RequestMethod} from '../common/constants';

import {ApiResponse} from './api';

import {Currency} from '../types/currency';
import {Account, AccountCategory} from '../types/account';

export interface AccountResponse extends ApiResponse { account: Account };

export interface AccountsResponse extends ApiResponse { accounts?: Account[]; };

export interface AccountCategoriesResponse extends ApiResponse { account_categories?: AccountCategory[]; };

export interface CurrenciesResponse extends ApiResponse { currencies?: Currency[]; };

export interface ExchangeRateResponse extends ApiResponse { cur_abrev?: number, cur_ex?: number, cur_id?: number };

export async function loadAccounts(): Promise<AccountsResponse> {
  const url = '/api/accounts';

  try {
    const response = await fetcher<AccountsResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function FavoriteAccount(account: Account): Promise<AccountResponse> {
  const url = `/api/accounts/${account.id}/favorite`;
  const response = await fetcher<AccountResponse>(url, {method: RequestMethod.PATCH});

  return response;
}

export async function UnfavoriteAccount(account: Account): Promise<AccountResponse> {
  const url = `/api/accounts/${account.id}/unfavorite`;
  const response = await fetcher<AccountResponse>(url, {method: RequestMethod.PATCH});

  return response;
}

export async function loadCurrencies(): Promise<CurrenciesResponse> {
  const url = '/api/currencies';

  try {
    const response = await fetcher<CurrenciesResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function loadAccountCategories(): Promise<AccountCategoriesResponse> {
  const url = '/api/account_categories';

  try {
    const response = await fetcher<AccountCategoriesResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}

export async function loadExchangeRate(accountId: number): Promise<ExchangeRateResponse> {
  const url = `/api/accounts/${accountId}/exchange_rate`;

  try {
    const response = await fetcher<ExchangeRateResponse>(url, {method: RequestMethod.GET});
    response.ok = true;
    response.errors = [];
    return response;
  } catch (e: any) {
    return {ok: false, errors: e.errors};
  }
}
