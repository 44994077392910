import {html} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {ClassInfo, classMap} from 'lit/directives/class-map.js';
import {styleMap} from 'lit/directives/style-map.js';
import {ifDefined} from 'lit/directives/if-defined.js';

import RoadInput from '../input';
import styles from './style.scss';
import inputStyles from '../input/style.scss';

export const ROAD_TEXTAREA_ELEMENT_TAG = 'road-textarea';

/**
 * RoadTextarea -- Element for a textarea.
 */
@customElement(ROAD_TEXTAREA_ELEMENT_TAG)
export default class RoadTextarea extends RoadInput {
  @query('textarea')
  $input!: HTMLTextAreaElement;

  @property({type: Number})
  rows?: number;

  @property({type: Number})
  cols?: number;

  @property({type: Boolean, reflect: true})
  preventEnterDefault = false;

  @property({type: Boolean, reflect: true})
  set autoExpandVertically(value: boolean) {
    if (this._autoExpandVertically === value) {
      return;
    }

    this._autoExpandVertically = value;

    if (value) {
      this._originalStyleHeightDeclaration = this.$input?.style.height ?? '';
      value && this.calculateHeight();
    }

    if (!value && this.$input) {
      this.$input.style.height = this._originalStyleHeightDeclaration;
    }
  }

  get autoExpandVertically() {
    return this._autoExpandVertically;
  }

  static get styles() {
    return [inputStyles, styles];
  }

  private _autoExpandVertically = false;
  private _originalStyleHeightDeclaration = '';
  private resizeObserver!: ResizeObserver;

  constructor() {
    super();
  }

  private calculateHeight() {
    if (!this.autoExpandVertically || !this.$input) {
      return;
    }

    this.$input.style.height = '1px';
    if (!this.$input.value.length)
      return (this.$input.style.height = this._originalStyleHeightDeclaration);

    const height = this.$input.scrollHeight;
    this.$input.style.height = `${height}px`;
  }

  public rendered() {
    this._rendered = true;
    this.resizeObserver.observe(this.$input);
    this.calculateHeight();
    this._originalStyleHeightDeclaration = this.$input?.style.height ?? '';
  }

  public focusTextArea() {
    if (!this.$input) return;
    this.$input.focus();
  }

  beforeLoad(): void {
    super.beforeLoad();
    this.resizeObserver = new ResizeObserver(() => {
      this.calculateHeight();
    });
  }

  disconnectedCallback() {
    this.resizeObserver.unobserve(this.$input);
  }

  get computedStyles() {
    return {
      textarea: {
        width:
          this.width ?? 'var(--road-input-native-width, var(--road-input-width))',
      },
    };
  }

  get computedClasses(): Record<string, ClassInfo> {
    return {
      textarea: {
        ...super.computedClasses.input,
        'road-textarea': true,
        'road-textarea--auto-expand-vertically': this.autoExpandVertically,
      },
    };
  }

  updated(changedProperties: Map<string, unknown>): void {
    if (changedProperties.has('value')) {
      this.calculateHeight();
    }
  }

  /**
   * Override input behavior of inline auto grow
   *
   * @override
   */
  setInlineWidth() {}

  inputMarkup() {
    return html`
      <textarea
        part="textarea"
        style=${styleMap(this.computedStyles.textarea)}
        id="road-textarea-${this.key}"
        class=${classMap(this.computedClasses.textarea)}
        rows=${ifDefined(this.rows)}
        cols=${ifDefined(this.cols)}
        .name=${this.name}
        .placeholder=${this.placeholder}
        ?required=${this.required}
        ?disabled=${this.disabled}
        ?readonly=${this.readonly}
        maxlength=${ifDefined(this.maxlength)}
        minlength=${ifDefined(this.minlength)}
        .value=${this.value}
        ?autofocus=${this.autofocus}
        @focus=${(evt: FocusEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'focus', evt});
        }}
        @blur=${(evt: FocusEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'blur', evt});
        }}
        @input=${(evt: InputEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'input', evt});
        }}
        @change=${(evt: InputEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'change', evt});
        }}
        @keyup=${(evt: KeyboardEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'keyup', evt});
        }}
        @keydown=${(evt: KeyboardEvent) => {
          if (this.preventEnterDefault) {
            if(evt.key === 'Enter') evt.preventDefault();
          }
          evt.stopImmediatePropagation();
          this.onAction({tag: 'keydown', evt});
        }}
        @focusout=${(evt: FocusEvent) => {
          evt.stopImmediatePropagation();
          this.onAction({tag: 'focusout', evt});
        }}
      >
      </textarea>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [ROAD_TEXTAREA_ELEMENT_TAG]: RoadTextarea;
  }
}
